import { css } from '@emotion/react'
import { ThemeUI } from '../../../theme'
import { StyleProps } from './types'

type Props = { theme: ThemeUI } & StyleProps

const rootContainer = css`
  display: flex;
  flex-direction: column;
  width: 100%;
`
const label = ({ theme }: Props) => css`
  font-size: 9px;
  line-height: 11px;
  margin-bottom: 6px;
  text-transform: uppercase;
`

const hint = ({ theme, success, error }: Props) => css`
  font-size: 9px;
  line-height: 11px;
  ${success && (`color: ${theme.palette.success};`)}
  ${error && (`color: ${theme.palette.danger};`)}
  margin-top: 6px;
  text-align: right;
`

const input = ({ theme, success, error }: Props) => css`
  background-color: ${theme.palette.white};
  border-width: 1px;
  width: 100%;
  position: relative;
  border-style: solid;
  font-family: "Lato", sans-serif;
  font-size: 12px;
  line-height: 14.4px;
  border-color: ${theme.palette.secondary70};
  border-radius: 3px;
  padding: 12px 18px;
  text-align: left;
  &:focus {
    outline: 1px solid #4784FF;
  }
  ${success && `background-color: ${theme.palette.successLight};`}
  ${error && `background-color: ${theme.palette.dangerLight};`}
  &:disabled {
    background-color: ${theme.palette.secondary10};
    &::placeholder {
      color: ${theme.palette.secondary70};
    }
    & ~ span {
      color: ${theme.palette.secondary70};
    }
  }
`

const inputContainer = css`
  position: relative;
  & svg {
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
  }
`


export { rootContainer, label, hint, input, inputContainer }