/** @jsxImportSource @emotion/react */
import React from 'react'
import { NewGroupForm, NewGroupPreview } from '../../../components/templates'
import * as styles from '../index.styles'
import Provider from '../../../context/GroupNewContext'
import { breadcrumbItems } from '../../utils'
import { Breadcrumb } from '../../../components/atoms'

const { index, contentManagement, manageNews } = breadcrumbItems

const CreateGroupNew: React.FC = () => {
  return (
    <Provider>
      <Breadcrumb items={[index, contentManagement, manageNews]} />
      <div css={styles.root}>
        <div css={styles.main}>
          <NewGroupForm />
        </div>
        <div css={styles.right}>
          <NewGroupPreview />
        </div>
      </div>
    </Provider>
  )
}

export default CreateGroupNew
