import * as React from "react"

export default React.memo((props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={13}
    height={12}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.2 5.602L9.963.129c0-.1-.101-.1-.203-.1H2.44c-.102 0-.203 0-.203.1L0 5.602v.199s.102.1.203.1h.712v5.97s.102.1.203.1h9.964c.101 0 .203-.1.203-.2v-5.97h.712c.101 0 .101 0 .203-.1v-.099zm-7.117 5.97v-3.98h2.034v3.98H5.083zm5.795 0H7.523v-4.08c0-.099-.101-.198-.203-.198H4.88c-.102 0-.203.1-.203.199v4.08H1.322V5.8h9.658v5.772h-.102zm.204-6.07H.407L2.44.427h7.117l2.033 5.075h-.508z"
    />
    <path
      d="M2.237 10.18h1.525c.101 0 .203-.1.203-.2V8.19a.902.902 0 00-.915-.897c-.508 0-1.017.398-1.017.896V9.98c0 .1.102.2.204.2zm.203-.399v-.895h1.118v.895H2.44zm.508-2.09c.305 0 .509.2.509.498v.299H2.44v-.299c0-.298.203-.498.508-.498zM6.1 1.522a.902.902 0 00-.915.896v1.79c0 .1.102.2.203.2h1.525c.102 0 .204-.1.204-.2v-1.79c-.102-.498-.509-.896-1.017-.896zm0 .299c.305 0 .508.199.508.497v.398H5.592v-.298c0-.299.203-.597.508-.597zM5.592 4.01v-.896H6.71v.896H5.592zM3.558 1.522c-.508 0-1.016.398-1.016.896v1.79c0 .1.101.2.203.2H4.27c.102 0 .203-.1.203-.2v-1.79a.902.902 0 00-.915-.896zm0 .299c.305 0 .509.298.509.597v.398H2.948v-.398c0-.299.305-.597.61-.597zm-.61 2.189v-.896h1.119v.896H2.948zM8.642 1.522a.902.902 0 00-.915.896v1.79c0 .1.101.2.203.2h1.525c.102 0 .203-.1.203-.2v-1.79c0-.498-.508-.896-1.016-.896zm0 .299c.305 0 .508.199.508.497v.398H8.133v-.298c0-.299.204-.597.509-.597zM8.133 4.01v-.896h1.119v.896H8.133zM8.438 10.18h1.525c.102 0 .204-.1.204-.2V8.19a.902.902 0 00-.915-.897.902.902 0 00-.915.896V9.98c0 .1 0 .2.101.2zm.204-.399v-.895H9.76v.895H8.642zm.61-2.09c.305 0 .508.2.508.498v.299H8.642v-.299c0-.298.305-.498.61-.498zM6.71 9.781c-.102 0-.203.1-.203.2 0 .099.101.198.203.198.102 0 .203-.1.203-.199-.101-.1-.101-.199-.203-.199z"
    />
  </svg>
))