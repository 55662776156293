/** @jsxImportSource @emotion/react */
import React, {Fragment, useEffect} from 'react'
import * as styles from '../index.styles'
import { breadcrumbItems } from '../../utils'
import { Breadcrumb } from '../../../components/atoms'
import { CreateSide, ManagementDataForm} from '../../../components/templates'
import { useNavigate } from "react-router";
import {useDispatch, useSelector} from "react-redux";
import {management} from "../../../redux/actions/management/management";
import {columns} from "./mockData";

const { index, users, managementData } = breadcrumbItems

const ManagementData: React.FC = (): any => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const token = useSelector((state: any) => state.login.access)
    const management_data = useSelector((state: any) => state.management.data)
    const permissions_list = useSelector((state: any) => state.permissions.data)

    useEffect(() => {
        dispatch(management({token}))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

  return (
    <Fragment>
      <Breadcrumb items={[index, users, managementData]} />
        {permissions_list.find((element: any) => element.codename === 'add_management') ? (
        <div css={styles.root}>
          <div css={styles.main}>
            <ManagementDataForm
              title="Datos de gerencia"
              subtitle="Gerencias publicadas"
              columns={columns}
              data={management_data || []}
              />
          </div>
          <div css={styles.right}>
            <CreateSide
              title="Crear nueva gerencia"
              message="Massa vulputate id placerat vitae ornare a orci. Gravida augue viverra pretium adipiscing mauris."
              textButton="Crear nueva gerencia"
              onCreate={() => navigate('/app/create-managements')}
            />
        </div>
      </div>
        ) : (<div css={styles.root}><h1>No tiene permisos para trabajar en esta sección</h1></div>)
      }
    </Fragment>
  )
}

export default ManagementData
