import {DELETE_GROUP_POSTS_ERROR, DELETE_GROUP_POSTS_START, DELETE_GROUP_POSTS_SUCCESS} from "../../types";

export default function delete_group_posts(state, action) {
    switch (action.type) {
        case DELETE_GROUP_POSTS_START:
            return {
                ...state,
                loading: true,
                error: null
            };
        case DELETE_GROUP_POSTS_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null
            };
        case DELETE_GROUP_POSTS_ERROR:
            return {
                ...state,
                loading: false,
                error: action.detail
            };
        default:
            return {...state};
    }
}
