/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import React, {FC, useContext, useEffect, useState} from 'react'
import { Context } from '../../../context/NatureMagazineContext'
import { BaseInput, FileInput, SwitchInput, Text, TextareaInput } from '../../atoms'
import { ContentSection } from '../../organisms'
import {useParams} from "react-router-dom";
import Select from "react-select";
import * as styles from "../Header/index.styles";
import {CATEGORIES_ALL} from "../../../category_all";
import {useSelector} from "react-redux";

const validateNumber = (data: string) =>
    /^[0-9]+$/.test(data)

const NewForm: FC = () => {
  const { formData, handleChangeFormData: handleChange, handleUpdateFormData: handleData } = useContext(Context)
  let { id } = useParams();

  const detail_data = useSelector((state: any) => state.nature_magazine.data)
  const n_magazine_data = id ? detail_data?.find((r:any) => r.id === parseInt(id+'')) : null

  useEffect(() => {
    if (!!id) {
      handleData(n_magazine_data)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, n_magazine_data])

  const categoryInit = n_magazine_data?.category ? { value: n_magazine_data.category, label: n_magazine_data.category } : { value: '', label: '' }

  const handleCategory = (e: any) => {
    handleChange({ category: e.value })
  }

  const [isTitleValid, setTitleValid] = useState<boolean>(false)
  const [isDescriptionValid, setIsDescriptionValid] = useState<boolean>(false)
  const [isUrlValid, setIsUrlValid] = useState<boolean>(false)
  const [isCycleValid, setIsCycleValid] = useState<boolean>(false)

  useEffect(() => {
    setTitleValid(formData.title.length > 2)
  }, [formData.title])
  useEffect(() => {
    setIsDescriptionValid(formData.description.length > 2)
  }, [formData.description])
  useEffect(() => {
    setIsUrlValid(formData.url.length > 10)
  }, [formData.url])
  useEffect(() => {
    setIsCycleValid(validateNumber(formData.cycle))
  }, [formData.cycle])

  return (
    <ContentSection
      style={css`
        display: grid;
        gap: 18px;
      `}
      header={
        <React.Fragment>
          <Text textType="h4" weight="black">
            {id ? 'Editar':'Nueva'} revista natura
          </Text>
        </React.Fragment>
      }
    >
      <BaseInput label="Título"
        value={formData.title}
        onChange={(value) => handleChange({ title: value })}
        placeholder="Escribe el titulo de tu entrada"
        maxLength={500}
        error={!isTitleValid && formData.title.length > 0}
        success={isTitleValid}
        hint={!isTitleValid && formData.title.length > 0 ? 'Digite mas de dos letras' : ''}
      />
      <TextareaInput
          label="Contenido"
          value={formData.description}
          onChange={(value) => handleChange({ description: value })}
          placeholder="Escribe el contenido de tu entrada"
          maxLength={1500}
          error={!isDescriptionValid && formData.description.length > 0}
          success={isDescriptionValid}
          hint={!isDescriptionValid && formData.description.length > 0 ? 'Digite mas de dos letras' : ''}
      />
      <BaseInput
          label="Enlace de la revista natura"
          value={formData.url}
          onChange={(value) => handleChange({ url: value })}
          placeholder="Escribe el enlace del evento"
          maxLength={1500}
          error={!isUrlValid && formData.url.length > 0}
          success={isUrlValid}
          hint={!isUrlValid && formData.url.length > 0 ? 'Introduce una url Ej.:https://www.google.com/' : ''}
      />
      <Text textType="label">
        CATEGORÍA :{' '}
        <Select css={styles.input}
                options={CATEGORIES_ALL}
                defaultValue={categoryInit}
                onChange={(e) =>  handleCategory(e)}
        />
      </Text>
      <BaseInput
        label="Ciclo"
        placeholder="Escribe un código de  ciclo"
        value={formData.cycle}
        onChange={(value) => handleChange({ cycle: value })}
        maxLength={30}
        error={!isCycleValid && formData.cycle.length > 0}
        success={isCycleValid}
        hint={!isCycleValid && formData.cycle.length > 0 ? 'Digite numeros' : ''}
      />
      <div style={{ width: 360 }}>
        <FileInput
          label="Imagen"
          value={formData.image}
          onChange={(value) => handleChange({ image: value })}
          accept="image/*"
        />
      </div>
      <SwitchInput checked={formData.active}
                   onCheckedChange={(value) => handleChange({ active: value })}
                   label={formData.active ? 'Activo': 'Inactivo'}
      />
    </ContentSection>
  )
}

export default NewForm
