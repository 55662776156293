import {takeLatest, put, call} from 'redux-saga/effects';
import {GET_GROUP_POST_CYCLE_START, GET_GROUP_POST_CYCLE_SUCCESS, GET_GROUP_POST_CYCLE_ERROR} from "../../types";

import apiCall from '../../api/apiGet';

export function* getCycle({payload}) {
    try {
        const url = '/group_posts_cycle/';
        const method = 'GET';
        const headers = {Authorization: 'Bearer ' + payload.token};
        const results = yield call(apiCall, url, method, headers)
        yield put({type: GET_GROUP_POST_CYCLE_SUCCESS, results: results.data});
        yield put(payload.navigate('/app/content-management/manage-post'))
    } catch (error) {
        yield put({type: GET_GROUP_POST_CYCLE_ERROR, error});
    }
}

export default function* group_posts_cycle() {
    yield takeLatest(GET_GROUP_POST_CYCLE_START, getCycle);
}