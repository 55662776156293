/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react'
import React, {useContext} from 'react'
import { ThemeUI } from '../../../theme'
import { Button, Card, Text } from '../../atoms'
import { ContentSection } from '../../organisms'
import * as styles from './index.styles'
import {Context} from "../../../context/UsersContext";
import {useDispatch, useSelector} from "react-redux";
import { useParams, useNavigate } from 'react-router-dom';
import { register } from '../../../redux/actions/auth/register'
import apiCall from '../../../redux/api';
import { userdata } from '../../../redux/actions/auth/userdata'

const NewPreview: React.FC = () => {
  const theme = useTheme() as ThemeUI
  const { formData } = useContext(Context)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const token = useSelector((state: any) => state.login.access)
  let { id } = useParams();

  const handleRegister = (token: any, formData: any) => {
    if (id) {
      const url_user = `/users/${id}/`;
      const method_user = 'PUT';
      const headers_user = { 
        'Authorization': `Bearer ${token}`,
        'Content-Type':'application/json'
      };
      const data = {
        last_name: formData.last_name,
        first_name: formData.first_name,
        is_staff: formData.is_staff,
        is_active: formData.is_active,
        user_permissions: formData.user_permissions,
      };  
      apiCall(url_user, method_user, data, headers_user).then((data) => {
        dispatch(userdata({ token }))
        navigate('/app/users/list')
      })
    } else {
      dispatch(register({ token, formData, navigate}))
    }
  }

  return (
    <ContentSection
      style={styles.root}
      header={
        <Text textType='p' weight='bold'>Vista Previa</Text>
      }
    >
      <div css={(theme: any) => styles.preview(theme)}>
        <Card style={css({ overflow: 'hidden' })}>
          <Text textType='sm' style={styles.previewTitle(theme)}>
            {formData.username}
          </Text>
        </Card>
      </div>
      <Button onClick={() => handleRegister(token, formData)}>
        {!id ? 'Crear' : 'Guardar'} usuario
      </Button>
    </ContentSection>
  )
}


export default NewPreview
