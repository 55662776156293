import {GET_GROUP_DETAIL_POSTS_ERROR, GET_GROUP_DETAIL_POSTS_START, GET_GROUP_DETAIL_POSTS_SUCCESS} from "../../types";

export default function group_detail_posts(state, action) {
  switch (action.type) {
    case GET_GROUP_DETAIL_POSTS_START:
      return {
        ...state,
        data: [],
        loading: true,
        error: null
      };
    case GET_GROUP_DETAIL_POSTS_SUCCESS:
      return {
        ...state,
        data: action.results,
        loading: false,
        error: null
      };
    case GET_GROUP_DETAIL_POSTS_ERROR:
      return {
        ...state,
        data: [],
        loading: false,
        error: action.detail
      };
    default:
      return { ...state };
  }
}
