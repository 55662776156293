import { RouteSection } from '../components/organisms/NavSection/types'
import { AddUserIcon, DocumentIcon, HomeIcon, ServiceIcon, ManagementIcon } from './icons'

const navListRoutes: RouteSection[] = [
  {
    children: [
      {
        text: 'Dashboard',
        path: '/app/dashboard',
        icon: HomeIcon,
      }
    ],
  },
  {
    title: 'Administración de Gerencia',
    children: [
      {
        text: 'Datos de Gerencia',
        path: '/app/management-data',
        icon: ManagementIcon,
      },
      {
        text: 'Administración de contenidos',
        path: '/app/content-management',
        icon: DocumentIcon,
        children: [
          /* {
            text: 'Administrar páginas',
            path: '/app/content-management/manage-pages',
          }, */
          {
            text: 'Administrar grupo de noticias',
            path: '/app/content-management/manage-group-news',
            codename: 'add_groupnews',
          },
          {
            text: 'Administrar banners',
            path: '/app/content-management/manage-banners',
            codename: 'add_banners',
          }, {
            text: 'Administrar notificaciones',
            path: '/app/content-management/manage-notifications',
            codename: 'add_notifications',
          }, {
            text: 'Administrar preguntas frecuentes',
            path: '/app/content-management/manage-questions',
            codename: 'add_questions',
          }, {
            text: 'Administrar eventos',
            path: '/app/content-management/manage-events',
            codename: 'add_events',
          }, {
            text: 'Administrar grupo de posteos',
            path: '/app/content-management/manage-post',
            codename: 'add_grouppost',
          }, {
            text: 'Administrar revistas',
            path: '/app/content-management/manage-magazine',
            codename: 'add_magazine',
          }, {
            text: 'Administrar revista natura',
            path: '/app/content-management/manage-nature-magazine',
            codename: 'add_naturamagazine',
          }, {
            text: 'Administrar Noticias por ciclo',
            path: '/app/content-management/manage-cycle',
            codename: 'add_groupcycle',
          }, {
            text: 'Administrar Camino de Crecimiento',
            path: '/app/content-management/manage-growth-path',
            codename: 'add_growthpath',
          }, {
            text: 'Administrar Tarjetas de categoría',
            path: '/app/content-management/manage-card-category',
            codename: 'add_cdccardcategory',
          }, {
            text: 'Administrar Ofertas por ciclo',
            path: '/app/content-management/manage-offers-by-cycle',
            codename: 'add_offersbycycle',
          }, {
            text: 'Administrar Items de menu APP',
            path: '/app/content-management/manage-drawer',
            codename: 'add_drawer',
          }
        ],
      },
      {
        text: 'Administración de servicios',
        path: '/app/services-management',
        icon: ServiceIcon,
        children: [
          {
            text: 'Enviar FCM (push notifications)',
            path: '/app/service-management/manage-fcm',
            codename: 'add_fcmdevice',
          },
        ]
      }
    ]
  },
  {
    title: 'Gestión de usuarios - CMS',
    children: [
      {
        text: 'Creacion de usuarios',
        path: '/app/users/list',
        icon: AddUserIcon,
      },
      // {
      //   text: 'Administración de roles',
      //   path: '/app/roles-management',
      //   icon: UserIcon,
      // }
    ]
  },
]

export default navListRoutes
