/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react'
import React, {useContext} from 'react'
import { ThemeUI } from '../../../theme'
import { Button, Card, Text } from '../../atoms'
import { ContentSection } from '../../organisms'
import * as styles from './index.styles'
import { Context } from '../../../context/GroupPostContext'
import {create_group_posts} from "../../../redux/actions/post/create_group_posts";
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {update_group_posts} from "../../../redux/actions/post/update_group_posts";

const NewGroupPreview: React.FC = () => {
  const APP_URL = process.env.REACT_APP_URL
  const theme = useTheme() as ThemeUI
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const token = useSelector((state: any) => state.login.access)
  const { formData } = useContext(Context)
  let { id } = useParams()

  const handleSubmit = (token: any, formData: any) => {
    if (id) {
      if (formData.cover !== undefined) {
        if (!formData.cover?.name) {
          formData.cover = formData.cover.indexOf('/media/')>-1 ? undefined : formData.cover
        }
      } else {
        formData.cover = undefined
      }
      dispatch(update_group_posts({ token, formData, navigate }))
    } else {
      dispatch(create_group_posts({ token, formData, navigate }))
    }
  }

  return (
    <ContentSection
      style={styles.root}
      header={
        <Text textType='p' weight='bold'>Vista Previa</Text>
      }
    >
      <div css={(theme: any) => styles.preview(theme)}>
        <Card style={css({ overflow: 'hidden' })}>
          <img
              src={
                formData.cover
                    ? (formData.cover?.name ? URL.createObjectURL(formData.cover): APP_URL+''+formData.cover)
                    : 'https://via.placeholder.com/300x200'
              }
              alt="placeholder"
              css={styles.previewImage}
          />
          <Text textType='sm' style={styles.previewTitle(theme)}>
            {formData.title}
          </Text>
          <div css={styles.previewDescription(theme)}>
            <Text textType="sm" weight="light">
              {formData.description}
            </Text>
            <Text textType="sm" weight="light">
              {formData.cycle}
            </Text>
            <Text textType="sm" weight="light">
              {formData.category}
            </Text>
          </div>
        </Card>
      </div>
      <Button onClick={() => handleSubmit(token, formData)}
              disabled={!formData.title || !formData.cycle || !formData.category || !formData.management || !formData.cover}>
        {!id ? 'Publicar' : 'Guardar'} grupo
      </Button>
    </ContentSection>
  )
}


export default NewGroupPreview
