/** @jsxImportSource @emotion/react */
import React, { Fragment, useEffect } from 'react'
import { useNavigate } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { CreateSide, ManageTable } from '../../../components/templates'
import * as styles from '../index.styles'
import { columns } from './mockData'
import { breadcrumbItems } from '../../utils'
import { Breadcrumb } from '../../../components/atoms'
import {offers} from "../../../redux/actions/offers_by_cycle/offers";

const { index, contentManagement, manageOffers } = breadcrumbItems

const ManageOffers: React.FC = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const token = useSelector((state: any) => state.login.access)
  const offers_data = useSelector((state: any) => state.offers.data)

  const cycleData = useSelector((state: any) => {
    return state.cycle.data.map((v:any) => {
      return { value: v.cycle, label: v.cycle }
    })
  })
  cycleData.push({ value: 'Ninguno', label: 'Ninguno'})

  useEffect(() => {
    dispatch(offers({ token }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Fragment>
      <Breadcrumb items={[index, contentManagement, manageOffers]} />
      <div css={styles.root}>
        <div css={styles.main}>
          <ManageTable
            title="Administrar Ofertas por ciclo"
            subtitle="Ofertas publicadas"
            data={offers_data || []}
            stateCategory={true}
            columns={columns}
            cycle={cycleData || []} management={[]}
          />
        </div>
        <div css={styles.right}>
          <CreateSide
            title="Crear nueva oferta"
            message="Massa vulputate id placerat vitae ornare a orci. Gravida augue viverra pretium adipiscing mauris."
            textButton="Crear nueva oferta"
            onCreate={() => navigate('/app/content-management/create-offers-by-cycle')}
          />
        </div>
      </div>
    </Fragment>
  )
}

export default ManageOffers
