import {takeLatest, put, call} from 'redux-saga/effects';
import {GET_GROUP_DETAIL_NEWS_ERROR, GET_GROUP_DETAIL_NEWS_START, GET_GROUP_DETAIL_NEWS_SUCCESS} from "../../types";

import apiCall from '../../api/apiGet';

export function* get({payload}) {
  try {
    const url = `/group_news/${payload.id}`;
    const method = 'GET';
    const headers = {Authorization: 'Bearer ' + payload.token};
    const results = yield call(apiCall, url, method, headers);
    yield put({type: GET_GROUP_DETAIL_NEWS_SUCCESS, results: results.data});
  } catch (error) {
    yield put({type: GET_GROUP_DETAIL_NEWS_ERROR, error});
  }
}

export default function* group_detail() {
  yield takeLatest(GET_GROUP_DETAIL_NEWS_START, get);
}
