import {DETAIL_BANNER_ERROR, DETAIL_BANNER_START, DETAIL_BANNER_SUCCESS} from "../../types";

export default function detail_banner(state, action) {
    switch (action.type) {
        case DETAIL_BANNER_START:
            return {
                ...state,
                data: [],
                loading: true,
                error: null
            };
        case DETAIL_BANNER_SUCCESS:
            return {
                ...state,
                data: action.results,
                loading: false,
                error: null
            };
        case DETAIL_BANNER_ERROR:
            return {
                ...state,
                data: [],
                loading: false,
                error: action.detail
            };
        default:
            return { ...state };
    }
}
