/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react'
import React, {useContext} from 'react'
import { ThemeUI } from '../../../theme'
import { Button, Card, Text } from '../../atoms'
import { ContentSection } from '../../organisms'
import * as styles from './index.styles'
import { Context } from '../../../context/GroupNCycleContext'
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {create_group_cycle} from "../../../redux/actions/group_cycle/create_group_cycle";
import {update_group_cycle} from "../../../redux/actions/group_cycle/update_group_cycle";

const NewGroupPreview: React.FC = () => {
  const theme = useTheme() as ThemeUI
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const token = useSelector((state: any) => state.login.access)
  let { id } = useParams()

  const { formData } = useContext(Context)

  const handleSubmit = (token: any, formData: any) => {
    if (id) {
      dispatch(update_group_cycle({ token, formData, navigate }))
    } else {
      dispatch(create_group_cycle({ token, formData, navigate }))
    }
  }

  return (
    <ContentSection
      style={styles.root}
      header={
        <Text textType='p' weight='bold'>Vista Previa</Text>
      }
    >
      <div css={(theme: any) => styles.preview(theme)}>
        <Card style={css({ overflow: 'hidden' })}>
          <div css={styles.previewDescription(theme)}>
            <Text textType="sm" weight="light">
              {formData.cycle}
            </Text>
            <Text textType="sm" weight="light">
              {formData.category}
            </Text>
          </div>
        </Card>
      </div>
      <Button onClick={() => handleSubmit(token, formData)} disabled={!formData.cycle || !formData.category}>
        {formData.active ? 'Publicar' : 'Guardar'} grupo
      </Button>
    </ContentSection>
  )
}


export default NewGroupPreview
