import {DELETE_OFFERS_ERROR, DELETE_OFFERS_START, DELETE_OFFERS_SUCCESS} from "../../types";

export default function delete_offers(state, action) {
    switch (action.type) {
        case DELETE_OFFERS_START:
            return {
                ...state,
                loading: true,
                error: null
            };
        case DELETE_OFFERS_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null
            };
        case DELETE_OFFERS_ERROR:
            return {
                ...state,
                loading: false,
                error: action.detail
            };
        default:
            return {...state};
    }
}
