import {takeLatest, put, call} from 'redux-saga/effects';
import apiCall from '../../api';
import {CREATE_GROUP_CYCLE_ERROR, CREATE_GROUP_CYCLE_START, CREATE_GROUP_CYCLE_SUCCESS} from "../../types";

export function* create({payload}) {
    try {
        const url = '/group_cycle/';
        const method = 'POST';
        const headers = {Authorization: 'Bearer ' + payload.token, 'Content-Type':'application/json'};
        const results = yield call(apiCall, url, method, payload.formData, headers);
        yield put({type: CREATE_GROUP_CYCLE_SUCCESS, results: results.data});
        yield put(payload.navigate('/app/content-management/manage-cycle'));
    } catch (error) {
        yield put({type: CREATE_GROUP_CYCLE_ERROR, error});
    }
}

export default function* create_group_cycle() {
    yield takeLatest(CREATE_GROUP_CYCLE_START, create);
}
