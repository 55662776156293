import {UPDATE_CDC_CARD_ERROR, UPDATE_CDC_CARD_START, UPDATE_CDC_CARD_SUCCESS} from "../../types";

export default function update_card_category(state, action) {
    switch (action.type) {
        case UPDATE_CDC_CARD_START:
            return {
                ...state,
                loading: true,
                error: null
            };
        case UPDATE_CDC_CARD_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null
            };
        case UPDATE_CDC_CARD_ERROR:
            return {
                ...state,
                loading: false,
                error: action.detail
            };
        default:
            return {...state};
    }
}
