import { takeLatest, put, call } from 'redux-saga/effects';
import apiCall from '../../api';
import {
  CREATE_EVENTS_START,
  CREATE_EVENTS_SUCCESS,
  CREATE_EVENTS_ERROR,
} from "../../types";

export function* create({ payload }) {
  try {
    const url = '/events/';
    const method = 'POST';
    const headers = { Authorization: 'Bearer ' + payload.token, 'Content-Type': 'multipart/form-data' };
    const data = (object) => Object.keys(object).reduce((formData, key) => {
        formData.append(key, object[key]);
        return formData;
    }, new FormData());
    const results = yield call(apiCall, url, method, data(payload.formData), headers);
    yield put({ type: CREATE_EVENTS_SUCCESS, results: results.data });
    yield put(payload.navigate('/app/content-management/manage-events'));
  } catch (error) {
    yield put({ type: CREATE_EVENTS_ERROR, error });
  }
}

export default function* events() {
  yield takeLatest(CREATE_EVENTS_START, create);
}
