export const columns = [
  {
    Header: 'Titulo',
    accessor: 'title',
  },
  {
    Header: 'Contenido',
    accessor: 'description',
  },
  {
    Header: 'Ciclo',
    accessor: 'cycle',
  },
  {
    Header: 'Nivel',
    accessor: 'category',
  },
  {
    Header: 'Imagen',
    accessor: 'image',
  },
  {
    Header: 'Enlace',
    accessor: 'url',
  },
  {
    Header: 'Editar',
    accessor: 'editNatureMagazine',
  },
  {
    Header: 'Eliminar',
    accessor: 'deleteNatureMagazine',
  },
  {
    Header: 'Estado',
    accessor: 'active',
  },
]
