import { createContext, FC, useState } from 'react'

export type FormData = {
  id?: string
  title: string
  description: string
  image?: File
  is_image: boolean
  url_video: string
  active: boolean
  cycle: string
  category: string
  management: object
  date_initial: Date
  date_ending: Date
}

export interface FormDataContext {
  formData: FormData
  handleChangeFormData: (data: Partial<FormData>) => void,
  handleUpdateFormData: (data: Partial<FormData>) => void,
}

const initialData: FormData = {
  id: '',
  title: '',
  description: '',
  image: undefined,
  is_image: true,
  url_video: '',
  active: false,
  cycle: '',
  category: 'Bronce',
  management: [],
  date_initial: new Date(),
  date_ending: new Date(),
}
export const Context = createContext<FormDataContext>({
  formData: initialData,
  handleChangeFormData: (data: Partial<FormData>) => {},
  handleUpdateFormData: (data: Partial<FormData>) => {},
})

const NewFormDataProvider: FC = (props) => {
  const [formData, setFormData] = useState<FormData>(initialData)

  const handleChangeFormData = (data: Partial<FormData>) =>
      setFormData({ ...formData, ...data })

  const handleUpdateFormData = (data: Partial<FormData>) => {
    if (data && data?.id) {
      setFormData({
        ...formData,
        id: data.id,
        title: data.title || initialData.title,
        description: data.description || initialData.description,
        image: data.image || initialData.image,
        is_image: data.is_image || initialData.is_image,
        url_video: data.url_video || initialData.url_video,
        cycle: data.cycle || initialData.cycle,
        category: data.category || initialData.category,
        management: data.management || initialData.management,
        active: data.active || false,
        date_initial: data.date_initial || initialData.date_initial,
        date_ending: data.date_ending || initialData.date_ending,
      })
    }
  }
  return (
    <Context.Provider value={{ formData, handleChangeFormData, handleUpdateFormData }}>
      {props.children}
    </Context.Provider>
  )
}

export default NewFormDataProvider
