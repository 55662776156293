export const columns = [
    {
        Header: 'Titulo',
        accessor: 'title',
    },
    {
        Header: 'Descripción',
        accessor: 'description',
    },
    {
        Header: 'Imagen',
        accessor: 'image',
    },
    {
        Header: 'Editar',
        accessor: 'editPosts',
    },
    {
        Header: 'Eliminar',
        accessor: 'deletePosts',
    },
    {
        Header: 'Estado',
        accessor: 'active',
    },
]
