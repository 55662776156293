import {takeLatest, put, call} from 'redux-saga/effects';
import {GET_MANAGEMENT_START, GET_MANAGEMENT_SUCCESS, GET_MANAGEMENT_ERROR} from "../../types";

import apiCall from '../../api/apiGet';

export function* getManagement({payload}) {
  try {
    const url = '/management/';
    const method = 'GET';
    const headers = {Authorization: 'Bearer ' + payload.token};
    const results = yield call(apiCall, url, method, headers);
    yield put({type: GET_MANAGEMENT_SUCCESS, results: results.data});
  } catch (error) {
    yield put({type: GET_MANAGEMENT_ERROR, error});
  }
}

export default function* management() {
  yield takeLatest(GET_MANAGEMENT_START, getManagement);
}
