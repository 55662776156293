import { createContext, FC, useState } from 'react'

export type Data = {
  title: string
  body: string
  image?: File
  registration_id: Array<string>
}
export interface DataContext {
  formData: Data,
  handleChangeFormData: (data: Partial<Data>) => void,
}

export const Context = createContext<DataContext>({
  formData: {
    title: '',
    body: '',
    image: undefined,
    registration_id: [],
  },
  handleChangeFormData: (data: Partial<Data>) => { },
})

const NewFormDataProvider: FC = (props) => {
  const [formData, setFormData] = useState<Data>({
    title: '',
    body: '',
    image: undefined,
    registration_id: []
  })

  const handleChangeFormData = (data: Partial<Data>) => setFormData({ ...formData, ...data })

  return (
    <Context.Provider value={{ formData, handleChangeFormData }}>
      {props.children}
    </Context.Provider>
  )
}

export default NewFormDataProvider
