import { css } from '@emotion/react'
import { ThemeUI } from '../../../theme'
import { StyleProps } from './types'

type Props = { theme: ThemeUI } & StyleProps

const textStyle = ({ theme, weight, textType, italic, style }: Props) => css({
  ...theme.typography.types[textType || 'p'],
  fontWeight: theme.typography.weights[weight || 'normal'],
  fontStyle: italic ? 'italic' : 'normal',
}, style)


export { textStyle }