import * as React from "react"

export default React.memo((props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={13}
    height={12}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.1 2.17s-.1 0 0 0c0-.1 0-.1-.1-.1l-2.1-2H2.3c-.1-.1-.1 0-.1.1v.8h-1c-.1 0-.2.1-.2.2v10.7s.1.1.2.1h8.6c.1 0 .2-.1.2-.2v-.8h1c.1 0 .2-.1.2-.2l-.1-8.6c0 .1 0 0 0 0zm-.7-.1H9V.57l1.4 1.5zm-.9 9.5H1.4V1.27h.8v9.5c0 .1.1.2.2.2h7.2v.6h-.1zm1.2-1H2.5V.37h6.1v1.9c0 .1.1.2.2.2h1.9v8.1z"
    />
    <path
      d="M4 2.37h3.2c.1 0 .2-.1.2-.2s-.1-.2-.2-.2H4c-.1 0-.2.1-.2.2 0 .2.1.2.2.2zM3.8 3.47c0 .1.1.2.2.2h5.4c.1 0 .2-.1.2-.2s-.1-.2-.2-.2H4c-.1 0-.2.1-.2.2zM4 4.97h4.8c.1 0 .2-.1.2-.2s-.1-.2-.2-.2H4c-.1 0-.2.1-.2.2s.1.2.2.2zM4 6.27h2c.1 0 .2-.1.2-.2s-.1-.2-.2-.2H4c-.1 0-.2.1-.2.2s.1.2.2.2zM9.3 5.87H7c-.1 0-.1.1-.1.2s.1.2.2.2h2.3c.1 0 .2-.1.2-.2-.1-.1-.2-.2-.3-.2zM4 7.47h4.3c.1 0 .2-.1.2-.2s-.1-.2-.2-.2H4c-.1 0-.2.1-.2.2s.1.2.2.2zM5.9 8.37H4c-.1 0-.2.1-.2.2s.1.2.2.2h1.9c.1 0 .1-.1.1-.2s0-.2-.1-.2zM9.3 8.37H8.1c-.1 0-.1.1-.1.2s0 .2.1.2h1.2c.1 0 .2-.1.2-.2s-.1-.2-.2-.2z"
    />
  </svg>
))
