/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import React, {FC, useContext, useEffect, useState} from 'react'
import { Context } from '../../../context/GrowthPathContext'
import {BaseInput, FileInput, SwitchInput, Text, TextareaInput} from '../../atoms'
import { ContentSection } from '../../organisms'
import {CATEGORIES_ALL} from "../../../category_all";
import {useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import Select from "react-select";
import * as styles from "../Header/index.styles";

const NewForm: FC = () => {
  const { formData, handleChangeFormData: handleChange, handleUpdateFormData: handleData } = useContext(Context)
  let { id } = useParams();
  const detail_data = useSelector((state: any) => state.growth_path.data)
  const growth_data = id ? detail_data?.find((r:any) => r.id === parseInt(id+'')) : null

  useEffect(() => {
    if (!!id) {
      handleData(growth_data)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, growth_data])

  const categoryInit = growth_data?.category ? { value: growth_data.category, label: growth_data.category } : { value: '', label: '' }

  const handleCategory = (e: any) => {
    handleChange({ category: e.value })
  }

  const [isTitleValid, setTitleValid] = useState<boolean>(false)
  const [isDescriptionValid, setIsDescriptionValid] = useState<boolean>(false)
  const [isUrlValid, setIsUrlValid] = useState<boolean>(false)

  useEffect(() => {
    setTitleValid(formData.title.length > 2)
  }, [formData.title])
  useEffect(() => {
    setIsDescriptionValid(formData.description.length > 2)
  }, [formData.description])
  useEffect(() => {
    setIsUrlValid(formData.url.length > 10)
  }, [formData.url])

  return (
    <ContentSection
      style={css`display: grid; gap: 18px;`}
      header={
        <React.Fragment>
          <Text textType='h4' weight='black'>{id ? 'Editar' : 'Nuevo'} camino de crecimiento</Text>
        </React.Fragment>
      }
    >
      <BaseInput
        label='Título'
        value={formData.title}
        onChange={(value) => handleChange({ title: value })}
        placeholder='Escribe el titulo de tu entrada'
        maxLength={500}
        error={!isTitleValid && formData.title.length > 0}
        success={isTitleValid}
        hint={!isTitleValid && formData.title.length > 0 ? 'Digite mas de dos letras' : ''}
      />
      <TextareaInput
        label='Contenido'
        value={formData.description}
        onChange={(value) => handleChange({ description: value })}
        placeholder='Escribe el contenido de tu entrada'
        maxLength={1500}
        error={!isDescriptionValid && formData.description.length > 0}
        success={isDescriptionValid}
        hint={!isDescriptionValid && formData.description.length > 0 ? 'Digite mas de dos letras' : ''}
      />
      <div style={{ width: 360 }}>
        <FileInput
          label='Imagen'
          value={formData.image}
          onChange={(value) => handleChange({ image: value })}
          accept='image/*'
        />
      </div>
      <BaseInput
        label='Link'
        value={formData.url}
        onChange={(value) => handleChange({ url: value })}
        placeholder='URL'
        maxLength={1500}
        error={!isUrlValid && formData.url.length > 0}
        success={isUrlValid}
        hint={!isUrlValid && formData.url.length > 0 ? 'Introduce una url Ej.:https://www.google.com/' : ''}
      />
      <Text textType="label">
        CATEGORÍA :{' '}
        <Select css={styles.input}
            options={CATEGORIES_ALL}
            defaultValue={categoryInit}
            onChange={(e) =>  handleCategory(e)}
        />
      </Text>
      <SwitchInput checked={formData.active}
                   onCheckedChange={(value) => handleChange({ active: value })}
                   label={formData.active ? 'Activo': 'Inactivo'}
      />
    </ContentSection>
  )
}

export default NewForm
