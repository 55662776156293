export const columns = [
  {
    Header: 'Titulo',
    accessor: 'title',
  },
  {
    Header: 'Contenido',
    accessor: 'description',
  },
  {
    Header: 'Nivel',
    accessor: 'category',
  },
  {
    Header: 'Enlace',
    accessor: 'url',
  },
  {
    Header: 'Imagen',
    accessor: 'image',
  },
  {
    Header: 'Imagen descripción',
    accessor: 'image_description',
  },
  {
    Header: 'Editar',
    accessor: 'editOffers',
  },
  {
    Header: 'Eliminar',
    accessor: 'deleteOffers',
  },
  {
    Header: 'Estado',
    accessor: 'active',
  },
]