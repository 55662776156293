/** @jsxImportSource @emotion/react */
import React from 'react'
import { css, SerializedStyles } from '@emotion/react'
import { Card } from '../../atoms'

type Props = {
  children: React.ReactNode,
  style?: SerializedStyles,
  padding?: string,
}

const CardSection: React.FC<Props> = ({ children, padding, style }) => {
  const cardStyle = css`
    padding: ${padding};
    ${style};
  `
  return (
    <Card style={cardStyle}>
      {children}
    </Card>
  )
}

export default CardSection
