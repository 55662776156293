/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import React, {FC, useContext, useEffect, useState} from 'react'
import { Context } from '../../../context/NotificationContext'
import {BaseInput, FileInput, SwitchInput, Text, TextareaInput} from '../../atoms'
import { ContentSection } from '../../organisms'
import Select from "react-select";
import {CATEGORIES_ALL} from "../../../category_all";
import {useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import * as styles from "../Header/index.styles";
import DatePicker from 'react-date-picker'
import moment from "moment";

const validateNumber = (data: string) =>
    /^[0-9]+$/.test(data)

const NewForm: FC = () => {
  const { formData, handleChangeFormData: handleChange, handleUpdateFormData: handleData } = useContext(Context)
  let { id } = useParams();
  const detail_data = useSelector((state: any) => state.notifications.data)
  const notification_data = id ? detail_data?.find((r:any) => r.id === parseInt(id+'')) : null

  const managements_list = useSelector((state: any) => state.management.data)
  // const managementInit = notification_data?.management ? managements_list.filter((r:any) => notification_data?.management.indexOf(r.id) > -1) : null
  const categoryInit = notification_data?.category ? { value: notification_data.category, label: notification_data.category } : { value: '', label: '' }

  useEffect(() => {
    if (!!id) {
      handleData(notification_data)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, notification_data])


  const handleCategory = (e: any) => {
    handleChange({ category: e.value })
  }

  const handleManagement = (e: any) => {
    let temp: any[] = []
    e.forEach((item: { id: any }, index: any) => {
      temp.push(item.id)
    })
    handleChange({ management: temp })
  }

  const [isTitleValid, setTitleValid] = useState<boolean>(false)
  const [isDescriptionValid, setIsDescriptionValid] = useState<boolean>(false)
  const [isUrlVideoValid, setIsUrlVideoValid] = useState<boolean>(false)
  const [isCycleValid, setIsCycleValid] = useState<boolean>(false)

  useEffect(() => {
    setTitleValid(formData.title.length > 2)
  }, [formData.title])
  useEffect(() => {
    setIsDescriptionValid(formData.description.length > 2)
  }, [formData.description])
  useEffect(() => {
    setIsUrlVideoValid(formData.url_video.length > 10)
  }, [formData.url_video])
  useEffect(() => {
    setIsCycleValid(validateNumber(formData.cycle))
  }, [formData.cycle])


  return (
    <ContentSection
      style={css`display: grid; gap: 18px;`}
      header={
        <React.Fragment>
          <Text textType='h4' weight='black'>{id ? 'Editar':'Nueva'} notificación</Text>
        </React.Fragment>
      }
    >
      <BaseInput
        label='Título'
        value={formData.title}
        onChange={(value) => handleChange({ title: value })}
        placeholder='Escribe el titulo de tu entrada'
        maxLength={500}
        error={!isTitleValid && formData.title.length > 0}
        success={isTitleValid}
        hint={!isTitleValid && formData.title.length > 0 ? 'Digite mas de dos letras' : ''}
      />
      <TextareaInput
        label='Contenido'
        value={formData.description}
        onChange={(value) => handleChange({ description: value })}
        placeholder='Escribe el contenido de tu entrada'
        maxLength={1500}
        error={!isDescriptionValid && formData.description.length > 0}
        success={isDescriptionValid}
        hint={!isDescriptionValid && formData.description.length > 0 ? 'Digite mas de dos letras' : ''}
      />
      <div style={{ width: 360 }}>
        <FileInput
          label={formData.is_image ? 'Imagen' : 'Video'}
          value={formData.image}
          onChange={(value) => handleChange({ image: value })}
          accept={formData.is_image ? 'image/*' : 'video/*'}
        />
      </div>
      <SwitchInput checked={formData.is_image}
        onCheckedChange={(value) => handleChange({ is_image: value })}
        label={formData.is_image ? 'Subir Imagen': 'Subir Video'}
      />

      <BaseInput
        label='Url'
        value={formData.url_video}
        onChange={(value) => handleChange({ url_video: value })}
        placeholder='Escribe el link'
        maxLength={1500}
        error={!isUrlVideoValid && formData.url_video.length > 0}
        success={isUrlVideoValid}
        hint={!isUrlVideoValid && formData.url_video.length > 0 ? 'Introduce una url Ej.:https://www.google.com/' : ''}
      />
      <Text textType="label">
        FECHA DE INICIO:{' '} <br />
        <DatePicker
          format={"y-MM-dd"}
          css={styles.input}
          value={moment(formData.date_initial).utc().toDate()}
          onChange={(value: Date) =>
            handleChange({ date_initial: value })
          }
        />
      </Text>
      <Text textType="label">
        FECHA DE FINALIZACION:{' '} <br />
        <DatePicker
          format={"y-MM-dd"}
          css={styles.input}
          value={moment(formData.date_ending).utc().toDate()}
          onChange={(value: Date) =>
            handleChange({ date_ending: value })
          }
        />
      </Text>
      <Text textType="label">
        CATEGORÍA :{' '}
        <Select css={styles.input}
                placeholder="Selecciona opciones de categoría"
                options={CATEGORIES_ALL}
                defaultValue={categoryInit}
                onChange={(e) =>  handleCategory(e)}
        />
      </Text>
      <Text textType="label">
        GERENCIA :{' '}
        <Select
          css={styles.input}
          isMulti
          placeholder="Selecciona opciones de gerencia"
          options={managements_list}
          getOptionValue={(option: any) => `${option['id']}`}
          getOptionLabel={(option: any) => `${option['management']}`}
          onChange={(e) =>  handleManagement(e)}
        />
      </Text>
      <BaseInput
        label="Ciclo"
        placeholder="Escribe un código de  ciclo"
        value={formData.cycle}
        onChange={(value) => handleChange({ cycle: value })}
        maxLength={30}
        error={!isCycleValid && formData.cycle.length > 0}
        success={isCycleValid}
        hint={!isCycleValid && formData.cycle.length > 0 ? 'Digite numeros' : ''}
      />
      <SwitchInput checked={formData.active}
        onCheckedChange={(value) => handleChange({ active: value })}
        label={formData.active ? 'Activo': 'Inactivo'}
      />
    </ContentSection>
  )
}

export default NewForm
