import {GET_GROUP_DETAIL_NEWS_ERROR, GET_GROUP_DETAIL_NEWS_START, GET_GROUP_DETAIL_NEWS_SUCCESS} from "../../types";

export default function group_detail(state, action) {
    switch (action.type) {
        case GET_GROUP_DETAIL_NEWS_START:
            return {
                ...state,
                data: [],
                loading: true,
                error: null
            };
        case GET_GROUP_DETAIL_NEWS_SUCCESS:
            return {
                ...state,
                data: action.results,
                loading: false,
                error: null
            };
        case GET_GROUP_DETAIL_NEWS_ERROR:
            return {
                ...state,
                data: [],
                loading: false,
                error: action.detail
            };
        default:
            return { ...state };
    }
}
