import {takeLatest, put, call} from 'redux-saga/effects';
import apiCall from '../../api';
import {UPDATE_GROUP_NEWS_ERROR, UPDATE_GROUP_NEWS_START, UPDATE_GROUP_NEWS_SUCCESS} from "../../types";

export function* updateGroup({payload}) {
  try {
    const url = `/group_news/${payload.formData.id}/`;
    const method = 'PUT';
    const headers = {Authorization: 'Bearer ' + payload.token, 'Content-Type':'application/json;charset=utf-8'};
    console.log(payload.formData)
    const results = yield call(apiCall, url, method, payload.formData, headers);
    yield put({type: UPDATE_GROUP_NEWS_SUCCESS, results: results.data});
    yield put(payload.navigate('/app/content-management/manage-group-news'));
  } catch (error) {
    yield put({type: UPDATE_GROUP_NEWS_ERROR, error});
  }
}

export default function* update_group_news() {
  yield takeLatest(UPDATE_GROUP_NEWS_START, updateGroup);
}