/** @jsxImportSource @emotion/react */
import React, { Fragment } from 'react'
import { useNavigate } from 'react-router'
import { CreateSide, ManageTable } from '../../../components/templates'
import * as styles from '../index.styles'
import { columns } from './mockData'
import { breadcrumbItems } from '../../utils'
import { Breadcrumb, StateSelectInput } from '../../../components/atoms'
import {useSelector} from "react-redux";

const { index, contentManagement, manageNews } = breadcrumbItems

const ManageNews: React.FC = () => {
  const navigate = useNavigate()
  const groups = useSelector((state: any) => state.group_news)
  const formatData = groups.data.map((item: any) => ({
    ...item,
    state: (
      <StateSelectInput
        defaultValue={{ value: 'disabled', label: 'Desactivado' }}
      />
    ),
  }))
  return (
    <Fragment>
      <Breadcrumb items={[index, contentManagement, manageNews]} />
      <div css={styles.root}>
        <div css={styles.main}>
          <ManageTable
            title="Administrar Noticias"
            subtitle="Noticias publicadas"
            columns={columns}
            data={formatData || []}
            cycle={[]}
            management={[]}
            stateCategory={false}
          />
        </div>
        <div css={styles.right}>
          <CreateSide
            title="Crear nueva noticia"
            message="Massa vulputate id placerat vitae ornare a orci. Gravida augue viverra pretium adipiscing mauris."
            textButton="Crear nueva noticia"
            onCreate={() => navigate('/app/content-management/create-new')}
          />
        </div>
      </div>
    </Fragment>
  )
}

export default ManageNews
