import { css } from '@emotion/react'

export const root = css`
  display: flex;
  min-height: 100vh;
  min-width: 100vw;
  position: relative;

`

export const main = css`
  display: flex;
  flex-direction: column; 
  width: 100%;
  background-color: #FAFAFA;
  justify-content: flex-start;
`

export const content = css`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
  flex-grow: 1;
`