import { Navigate, Outlet, RouteObject } from 'react-router'
import { MainLayout } from '../layouts'
import {
  Login,
  Dashboard,
  //ManagePage,
  ManageNews,
  ManageGroupNews,
  ManageBanners,
  ManageNotifications,
  ManageRoles,
  CreateNew,
  CreateGroupNew,
  CreateBanner,
  CreateNotification,
  CreateQuestions,
  SendNotification,
  ManageFCM,
  ManageQuestions,
  ManageEvents,
  CreateEvents,
  ManagePost,
  CreatePost,
  ManageMagazine,
  CreateMagazine,
  ManagementData,
  CreateManagement,
  ManageNatureMagazine,
  CreateNatureMagazine,
  CreateGroupPost,
  WatchGroupNews,
  ManageCycle,
  CreateGroupNCycle,
  WatchGroupPosts,
  ManageGrowthPath,
  ManageOffers,
  CreateOffers,
  ManageCardCategory,
  CreateCardCategory,
  CreateNewCycle,
  WatchGroupCycle,
  CreateGrowthPath,
  ManageUsers,
  CreateUsers,
  CreateDrawer,
  ManageDrawer,
} from '../pages'
import { Text } from '../components/atoms'

type RoutesMap = (isLoggedIn: boolean) => RouteObject[]

export const routesMap: RoutesMap = (isLoggedIn: boolean) => [
  {
    path: 'app',
    element: isLoggedIn ? <MainLayout /> : <Navigate to="/login" />,
    children: [
      { path: 'dashboard', element: <Dashboard /> },
      {
        path: 'content-management',
        element: <Outlet />,
        children: [
          //{ path: 'manage-pages', element: <ManagePage /> },
          //{ path: 'create-page', element: <Outlet /> },
          { path: 'manage-group-news', element: <ManageGroupNews /> },
          { path: 'manage-news', element: <ManageNews /> },
          { path: 'create-group-new', element: <CreateGroupNew/> },
          { path: 'edit-group-new/:id', element: <CreateGroupNew/> },
          { path: 'create-new/:id', element: <CreateNew /> },
          { path: 'edit-new/:idNews', element: <CreateNew /> },
          { path: 'watch-group-news/:id', element: <WatchGroupNews /> },
          { path: 'watch-group-posts/:id', element: <WatchGroupPosts /> },
          { path: 'manage-banners', element: <ManageBanners /> },
          { path: 'create-banner', element: <CreateBanner /> },
          { path: 'edit-banner/:id', element: <CreateBanner /> },
          { path: 'manage-questions', element: <ManageQuestions /> },
          { path: 'create-questions', element: <CreateQuestions /> },
          { path: 'edit-questions/:id', element: <CreateQuestions /> },
          { path: 'manage-notifications', element: <ManageNotifications /> },
          { path: 'create-notification', element: <CreateNotification /> },
          { path: 'edit-notification/:id', element: <CreateNotification /> },
          { path: 'manage-events', element: <ManageEvents /> },
          { path: 'create-events', element: <CreateEvents /> },
          { path: 'edit-events/:id', element: <CreateEvents /> },
          { path: 'manage-post', element: <ManagePost /> },
          { path: 'create-post/:id', element: <CreatePost /> },
          { path: 'edit-post/:idPost', element: <CreatePost /> },
          { path: 'create-group-post', element: <CreateGroupPost /> },
          { path: 'edit-group-post/:id', element: <CreateGroupPost /> },
          { path: 'manage-magazine', element: <ManageMagazine /> },
          { path: 'create-magazine', element: <CreateMagazine /> },
          { path: 'edit-magazine/:id', element: <CreateMagazine /> },
          { path: 'manage-nature-magazine', element: <ManageNatureMagazine /> },
          { path: 'manage-cycle', element: <ManageCycle /> },
          { path: 'create-nature-magazine', element: <CreateNatureMagazine /> },
          { path: 'edit-nature-magazine/:id', element: <CreateNatureMagazine /> },
          { path: 'create-group-n-cycle', element: <CreateGroupNCycle /> },
          { path: 'edit-group-n-cycle/:id', element: <CreateGroupNCycle /> },
          { path: 'add', element: <Outlet /> },
          { path: 'watch-group-cycle/:id', element: <WatchGroupCycle /> },
          { path: 'create-news_cycle/:id', element: <CreateNewCycle /> },
          { path: 'edit-news_cycle/:idCycle', element: <CreateNewCycle /> },
          { path: 'manage-growth-path', element: <ManageGrowthPath /> },
          { path: 'create-growth-path', element: <CreateGrowthPath /> },
          { path: 'edit-growth-path/:id', element: <CreateGrowthPath /> },
          { path: 'manage-card-category', element: <ManageCardCategory /> },
          { path: 'create-card-category', element: <CreateCardCategory /> },
          { path: 'edit-card-category/:id', element: <CreateCardCategory /> },
          { path: 'manage-offers-by-cycle', element: <ManageOffers /> },
          { path: 'create-offers-by-cycle', element: <CreateOffers /> },
          { path: 'edit-offers-by-cycle/:id', element: <CreateOffers /> },
          { path: 'manage-drawer', element: <ManageDrawer /> },
          { path: 'create-drawer', element: <CreateDrawer /> },
          { path: 'edit-drawer/:id', element: <CreateDrawer /> },
        ],
      },
      {
        path: 'service-management',
        element: <Outlet />,
        children: [
          { path: 'manage-fcm', element: <ManageFCM /> },
          { path: 'send-notification', element: <SendNotification /> },
        ]
      },
      {
        path: 'users',
        element: <Outlet />,
        children: [
          { path: 'list', element: <ManageUsers /> },
          { path: 'create', element: <CreateUsers /> },
          { path: 'edit/:id', element: <CreateUsers /> },
        ]
      },
      { path: 'roles-management', element: <ManageRoles /> },
      { path: 'management-data', element: <ManagementData /> },
      { path: 'create-managements', element: <CreateManagement /> },
      { path: 'edit-managements/:id', element: <CreateManagement /> },
      { path: '/app', element: <Navigate to="/app/dashboard" /> },
      { path: '*', element: <Text textType='h3'>Continuamos trabajando</Text> },
    ],
  },
  {
    path: '/',
    element: !isLoggedIn ? <Outlet /> : <Navigate to="/app/dashboard" />,
    children: [
      { path: 'login', element: <Login /> },
      { path: '/', element: <Navigate to="/login" /> },
    ],
  },
]
