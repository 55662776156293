/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import React, {FC, useContext, useEffect, useState} from 'react'
import { Context } from '../../../context/PostContext'
import {BaseInput, FileInput, SwitchInput, Text, TextareaInput} from '../../atoms'
import { ContentSection } from '../../organisms'
import {useParams} from "react-router";
import {useSelector} from "react-redux";

const NewForm: FC = () => {
  let { id, idPost } = useParams();
  const { formData, handleChangeFormData: handleChange, handleUpdateFormData: handleData } = useContext(Context)

  const detail_posts = useSelector((state: any) => state.group_detail_posts.data)
  const post_data = detail_posts?.posts?.find((r:any) => parseInt(idPost+'') === r.id)

  useEffect(() => {
    setTimeout(() => {
      handleChange({group: Number(id)})
    }, 1000)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!!idPost) {
      setTimeout(() => {
        handleData(post_data)
      }, 2000)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  const [isTitleValid, setTitleValid] = useState<boolean>(false)
  const [isDescriptionValid, setIsDescriptionValid] = useState<boolean>(false)

  useEffect(() => {
    setTitleValid(formData.title.length > 2)
  }, [formData.title])
  useEffect(() => {
    setIsDescriptionValid(formData.description.length > 2)
  }, [formData.description])

  
  return (
    <ContentSection
      style={css`display: grid; gap: 18px;`}
      header={
        <React.Fragment>
          <Text textType='h4' weight='black'>{idPost ? 'Editar':'Nuevo'} posteo</Text>
        </React.Fragment>
      }
    >
      <BaseInput
        label='Título'
        value={formData.title}
        onChange={(value) => handleChange({ title: value })}
        placeholder='Escribe el titulo de tu entrada'
        maxLength={500}
        error={!isTitleValid && formData.title.length > 0}
        success={isTitleValid}
        hint={!isTitleValid && formData.title.length > 0 ? 'Digite mas de dos letras' : ''}
      />
      <TextareaInput
        label='Contenido'
        value={formData.description}
        onChange={(value) => handleChange({ description: value })}
        placeholder='Escribe el contenido de tu entrada'
        maxLength={1500}
        error={!isDescriptionValid && formData.description.length > 0}
        success={isDescriptionValid}
        hint={!isDescriptionValid && formData.description.length > 0 ? 'Digite mas de dos letras' : ''}
      />
      <div style={{ width: 360 }}>
        <FileInput
          label={formData.is_image ? 'Imagen' : 'Video'}
          value={formData.image}
          onChange={(value) => handleChange({ image: value })}
          accept={formData.is_image ? 'image/*' : 'video/*'}
        />
      </div>
      <SwitchInput checked={formData.is_image}
        onCheckedChange={(value) => handleChange({ is_image: value })}
        label={formData.is_image ? 'Subir Imagen': 'Subir Video'}
      />

      <SwitchInput checked={formData.active}
                   onCheckedChange={(value) => handleChange({ active: value })}
                   label={formData.active ? 'Activo': 'Inactivo'}
      />
    </ContentSection>
  )
}

export default NewForm
