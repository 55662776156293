import {takeLatest, put, call} from 'redux-saga/effects';
import apiCall from '../../api';
import {UPDATE_GROUP_POSTS_ERROR, UPDATE_GROUP_POSTS_START, UPDATE_GROUP_POSTS_SUCCESS} from "../../types";

export function* updateGroupPosts({payload}) {
  try {
    const url = `/group_posts/${payload.formData.id}/`;
    const method = 'PUT';
    const headers = { Authorization: 'Bearer ' + payload.token, 'Content-Type': 'multipart/form-data' }
    const data = (object) => Object.keys(object).reduce((formData, key) => {
      formData.append(key, object[key])
      return formData
    }, new FormData())
    const results = yield call(apiCall, url, method, data(payload.formData), headers)
    yield put({type: UPDATE_GROUP_POSTS_SUCCESS, results: results.data});
    yield put(payload.navigate('/app/content-management/manage-post'))
  } catch (error) {
    yield put({type: UPDATE_GROUP_POSTS_ERROR, error});
  }
}

export default function* update_group_posts() {
    yield takeLatest(UPDATE_GROUP_POSTS_START, updateGroupPosts);
}