import {GET_NEWS_DETAIL_POST_ERROR, GET_NEWS_DETAIL_POST_START, GET_NEWS_DETAIL_POST_SUCCESS} from "../../types";

export default function posts_detail(state, action) {
  switch (action.type) {
    case GET_NEWS_DETAIL_POST_START:
      return {
        ...state,
        data: [],
        loading: true,
        error: null
      };
    case GET_NEWS_DETAIL_POST_SUCCESS:
      return {
        ...state,
        data: action.results,
        loading: false,
        error: null
      };
    case GET_NEWS_DETAIL_POST_ERROR:
      return {
        ...state,
        data: [],
        loading: false,
        error: action.detail
      };
    default:
      return { ...state };
    }
}
