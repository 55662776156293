/** @jsxImportSource @emotion/react */
import React from 'react'
import { Props } from './types'
import * as styles from './index.styles'
import { CheckIcon, ErrorIcon } from '../../../assets'

const Textarea: React.FC<Props> = ({ children, label, maxLength, hint, rightIcon, style = {}, ...props }) => {

  const showIcon = props.success || props.error || rightIcon
  return (
    <div css={[styles.rootContainer, style]}>
      {
        label && (
          <label css={(theme: any) => styles.label({ theme, ...props })}>
            {label}
          </label>
        )
      }
      <div css={styles.inputContainer}>
        <textarea
          {...props}
          rows={7}
          css={(theme: any) => styles.input({ theme, ...props })}
          onChange={(e) => props.onChange && props.onChange(e.target.value)}
          maxLength={maxLength}
        />
        {
          showIcon && (
            rightIcon ? rightIcon
              : props.error ? <ErrorIcon color="#F26A1B" />
                : props.success && <CheckIcon color="#04D99D" />
          )
        }
      </div>
      {
        hint && (
          <span css={(theme: any) => styles.hint({ theme, ...props })}>
            {hint}
          </span>
        )
      }
    </div >
  )
}

export default Textarea
