import { css } from "@emotion/react"

export const container = css`
  display: flex;
  align-items: center;
  padding: 0;
  & label {
    font-size: 14px;
    line-height: 16px;
    padding-left: 8px;
  }
`

export const root = css`
  background: #FFFF;
  border: 1px solid #6C6C6C;
  height: 20px;
  width: 20px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  &:disabled {
    background: #FAFAFA;
  }
  &[data-state="unchecked"] {
    svg {
      display: none;
    }
  }
`
