/** @jsxImportSource @emotion/react */
import React, { Fragment, useEffect } from 'react'
import { useNavigate } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { CreateSide, ManageTable } from '../../../components/templates'
import * as styles from '../index.styles'
import { columns } from './mockData'
import { breadcrumbItems } from '../../utils'
import { Breadcrumb } from '../../../components/atoms'
import { banners } from '../../../redux/actions/banner/banners'
import {useLocation} from "react-router-dom";

const { index, contentManagement, manageBanners } = breadcrumbItems

const ManageBanners: React.FC = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  let location = useLocation();
  const token = useSelector((state: any) => state.login.access)
  const data_banners = useSelector((state: any) => state.banners.data)
  const cycleData = useSelector((state: any) => {
    return state.cycle.data.map((v:any) => {
      return { value: v.cycle, label: v.cycle }
    })
  })
  cycleData.push({ value: 'Ninguno', label: 'Ninguno'})

  useEffect(() => {
    // dispatch(banners({ token }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (location.pathname === '/app/content-management/manage-banners') {
      setTimeout(() => {
        dispatch(banners({ token }));
      }, 1500)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <Fragment>
      <Breadcrumb items={[index, contentManagement, manageBanners]} />
      <div css={styles.root}>
        <div css={styles.main}>
          <ManageTable
            title="Administrar banners"
            subtitle="Banners publicados"
            columns={columns}
            data={data_banners || []}
            cycle={cycleData || []}
            management={[]}
            stateCategory={true}
          />
        </div>
        <div css={styles.right}>
          <CreateSide
            title="Crear nuevo banner"
            message="Massa vulputate id placerat vitae ornare a orci. Gravida augue viverra pretium adipiscing mauris."
            textButton="Crear nuevo banner"
            onCreate={() => navigate('/app/content-management/create-banner')}
          />
        </div>
      </div>
    </Fragment>
  )
}

export default ManageBanners
