import { POST_LOGIN_START, POST_LOGIN_SUCCESS, POST_LOGIN_ERROR, LOGOUT } from '../../types';

export default function login(state, action) {
  switch (action.type) {
    case POST_LOGIN_START:
      return { ...state };
    case POST_LOGIN_SUCCESS:
      return {
        ...state,
        isLogged: true,
        access: action.results.access,
        refresh: action.results.refresh,
        error: null,
      };
    case POST_LOGIN_ERROR:
      return {
        ...state,
        isLogged: false,
        error: action.detail
      };
    case LOGOUT:
      state = {}
      return {
        ...state,
        isLogged: false,
        access: null,
        refresh: null,
        error: null
      }
    default:
      return { ...state };
  }
}
