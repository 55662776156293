/** @jsxImportSource @emotion/react */
import React from "react";
import ReactApexChart from "react-apexcharts";
import * as styles from './index.styles'

const options = {
  chart: {
    toolbar: {
      show: false
    },
  },
  fill: {
    type: 'gradient',
    gradient: {
      type: 'vertical',
      shadeIntensity: 1,
      opacityFrom: 0.2,
      opacityTo: 0,
      stops: [0, 100],
      colorStops: [[
        {
          offset: 0,
          color: '#A8A8A8',
          opacity: 0.4,
        },
        {
          offset: 100,
          color: '#FFFFFF',
          opacity: 0
        }
      ]]
    },
  },
  stroke: {
    width: 2,
    curve: 'smooth' as 'smooth',
  },
  markers: {
    size: 4,
    showNullDataPoints: true,
  },
  xaxis: {
    categories: ['Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo']
  },
  yaxis: {
    min: 0,
    labels: {
      show: false,
    },
    lines: {
      show: false,
    }
  },
  grid: { show: false },
  dataLabels: {
    enabled: false,
  },
  colors: ['#04D99D']
}
const series = [
  {
    name: 'sales',
    data: [5, 10, 5, 13, 7, 11, 5]
  }
]


const TrafficGraph: React.FC = () => {

  return (
    <div id="chart" css={styles.chart}>
      <ReactApexChart options={options} series={series} type="area" height={285}/>
    </div>

  );
}

export default TrafficGraph;