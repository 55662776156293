import { createContext, FC, useState } from 'react'

export type Data = {
  id?: string
  category: string,
  image?: File
  active: boolean
}
export interface DataContext {
  formData: Data,
  handleChangeFormData: (data: Partial<Data>) => void,
  handleUpdateFormData: (data: Partial<Data>) => void,
}

const initialData: Data = {
  id: '',
  category: '',
  image: undefined,
  active: true,
}

export const Context = createContext<DataContext>({
  formData: initialData,
  handleChangeFormData: (data: Partial<Data>) => {},
  handleUpdateFormData: (data: Partial<Data>) => {},
})

const CardCategoryFormDataProvider: FC = (props) => {
  const [formData, setFormData] = useState<Data>(initialData)

  const handleChangeFormData = (data: Partial<Data>) =>
    setFormData({ ...formData, ...data })

  const handleUpdateFormData = (data: Partial<Data>) => {
    if (data && data?.id) {
      setFormData({
        ...formData,
        id: data.id,
        category: data.category || initialData.category,
        image: data.image || initialData.image,
        active: data.active || false
      })
    }
  }
  return (
    <Context.Provider value={{ formData, handleChangeFormData, handleUpdateFormData }}>
      {props.children}
    </Context.Provider>
  )
}

export default CardCategoryFormDataProvider
