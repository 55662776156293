import { css } from '@emotion/react';

export const root = css`
  display: flex;
  flex-direction: column;
  border: 1px solid #eaeaea;
  border-radius: 4px;
  padding: 10px;
  margin-top: 12px;
  & > div:first-child {
    margin-bottom: 32px;
  }
`