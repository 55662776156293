import { createContext, FC, useState } from 'react';
import { Option } from "../components/atoms/SelectInput/types"

export type Data = {
  id?: string,
  title: string,
  description: string,
  news: object,
  category?: Option,
  cycle: string,
  active: boolean,
}
export interface DataContext {
  formData: Data,
  handleChangeFormData: (data: Partial<Data>) => void,
  handleUpdateFormData: (data: Partial<Data>) => void,
}

export const Context = createContext<DataContext>({
  formData: {
    id: '',
    title: '',
    news: [],
    description: '',
    category: undefined,
    cycle: '',
    active: true,
  },
  handleChangeFormData: (data: Partial<Data>) => { },
  handleUpdateFormData: (data: Partial<Data>) => { },
})

const GroupNewFormDataProvider: FC = (props) => {
  const [formData, setFormData] = useState<Data>({
    id: '',
    title: '',
    news: [],
    description: '',
    category: undefined,
    cycle: '',
    active: true,
  })

  const handleChangeFormData = (data: Partial<Data>) => setFormData({ ...formData, ...data })

  const handleUpdateFormData = (data: Partial<Data>) => {
    if (data && data?.id) {
      // @ts-ignore
      setFormData({ ...formData, id: data.id, title: data.title, news: data.news, description: data.description, category: data.category, cycle: data.cycle, active: data.active })
    }
  }

  return (
    <Context.Provider value={{ formData, handleChangeFormData, handleUpdateFormData }}>
      {props.children}
    </Context.Provider>
  )
}

export default GroupNewFormDataProvider
