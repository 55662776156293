const palette = {
  primary: '#34189A',
  primary100: '#1A0C4D',
  primary90: '#200F60',
  primary80: '#271274',
  primary30: '#C4BBE2',
  primary20: '#D8D3EC',
  primary10: '#EDEAF6',
  secondary: '#868686',
  secondary90: '#000000',
  secondary80: '#363636',
  secondary70: '#6C6C6C',
  secondary30: '#BCBCBC',
  secondary20: '#D7D7D7',
  secondary10: '#FAFAFA',
  ornamental: '#FC2E08',
  success: '#04D99D',
  successLight: '#F4FFFC',
  danger: '#F26A1B',
  dangerLight: '#FFF3EC',
  warning: '#FFF349',
  info: '#3BD3EA',
  white: '#FFFFFF',
  complementary1: '#F2C4B8',
  complementary2: '#00489C',
  text: '#000000',
}

export default palette