import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { persistReducer, persistStore } from 'redux-persist';
// import storage from 'redux-persist/lib/storage';

import reducers from '../reducers';
import sagas from '../sagas';
// import localStorage from 'redux-persist/es/storage';
import localforage from 'localforage';

const sagaMiddleware = createSagaMiddleware();

const persistConfig = {
  key: 'root',
  storage: localforage,
  whitelist: [
    'login',
    'banners',
    'create_banner',
    'update_banner',
    'detail_banner',
    'delete_banner',
    'notifications',
    'create_notification',
    'update_notification',
    'delete_notification',
    'fcm_history',
    'fcm_send',
    'fcm_send_csv',
    'fcm_devices',
    'questions',
    'create_question',
    'update_question',
    'delete_question',
    'events',
    'events_cycle',
    'create_event',
    'detail_events',
    'update_events',
    'delete_events',
    'group_posts',
    'update_posts',
    'delete_posts',
    'update_group_posts',
    'delete_group_posts',
    'create_post',
    'create_group_posts',
    'group_detail_posts',
    'group_posts_cycle',
    'posts_detail',
    'magazines',
    'create_magazine',
    'update_magazine',
    'delete_magazine',
    'management',
    'create_management',
    'update_management',
    'delete_management',
    'get_one_management',
    'cycle',
    'create_group_cycle',
    'group_cycle',
    'update_group_cycle',
    'delete_group_cycle',
    'detail_new_cycle',
    'create_new_cycle',
    'update_new_cycle',
    'delete_new_cycle',
    'create_group_news',
    'create_news',
    'group_detail',
    'group_news',
    'news_detail',
    'update_group_news',
    'update_news',
    'delete_news',
    'delete_group_news',
    'nature_magazine',
    'create_nature_magazine',
    'update_nature_magazine',
    'delete_nature_magazine',
    'growth_path',
    'create_growth_path',
    'update_growth_path',
    'delete_growth_path',
    'offers',
    'create_offers',
    'update_offers',
    'delete_offers',
    'card_category',
    'create_card_category',
    'update_card_category',
    'delete_card_category',
    'cdc',
    'create_cdc',
    'userdata',
    'permissions',
    'register',
    'events_send_csv',
    'drawer',
    'create_drawer',
    'update_drawer',
    'delete_drawer',
    'remove_posts',
  ],
  timeout: 0,
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = createStore(
  persistedReducer,
  compose(
    applyMiddleware(sagaMiddleware),
    (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__()) ||
    compose,
  ),
);

sagaMiddleware.run(sagas);

const persistor = persistStore(store);

store.persistor = persistor;

export { store, persistor };
