/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import React, {FC, useContext, useEffect, useState} from 'react'
import { Context } from '../../../context/DrawerContext'
import {BaseInput, FileInput, SwitchInput, Text, TextareaInput} from '../../atoms'
import { ContentSection } from '../../organisms'
import {useParams} from "react-router-dom";
import {useSelector} from "react-redux";

const NewForm: FC = () => {
  const { formData, handleChangeFormData: handleChange, handleUpdateFormData: handleData } = useContext(Context)
  let { id } = useParams();
  const detail_data = useSelector((state: any) => state.drawer.data)
  const news_data = id ? detail_data?.find((r:any) => r.id === parseInt(id+'')) : null

  useEffect(() => {
    if (!!id) {
      handleData(news_data)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, news_data])

  const [isTitleValid, setTitleValid] = useState<boolean>(false)
  const [isUrlValid, setIsUrlValid] = useState<boolean>(false)

  useEffect(() => {
    setTitleValid(formData.title.length > 2)
  }, [formData.title])
  useEffect(() => {
    setIsUrlValid(formData.url.length > 10)
  }, [formData.url])

  return (
    <ContentSection
      style={css`display: grid; gap: 18px;`}
      header={
        <React.Fragment>
          <Text textType='h4' weight='black'>{id ? 'Editar' : 'Nuevo'} item</Text>
        </React.Fragment>
      }
    >
      <BaseInput
        label='Título'
        value={formData.title}
        onChange={(value) => handleChange({ title: value })}
        placeholder='Escribe el titulo de tu entrada'
        maxLength={500}
        error={!isTitleValid && formData.title.length > 0}
        success={isTitleValid}
        hint={!isTitleValid && formData.title.length > 0 ? 'Digite mas de dos letras' : ''}
      />
      <TextareaInput
        label='Nav'
        value={formData.nav}
        onChange={(value) => handleChange({ nav: value })}
        placeholder='Escribe la ruta de la app'
        maxLength={100}
      />
      <div style={{ width: 360 }}>
        <FileInput
          label={'Imagen'}
          value={formData.image}
          onChange={(value) => handleChange({ image: value })}
          accept={'image/*'}
        />
      </div>
      <SwitchInput checked={formData.web}
        onCheckedChange={(value) => handleChange({ web: value })}
        label={formData.web ? 'Es Web': 'No es Web'}
      />
      <BaseInput
        label='URL'
        value={formData.url}
        onChange={(value) => handleChange({ url: value })}
        placeholder='URL'
        maxLength={1500}
        error={!isUrlValid && formData.url.length > 0}
        success={isUrlValid}
        hint={!isUrlValid && formData.url.length > 0 ? 'Introduce una url Ej.:https://www.google.com/' : ''}
      />
      <SwitchInput
        checked={formData.active}
        onCheckedChange={(value) => handleChange({ active: value })}
        label={formData.active ? 'Activo': 'Inactivo'}
      />
    </ContentSection>
  )
}

export default NewForm
