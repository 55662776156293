import {takeLatest, put, call} from 'redux-saga/effects';
import {DETAIL_EVENTS_ERROR, DETAIL_EVENTS_START, DETAIL_EVENTS_SUCCESS} from "../../types";

import apiCall from '../../api/apiGet';

export function* getEvents({payload}) {
    try {
        const url = `/events/${payload.formData.id}/`;
        const method = 'GET';
        const headers = {Authorization: 'Bearer ' + payload.token};
        const results = yield call(apiCall, url, method, headers);
        yield put({type: DETAIL_EVENTS_SUCCESS, results: results.data});
    } catch (error) {
        yield put({type: DETAIL_EVENTS_ERROR, error});
    }
}

export default function* detail_events() {
    yield takeLatest(DETAIL_EVENTS_START, getEvents);
}
