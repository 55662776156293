import {takeLatest, put, call} from 'redux-saga/effects';
import apiCall from '../../api';
import {UPDATE_NEWS_ERROR, UPDATE_NEWS_START, UPDATE_NEWS_SUCCESS} from "../../types";

export function* updateNews({payload}) {
  try {
    const url = `/news/${payload.formData.id}/`;
    const method = 'PUT';
    const headers = {Authorization: 'Bearer ' + payload.token, 'Content-Type':'multipart/form-data'};

    const data = (object) => Object.keys(object).reduce((formData, key) => {
      formData.append(key, object[key]);
      return formData;
    }, new FormData());

    const results = yield call(apiCall, url, method, data(payload.formData), headers);
    yield put({type: UPDATE_NEWS_SUCCESS, results: results.data});
    yield put(payload.navigate('/app/content-management/manage-group-news'));
  } catch (error) {
    yield put({type: UPDATE_NEWS_ERROR, error});
  }
}

export default function* update_news() {
    yield takeLatest(UPDATE_NEWS_START, updateNews);
}