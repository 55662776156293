/** @jsxImportSource @emotion/react */
import React, { Fragment, useEffect } from 'react'
import { useNavigate } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { CreateSide, ManageTable } from '../../../components/templates'
import * as styles from '../index.styles'
import { columns } from './mockData'
import { breadcrumbItems } from '../../utils'
import { Breadcrumb } from '../../../components/atoms'
import { nature_magazine } from "../../../redux/actions/nature-magazine/nature_magazine";

const { index, contentManagement, manageMagazine } = breadcrumbItems

const ManageNatureMagazine: React.FC = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const token = useSelector((state: any) => state.login.access)
  const data_nat_magazine = useSelector((state: any) => state.nature_magazine.data)

  const cycleData = useSelector((state: any) => {
    return state.cycle.data.map((v:any) => {
      return { value: v.cycle, label: v.cycle }
    })
  })
  cycleData.push({ value: 'Ninguno', label: 'Ninguno'})

  useEffect(() => {
    dispatch(nature_magazine({ token }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Fragment>
      <Breadcrumb items={[index, contentManagement, manageMagazine]} />
      <div css={styles.root}>
        <div css={styles.main}>
          <ManageTable
            title="Administrar revista natura"
            subtitle="Revistas publicados"
            columns={columns}
            data={data_nat_magazine || []}
            cycle={cycleData || []}
            management={[]}
            stateCategory={true}
          />
        </div>
        <div css={styles.right}>
          <CreateSide
            title="Crear nueva revista natura"
            message="Massa vulputate id placerat vitae ornare a orci. Gravida augue viverra pretium adipiscing mauris."
            textButton="Crear nueva revista natura"
            onCreate={() => navigate('/app/content-management/create-nature-magazine')}
          />
        </div>
      </div>
    </Fragment>
  )
}

export default ManageNatureMagazine
