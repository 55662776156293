/** @jsxImportSource @emotion/react */
import React, { Fragment, useEffect } from 'react'
import moment from 'moment'
import jwt_decode from 'jwt-decode'
import { useDispatch, useSelector } from 'react-redux'
import { Breadcrumb } from '../../../components/atoms'
import { Traffic } from '../../../components/templates'
import * as styles from '../index.styles'
import { login } from '../../../redux/actions/auth/login'
import { fcm_devices } from '../../../redux/actions/fcm/fcm_devices'
import { management } from "../../../redux/actions/management/management";
import { cycle } from "../../../redux/actions/cycle/cycle";
import {fcm_history} from "../../../redux/actions/fcm/fcm_history";
import {group_posts_cycle} from "../../../redux/actions/post/group_posts_cycle";
import {events_cycle} from "../../../redux/actions/events/events_cycle";
import { events } from '../../../redux/actions/events/events'
import { userdata } from '../../../redux/actions/auth/userdata'
import { permissions } from '../../../redux/actions/auth/permissions'
import { group_posts } from '../../../redux/actions/post/group_posts'
import { drawer } from '../../../redux/actions/drawer/drawer'

interface MyToken {
  exp: number
}
const Dashboard: React.FC = () => {
  const dispatch = useDispatch()
  const token = useSelector((state: any) => state.login.access)

  useEffect(() => {
    if (token) {
      const decoded = jwt_decode<MyToken>(token)
      const now = moment().unix()
      const duration = moment.duration(
        moment.unix(now).diff(moment.unix(decoded.exp))
        )
      const getMinutes = duration.asMinutes()
      if (getMinutes > 1) {
        dispatch(login('LOGOUT'))
      }
    }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    dispatch(permissions({ token }));
    dispatch(fcm_devices({ token }));
    dispatch(management({token}));
    dispatch(cycle({token}));
    dispatch(group_posts_cycle({token}));
    dispatch(group_posts({ token }))
    dispatch(events_cycle({ token }));
    dispatch(events({ token }));
    dispatch(fcm_history({ token }));
    dispatch(userdata({ token }));
    dispatch(drawer({ token }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  return (
    <Fragment>
      <Breadcrumb
        items={[{ label: 'Inicio' }, { label: 'Dashboard', path: '/app' }]}
      />
      <div css={styles.root}>
        <div css={styles.main}>
          <Traffic />
          {/*<ExpandYourPlan />*/}
        </div>
        {/*<div css={styles.right}>
          <Resource />
        </div>*/}
      </div>
    </Fragment>
  )
}

export default Dashboard
