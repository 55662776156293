/** @jsxImportSource @emotion/react */
import React, {useState} from 'react'
import { useDispatch } from 'react-redux'
import {CloseIcon, MenuIcon, QuestionIcon} from '../../../assets'
import { Text } from '../../atoms'
import * as styles from './index.styles'
import {login} from "../../../redux/actions/auth/login";
import {useNavigate} from "react-router-dom";
import { Modal } from '../../../components/templates'
import navListRoutes from "../../../router/navListRoutes";
import {NavSection} from "../index";

const Notifications: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const [show, setShow] = useState(false)

  const handleLogout = () => {
    dispatch(login('LOGOUT'))
    navigate('/login');
    setShow(false)
  }

  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };
  const goToHome = () => {
    navigate('/');
  }

  return (
    <div css={styles.root}>
        <Modal
            show={show}
            title="¿Esta seguro que desea salir?"
            text=""
            btnSuccess="Si"
            btnWarning="No"
            onClickSuccess={() => handleLogout()}
            onClickWarning={() => {setShow(false)}}
            onClickClose={() => {setShow(false)}}
        />

      <div css={styles.iconsContainer}>
        <QuestionIcon width={24} height={24} />
        <MenuIcon width={24} height={24} css={styles.iconMenu}
                    onClick={handleClick}/>
      </div>
      <div css={styles.avatarContainer}>
        <img src="/user.png" alt="user" css={{ cursor: 'pointer' }} onClick={() => {setShow(true)}} />
        <div >
          <Text textType="p">Nat</Text>
          <Text textType="label">Administrador App</Text>
        </div>
      </div>
      <div css={open ? styles.openYes : styles.openNo}>
        <CloseIcon width={24} height={24} css={styles.iconClose}
                    onClick={handleClick}/>
        <img src='/logo.png' alt="logo" width={167} css={{ marginTop: '2rem', marginBottom: '46px', cursor: 'pointer' }} onClick={goToHome} />
            <aside>
                {
                    navListRoutes.map(routeSection => (
                        <NavSection key={`sidebarSection-${routeSection.title}`} routeSection={routeSection} />
                    ))
                }
            </aside>
        </div>

    </div>
  )
}

export default Notifications
