import { takeLatest, call, put } from 'redux-saga/effects';
import { POST_REGISTER_ERROR, POST_REGISTER_START, POST_REGISTER_SUCCESS } from '../../types';

import apiCall from '../../api';

export function* get({ payload }) {
  try {
    const url = '/register/';
    const method = 'POST';
    const headers = {Authorization: 'Bearer ' + payload.token};
    const data = {
      username: payload.formData.username,
      last_name: payload.formData.last_name,
      first_name: payload.formData.first_name,
      password: payload.formData.password,
      is_staff: payload.formData.is_staff,
      is_active: payload.formData.is_active,
      user_permissions: payload.formData.user_permissions,
    };
    const results = yield call(apiCall, url, method, data, headers);
    yield put({ type: POST_REGISTER_SUCCESS, results: results.data });
    yield put(payload.navigate('/app/users/list'));
  } catch (error) {
    yield put({ type: POST_REGISTER_ERROR, error });
  }
}

export default function* register() {
  yield takeLatest(POST_REGISTER_START, get);
}
