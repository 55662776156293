import { createContext, FC, useState } from 'react';

export type Data = {
    title: string,
    description: string,
    image?: File,
    group: number,
    active: boolean
}
export interface DataContext {
    formData: Data,
    handleChangeFormData: (data: Partial<Data>) => void,
}

export const Context = createContext<DataContext>({
    formData: {
        title: '',
        description: '',
        image: undefined,
        group: 0,
        active: true,
    },
    handleChangeFormData: (data: Partial<Data>) => { },
})

const NewFormDataProvider: FC = (props) => {
    const [formData, setFormData] = useState<Data>({
        title: '',
        description: '',
        image: undefined,
        group: 0,
        active: true
    })

    const handleChangeFormData = (data: Partial<Data>) => setFormData({ ...formData, ...data })

    return (
        <Context.Provider value={{ formData, handleChangeFormData }}>
            {props.children}
        </Context.Provider>
    )
}

export default NewFormDataProvider
