/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import React, {FC, useContext, useEffect, useState} from 'react'
import { Context } from '../../../context/NotificationFCMContext'
import {BaseInput, FileInput, Text, TextareaInput} from '../../atoms'
import { ContentSection } from '../../organisms'
import {useSelector} from "react-redux";
import {ManageTable} from "../index";
import {columns} from "./mockData";

const FCMForm: FC = () => {
  const { formData, handleChangeFormData: handleChange } = useContext(Context)
  const FCM_data = useSelector((state: any) => state.fcm_devices.data)

  const cycleData = useSelector((state: any) => {
    return state.cycle.data.map((v:any) => {
      return { value: v.cycle, label: v.cycle }
    })
  })
  const managementData = useSelector((state: any) => {
    return state.management.data.map((v:any) => {
      return { value: v.id, label: v.management }
    })
  })

  useEffect(() => {
    return () => {
      handleChange({ registration_id: [] })
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (formData.registration_id.length === 0) {
    let ids_temp: any[] = []
    FCM_data.forEach((e: any) => {
      if (e.registration_id) {
        ids_temp.push(e.registration_id)
      }
    })
    handleChange({ registration_id: ids_temp })
  }

  useEffect(() => {
    console.log(formData.registration_id)
  }, [formData]);

  const [isTitleValid, setTitleValid] = useState<boolean>(false)
  const [isBodyValid, setIsBodyValid] = useState<boolean>(false)

  useEffect(() => {
    setTitleValid(formData.title.length > 2)
  }, [formData.title])
  useEffect(() => {
    setIsBodyValid(formData.body.length > 2)
  }, [formData.body])

  return (
    <ContentSection
      style={css`display: grid; gap: 18px;`}
      header={
        <React.Fragment>
          <Text textType='h4' weight='black'>Nueva notificación FCM</Text>
        </React.Fragment>
      }
    >
      <BaseInput
        label='Título'
        value={formData.title}
        onChange={(value) => handleChange({title: value})}
        placeholder='Escribe el titulo de tu entrada'
        maxLength={500}
        error={!isTitleValid && formData.title.length > 0}
        success={isTitleValid}
        hint={!isTitleValid && formData.title.length > 0 ? 'Digite mas de dos letras' : ''}
      />
      <TextareaInput
        label='Mensaje'
        value={formData.body}
        onChange={(value) => handleChange({ body: value })}
        placeholder='Escribe el mensaje de tu entrada'
        maxLength={1500}
        error={!isBodyValid && formData.body.length > 0}
        success={isBodyValid}
        hint={!isBodyValid && formData.body.length > 0 ? 'Digite mas de dos letras' : ''}
      />
      <div style={{ width: 360 }}>
        <FileInput
          label='Imagen'
          value={formData.image}
          onChange={(value) => handleChange({ image: value })}
          accept='image/*'
        />
      </div>

      <ManageTable
          title=""
          subtitle=""
          columns={columns}
          data={FCM_data || []}
          cycle={cycleData || []}
          management={managementData || []}
          stateCategory={true}
          handleChange={handleChange}
      />
    </ContentSection>
  )
}

export default FCMForm
