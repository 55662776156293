import { createContext, FC, useState } from 'react'

export type Data = {
    id: string
    management: string
    region: string
    sector: string
    active: boolean
}

export interface DataContext {
    formData: Data,
    handleChangeFormData: (data: Partial<Data>) => void
    handleUpdateFormData: (data: Partial<Data>) => void
}

const initialData: Data = {
    id: '',
    management: '',
    region: '',
    sector: '',
    active: true,
}

export const Context = createContext<DataContext>({
    formData: initialData,
    handleChangeFormData: (data: Partial<Data>) => {},
    handleUpdateFormData: (data: Partial<Data>) => {},
})

const NewFormDataProvider: FC = (props) => {
    const [formData, setFormData] = useState<Data>(initialData)

    const handleChangeFormData = (data: Partial<Data>) => {
        setFormData({ ...formData, ...data })
    }
    const handleUpdateFormData = (data: Partial<Data>) => {
      if (data && data?.id) {
        setFormData({
            ...formData,
            id: data.id,
            management: data.management || initialData.management,
            region: data.region || initialData.region,
            sector: data.sector || initialData.sector,
            active: data.active || false
        })
      }
    }

    return (
        <Context.Provider value={{ formData, handleChangeFormData, handleUpdateFormData }}>
            {props.children}
        </Context.Provider>
    )
}

export default NewFormDataProvider