export const columns = [
  {
    Header: 'Correo electronico',
    accessor: 'username',
  },
  {
    Header: 'Ultimo login',
    accessor: 'last_login',
  },
  {
    Header: 'Editar',
    accessor: 'editUser',
  },
  {
    Header: 'Eliminar',
    accessor: 'deleteUser',
  },
]

export const data = [
  {
    title: 'Titulo 1',
    content: 'Contenido 1',
    image: 'https://via.placeholder.com/150',
    link: 'https://via.placeholder.com/150',
  },
  {
    title: 'Titulo 2',
    content: 'Contenido 2',
    image: 'https://via.placeholder.com/150',
    link: 'https://via.placeholder.com/150',
  },
  {
    title: 'Titulo 3',
    content: 'Contenido 3',
    image: 'https://via.placeholder.com/150',
    link: 'https://via.placeholder.com/150',
  },
]
