import {DETAIL_EVENTS_ERROR, DETAIL_EVENTS_START, DETAIL_EVENTS_SUCCESS} from "../../types";

export default function detail_events(state, action) {
    switch (action.type) {
        case DETAIL_EVENTS_START:
            return {
                ...state,
                data: [],
                loading: true,
                error: null
            };
        case DETAIL_EVENTS_SUCCESS:
            return {
                ...state,
                data: action.results,
                loading: false,
                error: null
            };
        case DETAIL_EVENTS_ERROR:
            return {
                ...state,
                data: [],
                loading: false,
                error: action.detail
            };
        default:
            return { ...state };
    }
}
