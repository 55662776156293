import { css } from '@emotion/react';
export const table = css`
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
  border-radius: 25px;
  border: 1px solid #BCBCBC;
  border-radius: 5px;
  margin-bottom: 10px;
  margin-top: 10px;
  overflow: hidden;
`
export const tableHeader = css`
`

export const tableBody = css`

`

export const tableHeaderRow = css`
`

export const tableBodyRow = css`
`

export const tableHeaderCell = css`
  background-color: #FAFAFA;
  border-bottom: 1px solid #BCBCBC;
  padding: 20px 10px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: 12px;
  text-align: left;
  line-height: 14px;
`

export const tableBodyCell = css`
  padding: 20px 10px;
  text-align: left;
  font-size: 12px;
  line-height: 14px;
`

export const actions = css`
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;

`

export const actionsRigth = css`
  margin-top: 10px;
  display: flex;
  justify-content: end;
  align-items: center;
  .button {
    margin-left: 10px;
  }
`

export const left = css`
  display: flex;
  & button {
    width: 86px;
  }
  margin-right: 10px;
`

export const filter = css`
  width: 168px;
  margin-right: 10px;
`

export const search = css`
  max-width: 350px;
  self-justify: flex-end;
`

export const sizeX = css`
  width: 50px;
`
export const icon = css`
  cursor: pointer;
  margin: 0 auto;
`
