import { css } from '@emotion/react'
import { StylesConfig } from 'react-select'
import { ThemeUI } from '../../../theme'
import { Option, StyleProps } from './types'

type Props = { theme: ThemeUI } & StyleProps

export const rootContainer = css`
  display: flex;
  flex-direction: column;
`

type StyleSelectProps = (props: Props) => StylesConfig<Option>
export const select: StyleSelectProps = ({ theme, success, error }) => {
  const getColor = (option: Option | undefined) => {
    const value = option?.value
    return value === 'published'
      ? theme.palette.success : value === 'disabled'
        ? theme.palette.danger : value === 'review'
          ? theme.palette.warning : theme.palette.secondary70
  }
  const getBackground = (option: Option | undefined) => {
    const value = option?.value
    return value === 'published'
      ? '#F4FFFC' : value === 'disabled'
        ? '#FFF3EC' : value === 'review'
          ? '#FFFDE3' : theme.palette.white
  }
  return {
    container: (base) => ({
      ...base,
      width: '74px',
    }),
    control: (base, state) => ({
      ...base,
      fontFamily: "Lato, 'sans-serif'",
      fontSize: 9,
      height: 22,
      minHeight: 20,
      borderColor: getColor(state.getValue()[0]),
      color: getColor(state.getValue()[0]),
      backgroundColor: getBackground(state.getValue()[0]),
      '&:hover': {
        borderColor: getColor(state.getValue()[0]),
      },
      boxShadow: 'none',
    }
    ),
    valueContainer: (base, state) => ({
      ...base,
      borderRadius: 3,
      padding: '0',
      paddingLeft: '3px',
      backgroundColor: getBackground(state.getValue()[0]),
    }),
    indicatorSeparator: () => ({
      display: 'none'
    }),
    indicatorsContainer: (base, state) => ({
      ...base,
      '& > div': {
        padding: '0',
        paddingRight: '3px',
      },
      '& svg': {
        color: getColor(state.getValue()[0]),
        height: '10px',
        width: '10px',
      }
    }),
    placeholder: (base) => ({
      ...base,
      whiteSpace: 'nowrap',
    }),
    option: (base) => ({
      ...base,
      fontSize: 9,
    }),
    input: (base, state) => ({
      ...base,
      margin: '0px',
    }),
    singleValue: (base, state) => ({
      ...base,
      color: getColor(state.getValue()[0]),
    })
  }
}