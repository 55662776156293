/** @jsxImportSource @emotion/react */
import React, { Fragment, useEffect } from 'react'
import { useNavigate } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { CreateSide, ManageTable } from '../../../components/templates'
import * as styles from '../index.styles'
import { columns } from './mockData'
import { breadcrumbItems } from '../../utils'
import { Breadcrumb } from '../../../components/atoms'
import {useLocation} from "react-router-dom";
import { create_drawer } from '../../../redux/actions/drawer/create_drawer'
import { drawer } from '../../../redux/actions/drawer/drawer'

const { index, contentManagement, manageDrawer } = breadcrumbItems

const ManageBanners: React.FC = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation();
  const token = useSelector((state: any) => state.login.access)
  const data_banners = useSelector((state: any) => state.drawer.data)

  useEffect(() => {
    if (location.pathname === '/app/content-management/manage-drawer') {
      dispatch(drawer({ token }));
      setTimeout(() => {
        dispatch(create_drawer({ token }));
      }, 1500)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <Fragment>
      <Breadcrumb items={[index, contentManagement, manageDrawer]} />
      <div css={styles.root}>
        <div css={styles.main}>
          <ManageTable
            title="Administrar items"
            subtitle="Items publicados"
            columns={columns}
            data={data_banners || []}
            cycle={[]}
            management={[]}
            stateCategory={true}
          />
        </div>
        <div css={styles.right}>
          <CreateSide
            title="Crear nuevo item"
            message="Massa vulputate id placerat vitae ornare a orci. Gravida augue viverra pretium adipiscing mauris."
            textButton="Crear nuevo item"
            onCreate={() => navigate('/app/content-management/create-drawer')}
          />
        </div>
      </div>
    </Fragment>
  )
}

export default ManageBanners
