import {takeLatest, put, call} from 'redux-saga/effects';
import {DETAIL_BANNER_ERROR, DETAIL_BANNER_START, DETAIL_BANNER_SUCCESS} from "../../types";

import apiCall from '../../api/apiGet';

export function* getBanners({payload}) {
    try {
        const url = `/banners/${payload.formData.id}/`;
        const method = 'GET';
        const headers = {Authorization: 'Bearer ' + payload.token};
        const results = yield call(apiCall, url, method, headers);
        yield put({type: DETAIL_BANNER_SUCCESS, results: results.data});
    } catch (error) {
        yield put({type: DETAIL_BANNER_ERROR, error});
    }
}

export default function* detail_banner() {
    yield takeLatest(DETAIL_BANNER_START, getBanners);
}
