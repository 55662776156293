import { CREATE_POSTS_ERROR, CREATE_POSTS_START, CREATE_POSTS_SUCCESS } from "../../types";

export default function create_post(state, action) {
  switch (action.type) {
    case CREATE_POSTS_START:
      return {
        ...state,
        loading: true,
        error: null
      };
    case CREATE_POSTS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null
      };
    case CREATE_POSTS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.detail
      };
    default:
      return { ...state };
  }
}
