import {DELETE_NATURE_MAGAZINE_ERROR, DELETE_NATURE_MAGAZINE_START, DELETE_NATURE_MAGAZINE_SUCCESS} from "../../types";

export default function delete_nature_magazine(state, action) {
    switch (action.type) {
        case DELETE_NATURE_MAGAZINE_START:
            return {
                ...state,
                loading: true,
                error: null
            };
        case DELETE_NATURE_MAGAZINE_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null
            };
        case DELETE_NATURE_MAGAZINE_ERROR:
            return {
                ...state,
                loading: false,
                error: action.detail
            };
        default:
            return {...state};
    }
}
