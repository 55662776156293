import { combineReducers } from 'redux';
import { REHYDRATE } from 'redux-persist/lib/constants';

import { LOGOUT } from '../types';

import login from './auth/login';
import banners from './banner/banners';
import create_banner from './banner/create_banner';
import update_banner from './banner/update_banner';
import detail_banner from './banner/detail_banner';
import delete_banner from './banner/delete_banner';
import notifications from './notification/notifications';
import create_notification from './notification/create_notification';
import update_notification from './notification/update_notification';
import delete_notification from './notification/delete_notification';
import fcm_history from './fcm/fcm_history';
import fcm_send from "./fcm/fcm_send";
import fcm_send_csv from "./fcm/fcm_send_csv";
import fcm_devices from "./fcm/fcm_devices";
import questions from "./questions/questions";
import create_question from "./questions/create_question";
import update_questions from "./questions/update_questions";
import delete_questions from "./questions/delete_questions";
import events from "./events/events";
import events_cycle from "./events/events_cycle";
import create_event from './events/create_event'
import detail_events from './events/detail_events'
import update_events from './events/update_events'
import delete_events from './events/delete_events'
import group_posts from './post/group_posts';
import create_post from './post/create_post';
import create_group_posts from './post/create_group_posts';
import group_posts_cycle from './post/group_posts_cycle';
import group_detail_posts from './post/group_detail_posts';
import posts_detail from './post/posts_detail';
import update_posts from './post/update_posts';
import update_group_posts from './post/update_group_posts';
import delete_posts from './post/delete_posts';
import delete_group_posts from './post/delete_group_posts';
import magazines from './magazine/magazines';
import create_magazine from './magazine/create_magazine';
import update_magazine from './magazine/update_magazine';
import delete_magazine from './magazine/delete_magazine';
import management from './management/management';
import create_management from './management/create_management';
import update_management from './management/update_management';
import delete_management from './management/delete_management';
import get_one_management from "./management/get_one_management";
import cycle from './cycle/cycle';
import group_news from "./news/group_news";
import create_group_news from "./news/create_group_news";
import create_news from "./news/create_news";
import group_detail from './news/group_detail';
import news_detail from './news/news_detail';
import update_group_news from './news/update_group_news';
import update_news from './news/update_news';
import delete_group_news from "./news/delete_group_news";
import delete_news from "./news/delete_news";
import nature_magazine from './nature-magazine/nature_magazine';
import create_nature_magazine from './nature-magazine/create_nature_magazine';
import update_nature_magazine from './nature-magazine/update_nature_magazine';
import delete_nature_magazine from './nature-magazine/delete_nature_magazine';
import group_cycle from "./group_cycle/group_cycle";
import create_group_cycle from "./group_cycle/create_group_cycle";
import update_group_cycle from "./group_cycle/update_group_cycle";
import offers from "./offers_by_cycle/offers";
import create_offers from "./offers_by_cycle/create_offers";
import update_offers from "./offers_by_cycle/update_offers";
import delete_offers from "./offers_by_cycle/delete_offers";
import delete_group_cycle from "./group_cycle/delete_group_cycle";
import create_new_cycle from "./group_cycle/create_new_cycle";
import update_new_cycle from "./group_cycle/update_new_cycle";
import delete_new_cycle from "./group_cycle/delete_new_cycle";
import card_category from "./card_category/card_category";
import create_card_category from "./card_category/create_card_category";
import update_card_category from "./card_category/update_card_category";
import delete_card_category from "./card_category/delete_card_category";
import detail_new_cycle from "./group_cycle/detail_new_cycle";
import growth_path from "./growth-path/growth_path";
import create_growth_path from "./growth-path/create_growth_path";
import update_growth_path from "./growth-path/update_growth_path";
import delete_growth_path from "./growth-path/delete_growth_path";
import userdata from "./auth/userdata";
import permissions from "./auth/permissions";
import register from "./auth/register";
import events_send_csv from "./events/events_send_csv";
import drawer from "./drawer/drawer";
import create_drawer from "./drawer/create_drawer";
import update_drawer from "./drawer/update_drawer";
import delete_drawer from "./drawer/delete_drawer";
import remove_posts from './remove_posts';

const appReducer = combineReducers({
  login,
  banners,
  create_banner,
  update_banner,
  detail_banner,
  delete_banner,
  notifications,
  create_notification,
  update_notification,
  delete_notification,
  fcm_history,
  fcm_send,
  fcm_send_csv,
  fcm_devices,
  questions,
  create_question,
  update_questions,
  delete_questions,
  events,
  events_cycle,
  create_event,
  detail_events,
  update_events,
  delete_events,
  group_posts,
  create_post,
  create_group_posts,
  group_posts_cycle,
  group_detail_posts,
  posts_detail,
  update_posts,
  delete_posts,
  update_group_posts,
  delete_group_posts,
  magazines,
  create_magazine,
  update_magazine,
  delete_magazine,
  management,
  create_management,
  update_management,
  delete_management,
  get_one_management,
  cycle,
  group_news,
  create_group_news,
  create_news,
  group_detail,
  update_group_news,
  update_news,
  news_detail,
  delete_group_news,
  delete_news,
  nature_magazine,
  create_nature_magazine,
  update_nature_magazine,
  delete_nature_magazine,
  group_cycle,
  create_group_cycle,
  update_group_cycle,
  offers,
  create_offers,
  update_offers,
  delete_offers,
  delete_group_cycle,
  detail_new_cycle,
  create_new_cycle,
  update_new_cycle,
  delete_new_cycle,
  card_category,
  create_card_category,
  update_card_category,
  delete_card_category,
  growth_path,
  create_growth_path,
  update_growth_path,
  delete_growth_path,
  userdata,
  permissions,
  register,
  events_send_csv,
  drawer,
  create_drawer,
  update_drawer,
  delete_drawer,
  remove_posts,
});

const rootReducer = (state, action) => {
  if (action.type === REHYDRATE) {
    return { ...state, persistedState: action.payload };
  }
  if (action.type === LOGOUT) {
    state = undefined;
    localStorage.removeItem('persist:root');
    localStorage.clear();
  } else {
    state = { ...state }
  }

  return appReducer(state, action);
};

export default rootReducer;
