export const columns = [
  {
    Header: 'Titulo',
    accessor: 'title',
  },
  {
    Header: 'Descripción',
    accessor: 'description',
  },
  {
    Header: 'Nivel',
    accessor: 'category',
  },
  {
    Header: 'Estado',
    accessor: 'active',
  },
  {
    Header: 'Agregar Noticia',
    accessor: 'id',
  },
  {
    Header: 'Ver grupo',
    accessor: 'watchNews',
  },
]

export const columnsUsers = [
  {
    Header: 'Nombres',
    accessor: 'name',
  },
  {
    Header: 'Tipo de dispositivo',
    accessor: 'type',
  },
  {
    Header: 'Estado',
    accessor: 'active',
  },
]


export const data = [
  {
    title: 'Amet, nibh amet, ullamcorper sed posuere adipiscing maur...',
    publishedAt: '2020-01-01',
    state: 'Publicado',
  },
  {
    title: 'Amet, nibh amet, ullamcorper sed posuere adipiscing maur...',
    publishedAt: '2020-01-01',
    state: 'Publicado',
  },
  {
    title: 'Amet, nibh amet, ullamcorper sed posuere adipiscing maur...',
    publishedAt: '2020-01-01',
    state: 'Publicado',
  },
  {
    title: 'Amet, nibh amet, ullamcorper sed posuere adipiscing maur...',
    publishedAt: '2020-01-01',
    state: 'Publicado',
  },
  {
    title: 'Amet, nibh amet, ullamcorper sed posuere adipiscing maur...',
    publishedAt: '2020-01-01',
    state: 'Publicado',
  },
  {
    title: 'Amet, nibh amet, ullamcorper sed posuere adipiscing maur...',
    publishedAt: '2020-01-01',
    state: 'Publicado',
  },
  {
    title: 'Amet, nibh amet, ullamcorper sed posuere adipiscing maur...',
    publishedAt: '2020-01-01',
    state: 'Publicado',
  },
  {
    title: 'Amet, nibh amet, ullamcorper sed posuere adipiscing maur...',
    publishedAt: '2020-01-01',
    state: 'Publicado',
  },
  {
    title: 'Amet, nibh amet, ullamcorper sed posuere adipiscing maur...',
    publishedAt: '2020-01-01',
    state: 'Publicado',
  },
  {
    title: 'Amet, nibh amet, ullamcorper sed posuere adipiscing maur...',
    publishedAt: '2020-01-01',
    state: 'Publicado',
  },
  {
    title: 'Amet, nibh amet, ullamcorper sed posuere adipiscing maur...',
    publishedAt: '2020-01-01',
    state: 'Publicado',
  },
  {
    title: 'Amet, nibh amet, ullamcorper sed posuere adipiscing maur...',
    publishedAt: '2020-01-01',
    state: 'Publicado',
  },
  {
    title: 'Amet, nibh amet, ullamcorper sed posuere adipiscing maur...',
    publishedAt: '2020-01-01',
    state: 'Publicado',
  },
  {
    title: 'Amet, nibh amet, ullamcorper sed posuere adipiscing maur...',
    publishedAt: '2020-01-01',
    state: 'Publicado',
  },
  {
    title: 'Amet, nibh amet, ullamcorper sed posuere adipiscing maur...',
    publishedAt: '2020-01-01',
    state: 'Publicado',
  },
  {
    title: 'Amet, nibh amet, ullamcorper sed posuere adipiscing maur...',
    publishedAt: '2020-01-01',
    state: 'Publicado',
  },
]
