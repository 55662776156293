import { css } from '@emotion/react';

export const chart = css`
  overflowY: scroll;
  & [type='area'] {
    max-width: 100% !important;
    width: 100% !important;
    & > .apexcharts-canvas{
    }
  }
`