import {takeLatest, put, call} from 'redux-saga/effects';
import {DETAIL_NEW_CYCLE_ERROR, DETAIL_NEW_CYCLE_START, DETAIL_NEW_CYCLE_SUCCESS} from "../../types";

import apiCall from '../../api/apiGet';

export function* getNCycle({payload}) {
    try {
        const url = `/news_cycle/${payload.id}/`;
        const method = 'GET';
        const headers = {Authorization: 'Bearer ' + payload.token};
        const results = yield call(apiCall, url, method, headers);
        yield put({type: DETAIL_NEW_CYCLE_SUCCESS, results: results.data});
    } catch (error) {
        yield put({type: DETAIL_NEW_CYCLE_ERROR, error});
    }
}

export default function* detail_new_cycle() {
    yield takeLatest(DETAIL_NEW_CYCLE_START, getNCycle);
}
