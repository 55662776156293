import {takeLatest, put, call} from 'redux-saga/effects';
import apiCall from '../../api';
import {CREATE_MANAGEMENT_ERROR, CREATE_MANAGEMENT_START, CREATE_MANAGEMENT_SUCCESS} from "../../types";

export function* create({payload}) {
    try {
        const url = '/management/';
        const method = 'POST';
        const headers = {Authorization: 'Bearer ' + payload.token, 'Content-Type':'multipart/form-data'};
        const data = (object) => Object.keys(object).reduce((formData, key) => {
            formData.append(key, object[key]);
            return formData;
        }, new FormData());
        const results = yield call(apiCall, url, method, data(payload.formData), headers);
        yield put({type: CREATE_MANAGEMENT_SUCCESS, results: results.data});
        yield put(payload.navigate('/app/management-data'));
    } catch (error) {
        yield put({type: CREATE_MANAGEMENT_ERROR, error});
    }
}

export default function* create_management() {
    yield takeLatest(CREATE_MANAGEMENT_START, create);
}
