/** @jsxImportSource @emotion/react */
import React, { Fragment, useMemo, useState } from 'react'
import {
  Column,
  TableInstance,
  useAsyncDebounce,
  useGlobalFilter,
  useSortBy,
  useTable,
} from 'react-table'
import {BaseInput, Text} from '../../atoms'
import * as styles from './index.styles'
import {Link, useNavigate} from "react-router-dom";
import {EyeIcon} from "../../../assets";

export type Props = {
  columns: Column<any>[]
  data: any[]
}

const FilterInput = ({
  globalFilter,
  setGlobalFilter,
}: Pick<TableInstance, 'globalFilter' | 'setGlobalFilter'>) => {
  const [filter, setFilter] = useState<string>(globalFilter)
  const onFilterChange = useAsyncDebounce(
    (value) => setGlobalFilter(value || undefined),
    200
  )

  const handleChange = (value: string) => {
    setFilter(value)
    onFilterChange(value)
  }
  return (
    <BaseInput
      style={styles.search}
      name="search"
      value={filter}
      placeholder="Buscar"
      onChange={handleChange}
    />
  )
}

const TableBase: React.FC<Props> = ({
  columns: columnsTable,
  data: dataTable,
}) => {
  const navigate = useNavigate();
  const columns = useMemo((): Column<any>[] => columnsTable, [columnsTable])
  const data = useMemo((): any[] => dataTable, [dataTable])
  const tabla = useTable({ columns, data }, useGlobalFilter, useSortBy)
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setGlobalFilter,
    state: { globalFilter },
  } = tabla

  const handleDirectGN = (row: any) => {
    navigate(`/app/content-management/watch-group-news/${row.original.id}`)
  }

  return (
    <Fragment>
      <div css={styles.actions}>
        <FilterInput
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
        />
      </div>
      <table {...getTableProps()} css={styles.table}>
        <thead css={styles.tableHeader}>
          {headerGroups.map((headerGroup, index) => (
            <tr
              {...headerGroup.getHeaderGroupProps()}
              css={styles.tableHeaderRow}
              key={`tr-${index}`}
            >
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  css={styles.tableHeaderCell}
                  key={`th-${column.render('Header')}`}
                >
                  {column.render('Header')}
                  {column.isSorted ? (
                    column.isSortedDesc ? (
                      <span> &darr;</span>
                    ) : (
                      <span> &uarr;</span>
                    )
                  ) : (
                    ''
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()} css={styles.tableBody}>
          {rows.map((row) => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()} css={styles.tableBodyRow}>
                {row.cells.map((cell) => {
                  if (cell.column.id === 'id') {
                    return (
                        <td {...cell.getCellProps()} css={styles.tableBodyCell}>
                          <Link to={`/app/content-management/create-new/${cell.value}`}> Agregar noticia</Link>
                        </td>
                    )
                  } else {
                    return (
                        <td {...cell.getCellProps()} css={styles.tableBodyCell}>
                          {cell.render('Cell')}
                          {cell.column.id === 'active' ? cell.value === true ? 'Activo' : 'Inactivo' : null}
                          {cell.column.id === 'watchNews' ? <EyeIcon width={24} height={24} css={styles.icon} onClick={() => handleDirectGN(row)} /> : null}
                        </td>
                    )
                  }

                })}
              </tr>
            )
          })}
        </tbody>
        <Text textType="sm">{rows.length===0?'No hay datos en la tabla.':''}</Text>
      </table>
    </Fragment>
  )
}
//                       {cell.column.id === 'action' ? <Button onClick={() => navigate('/app/content-management/create-group-new', {id: 1})}>Agregar noticia</Button> : null}
export default TableBase
