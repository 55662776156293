/** @jsxImportSource @emotion/react */
import React, { Fragment, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { CreateSide, ManageTable } from '../../../components/templates'
import * as styles from '../index.styles'
import { columns } from './mockData'
import { breadcrumbItems } from '../../utils'
import { Breadcrumb } from '../../../components/atoms'
import { useDispatch, useSelector } from 'react-redux'
import { notifications } from '../../../redux/actions/notification/notifications'

const { index, contentManagement, manageNotifications } = breadcrumbItems

const ManagePage: React.FC = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const token = useSelector((state: any) => state.login.access)
  const data_notifications = useSelector((state: any) => state.notifications.data)
  const location = useLocation();

  useEffect(() => {
    dispatch(notifications({ token }))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const cycleData = useSelector((state: any) => {
    return state.cycle.data.map((v:any) => {
      return { value: v.cycle, label: v.cycle }
    })
  })
  cycleData.push({ value: 'Ninguno', label: 'Ninguno'})

  const managementData = useSelector((state: any) => {
    return state.management.data.map((v:any) => {
      return { value: v.id, label: v.management }
    })
  })
  managementData.push({ value: 'Ninguno', label: 'Ninguno'})

  useEffect(() => {
    // dispatch(notifications({ token }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Fragment>
      <Breadcrumb items={[index, contentManagement, manageNotifications]} />
      <div css={styles.root}>
        <div css={styles.main}>
          <ManageTable
            title="Administrar notificaciones"
            subtitle="Notificaciones publicadas"
            columns={columns}
            data={data_notifications || []}
            cycle={cycleData || []}
            management={managementData || []}
            stateCategory={true}
          />
        </div>
        <div css={styles.right}>
          <CreateSide
            title="Crear nueva notificación"
            message="Massa vulputate id placerat vitae ornare a orci. Gravida augue viverra pretium adipiscing mauris."
            textButton="Crear nueva notificación"
            onCreate={() =>
              navigate('/app/content-management/create-notification')
            }
          />
        </div>
      </div>
    </Fragment>
  )
}

export default ManagePage
