/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react'
import React, {useContext} from 'react'
import { ThemeUI } from '../../../theme'
import { Button, Card, Text } from '../../atoms'
import { ContentSection } from '../../organisms'
import * as styles from './index.styles'
import {Context} from "../../../context/NotificationFCMContext";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {fcm_send} from "../../../redux/actions/fcm/fcm_send";

const FCMPreview: React.FC = () => {
  const theme = useTheme() as ThemeUI
  const navigate = useNavigate();
  const { formData } = useContext(Context)
  const dispatch = useDispatch()
  const token = useSelector((state: any) => state.login.access)
  const handleNotification = (token: any, formData: any) => {
    dispatch(fcm_send({ token, formData, navigate}))
  }

  return (
    <ContentSection
      style={styles.root}
      header={
        <Text textType='p' weight='bold'>Vista Previa</Text>
      }
    >
      <div css={(theme: any) => styles.preview(theme)}>
        <Card style={css({ overflow: 'hidden' })}>
          <Text textType='sm' style={styles.previewTitle(theme)}>
            {formData.title}
          </Text>
          <div css={styles.previewDescription(theme)}>
            <Text textType='sm' weight='light'>
              {formData.body}
            </Text>
          </div>
          <img
            src={formData.image ? URL.createObjectURL(formData.image) : 'https://via.placeholder.com/300x200'}
            alt='placeholder'
            css={styles.previewImage}
          />
        </Card>
      </div>
      <Button onClick={() => handleNotification(token, formData)} disabled={!formData.title || !formData.body || !formData.image}>
        Enviar notificación
      </Button>
    </ContentSection>
  )
}


export default FCMPreview
