/** @jsxImportSource @emotion/react */
import React, { Fragment, useEffect } from 'react'
import { useNavigate } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { CreateSide, ManageTable } from '../../../components/templates'
import * as styles from '../index.styles'
import { columns } from './mockData'
import { breadcrumbItems } from '../../utils'
import { Breadcrumb } from '../../../components/atoms'
import { questions } from '../../../redux/actions/questions/questions'

const { index, contentManagement, manageQuestions } = breadcrumbItems

const ManageQuestions: React.FC = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const token = useSelector((state: any) => state.login.access)
  const data_questions = useSelector((state: any) => state.questions.data)

  useEffect(() => {
    dispatch(questions({ token }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Fragment>
      <Breadcrumb items={[index, contentManagement, manageQuestions]} />
      <div css={styles.root}>
        <div css={styles.main}>
          <ManageTable
            title="Administrar Preguntas Frecuentes"
            subtitle="Preguntas frecuentes publicadas"
            columns={columns}
            data={data_questions || []}
            cycle={[]}
            management={[]}
            stateCategory={false}
          />
        </div>
        <div css={styles.right}>
          <CreateSide
            title="Crear nueva pregunta"
            message="Massa vulputate id placerat vitae ornare a orci. Gravida augue viverra pretium adipiscing mauris."
            textButton="Crear pregunta"
            onCreate={() =>
              navigate('/app/content-management/create-questions')
            }
          />
        </div>
      </div>
    </Fragment>
  )
}

export default ManageQuestions
