import { POST_REGISTER_ERROR, POST_REGISTER_START, POST_REGISTER_SUCCESS } from '../../types';

export default function register(state, action) {
  switch (action.type) {
    case POST_REGISTER_START:
      return { ...state };
    case POST_REGISTER_SUCCESS:
      return {
        ...state,
        data: action.results,
        error: null,
        success: true,
      };
    case POST_REGISTER_ERROR:
      return {
        ...state,
        data: [],
        error: action.detail,
        success: false,
      };
    default:
      return { ...state };
  }
}
