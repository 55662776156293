import { takeLatest, put, call } from 'redux-saga/effects'
import { GET_GROUP_CYCLE_ERROR, GET_GROUP_CYCLE_START, GET_GROUP_CYCLE_SUCCESS } from "../../types"

import apiCall from '../../api/apiGet'

export function* getCycle({ payload }) {
    try {
        const url = '/group_cycle/'
        const method = 'GET'
        const headers = { Authorization: 'Bearer ' + payload.token }
        const results = yield call(apiCall, url, method, headers)
        yield put({ type: GET_GROUP_CYCLE_SUCCESS, results: results.data })
    } catch (error) {
        yield put({ type: GET_GROUP_CYCLE_ERROR, error })
    }
}

export default function* group_cycle() {
    yield takeLatest(GET_GROUP_CYCLE_START, getCycle)
}
