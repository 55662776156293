import {GET_ALL_NOTIFICATION_ERROR, GET_ALL_NOTIFICATION_START, GET_ALL_NOTIFICATION_SUCCESS} from "../../types";

export default function notifications(state, action) {
  switch (action.type) {
    case GET_ALL_NOTIFICATION_START:
      return {
        ...state,
        data: [],
        loading: true,
        error: null
      };
    case GET_ALL_NOTIFICATION_SUCCESS:
      return {
        ...state,
        data: action.results,
        loading: false,
        error: null
      };
    case GET_ALL_NOTIFICATION_ERROR:
      return {
        ...state,
        data: [],
        loading: false,
        error: action.detail
      };
    default:
      return { ...state };
  }
}
