import {takeLatest, put, call} from 'redux-saga/effects';
import apiCall from '../../api';
import {DELETE_MAGAZINE_ERROR, DELETE_MAGAZINE_START, DELETE_MAGAZINE_SUCCESS} from "../../types";

export function* deleteMagazine({payload}) {
    try {
        const url = `/magazines/${payload.id}/`;
        const method = 'DELETE';
        const headers = {Authorization: 'Bearer ' + payload.token, 'Content-Type':'application/json'};
        const results = yield call(apiCall, url, method, payload.formData, headers);
        yield put({type: DELETE_MAGAZINE_SUCCESS, results: results.data});
        yield put(payload.navigate('/app/content-management/manage-magazine'))
    } catch (error) {
        yield put({type: DELETE_MAGAZINE_ERROR, error});
    }
}

export default function* delete_magazine() {
    yield takeLatest(DELETE_MAGAZINE_START, deleteMagazine);
}