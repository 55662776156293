/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import React, {FC, useContext, useEffect, useState} from 'react'
import { Context } from '../../../context/QuestionsContext'
import {BaseInput, FileInput, SwitchInput, Text, TextareaInput} from '../../atoms'
import { ContentSection } from '../../organisms'
import {useParams} from "react-router-dom";
import {useSelector} from "react-redux";

const NewForm: FC = () => {
  const { formData, handleChangeFormData: handleChange, handleUpdateFormData: handleData } = useContext(Context)
  let { id } = useParams();
  const data_question = useSelector((state: any) => {
    return state.questions.data.find((r: any) => parseInt(id+'') === r.id)
  })

  useEffect(() => {
    if (!!id) {
      handleData(data_question)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, data_question])

  const [isQuestionValid, setQuestionValid] = useState<boolean>(false)
  const [isAnswerValid, setIsAnswerValid] = useState<boolean>(false)

  useEffect(() => {
    setQuestionValid(formData.question.length > 2)
  }, [formData.question])
  useEffect(() => {
    setIsAnswerValid(formData.answer.length > 2)
  }, [formData.answer])

  return (
    <ContentSection
      style={css`
        display: grid;
        gap: 18px;
      `}
      header={
        <React.Fragment>
          <Text textType="h4" weight="black">
            {id ? 'Editar':'Nueva'} pregunta
          </Text>
        </React.Fragment>
      }
    >
      <BaseInput
        label="Pregunta"
        value={formData.question}
        onChange={(value) => handleChange({ question: value })}
        placeholder="Escribe tu pregunta"
        maxLength={1500}
        error={!isQuestionValid && formData.question.length > 0}
        success={isQuestionValid}
        hint={!isQuestionValid && formData.question.length > 0 ? 'Digite mas de dos letras' : ''}
      />
      <TextareaInput
        label="Respuesta"
        value={formData.answer}
        onChange={(value) => handleChange({ answer: value })}
        placeholder="Escribe el contenido de tu entrada"
        maxLength={1500}
        error={!isAnswerValid && formData.answer.length > 0}
        success={isAnswerValid}
        hint={!isAnswerValid && formData.answer.length > 0 ? 'Digite mas de dos letras' : ''}
      />
      <div style={{ width: 360 }}>
        <FileInput
          label={formData.is_image ? 'Imagen' : 'Video'}
          value={formData.image}
          onChange={(value) => handleChange({ image: value })}
          accept={formData.is_image ? 'image/*' : 'video/*'}
        />
      </div>
      <SwitchInput checked={formData.is_image}
        onCheckedChange={(value) => handleChange({ is_image: value })}
        label={formData.is_image ? 'Subir Imagen': 'Subir Video'}
      />

      <SwitchInput checked={formData.active}
        onCheckedChange={(value) => handleChange({ active: value })}
        label={formData.active ? 'Activo': 'Inactivo'}
      />
    </ContentSection>
  )
}

export default NewForm
