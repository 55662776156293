import * as React from "react"

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  const color = props.color || '#34189A'
  return (
    <svg
      width={12}
      height={12}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.243 1.757a6 6 0 10-8.486 8.485 6 6 0 008.486-8.485zM6 11.196A5.202 5.202 0 01.804 6 5.202 5.202 0 016 .804 5.202 5.202 0 0111.196 6 5.202 5.202 0 016 11.196z"
        fill={color}
      />
      <path
        d="M7.885 4L6.19 5.766 4.495 4 4 4.495 5.695 6.26 4 8.027l.495.494L6.19 6.755l1.695 1.766.495-.494L6.685 6.26 8.38 4.495 7.885 4z"
        fill={color}
      />
    </svg>
  )
}

export default React.memo(SvgComponent)
