import { takeLatest, put, call } from 'redux-saga/effects'
import { GET_GROUP_POSTS_ERROR, GET_GROUP_POSTS_START, GET_GROUP_POSTS_SUCCESS } from "../../types"

import apiCall from '../../api/apiGet'

export function* getPosts({ payload }) {
  try {
    const url = '/group_posts/'
    const method = 'GET'
    const headers = { Authorization: 'Bearer ' + payload.token }
    const results = yield call(apiCall, url, method, headers)
    yield put({ type: GET_GROUP_POSTS_SUCCESS, results: results.data })
  } catch (error) {
    yield put({ type: GET_GROUP_POSTS_ERROR, error })
  }
}

export default function* group_posts() {
  yield takeLatest(GET_GROUP_POSTS_START, getPosts)
}
