/** @jsxImportSource @emotion/react */
import React from 'react'
import navListRoutes from '../../../router/navListRoutes'
import { NavSection } from '../../organisms'
import * as styles from './index.styles'
import {useNavigate} from "react-router-dom";

const Sidebar: React.FC = () => {
  const navigate = useNavigate()

  const goToHome = () => {
    navigate('/');
  }
  return (
    <div css={styles.root}>
      <img src='/logo.png' alt="logo" width={167} css={{ marginBottom: '46px', cursor: 'pointer' }} onClick={goToHome} />
      <nav css={styles.nav}>
        {
          navListRoutes.map(routeSection => (
            <NavSection key={`sidebarSection-${routeSection.title}`} routeSection={routeSection} />
          ))
        }
      </nav>
    </div>
  )
}

export default Sidebar
