/** @jsxImportSource @emotion/react */
import React from 'react'
import {Button, Text} from '../../atoms'
import * as styles from './index.styles'
import {CloseIcon} from "../../../assets";
import {css} from "@emotion/react";

type Props = {
    show: boolean,
    title: string,
    text: string,
    btnSuccess: string,
    onClickSuccess: () => void,
    btnWarning: string,
    onClickWarning: () => void,
    onClickClose: () => void
}

const Modal: React.FC<Props> = ({
  show = false,
  title,
  text,
  btnSuccess,
  onClickSuccess,
  btnWarning,
  onClickWarning,
  onClickClose
}) => {

  if (!show) {
    return null;
  }

  return (
    <div css={styles.root}>
      <div css={styles.modal}>
        <CloseIcon width={24} height={24}
                   css={styles.modal_close}
                   onClick={onClickClose}/>
        <Text textType='h3' weight='black'>{title}</Text>
        <div css={styles.modal_content}>
          <Text textType='p'>{text}</Text>
        </div>
        <div css={styles.modal_footer}>
          <Button variant={"success"}
                  style={css`display: ${btnSuccess === "" ? 'none' : 'block'};width: 130px`}
                  size='md'
                  onClick={onClickSuccess}>
              {btnSuccess}
          </Button>
          <Button variant={"danger"}
                  style={css`display: ${btnWarning === "" ? 'none' : 'block'};width: 130px`}
                  size='md'
                  onClick={onClickWarning}>
              {btnWarning}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default Modal
