import React from 'react'
import { Text } from '../../atoms'
import CardSection from '../../molecules/CardSection'
import { TrafficGraph } from '../../organisms'

const Traffic: React.FC = () => {
  return (
    <CardSection padding="24px">
      <Text textType='h4' weight='black'>Tráfico</Text>
      <Text textType='p'>Octubre - Diciembre 2021</Text>
      <TrafficGraph />
    </CardSection>
  )
}

export default Traffic
