/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react'
import React, {useContext} from 'react'
import { ThemeUI } from '../../../theme'
import { Button, Card, Text } from '../../atoms'
import { ContentSection } from '../../organisms'
import * as styles from './index.styles'
import {Context} from "../../../context/NotificationContext";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import {create_notification} from "../../../redux/actions/notification/create_notification";
import {update_notification} from "../../../redux/actions/notification/update_notification";

const NewPreview: React.FC = () => {
  const APP_URL = process.env.REACT_APP_URL
  const theme = useTheme() as ThemeUI
  const navigate = useNavigate();
  const { formData } = useContext(Context)
  const dispatch = useDispatch()
  const token = useSelector((state: any) => state.login.access)
  let { id } = useParams();
  const handleNotification = (token: any, formData: any) => {
    if(id) {
      if (!formData.image?.name) {
        if (formData.image !== undefined) {
          formData.image = formData.image.indexOf('/media/')>-1 ? undefined : formData.image
        } else {
          formData.image = undefined
        }
      }
      dispatch(update_notification({ token, formData, navigate}))
    } else {
      dispatch(create_notification({ token, formData, navigate}))
    }
  }

  return (
    <ContentSection
      style={styles.root}
      header={
        <Text textType='p' weight='bold'>Vista Previa</Text>
      }
    >
      <div css={(theme: any) => styles.preview(theme)}>
        <Card style={css({ overflow: 'hidden' })}>
          <img
            src={formData.image ? (formData.image?.name ? URL.createObjectURL(formData.image): APP_URL+''+formData.image) : 'https://via.placeholder.com/300x200'}
            alt='imagen'
            css={styles.previewImage}
          />
          <Text textType='sm' style={styles.previewTitle(theme)}>
            {formData.title}
          </Text>
          <div css={styles.previewDescription(theme)}>
            <Text textType='sm' weight='light'>
              {formData.description}
            </Text>
            <Text textType='sm' weight='light'>
              {formData.url_video}
            </Text>
          </div>
        </Card>
      </div>
      <Button onClick={() => handleNotification(token, formData)} disabled={!formData.title || !formData.image
          || !formData.date_initial || !formData.date_ending || !formData.category || !formData.management || !formData.cycle}>
        {!id ? 'Publicar' : 'Guardar'} notificación
      </Button>
    </ContentSection>
  )
}


export default NewPreview
