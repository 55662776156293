import { css } from "@emotion/react";

export const root = css`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  overflow-y: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  
  inset: 0px;
  backdrop-filter: blur(0.3rem);

  background: rgba(188, 188, 188, 0.7);
  transition: all .4s;
  z-index: 12;
`
export const modal = css`
  background: #C4BBE2;
  width: 500px;
  position: relative;
  max-width: 90%;
  padding: 2em 2em;
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 10%), 0 4px 6px -2px rgb(0 0 0 / 5%);
  border-radius: 0.5rem;
`
export const modal_close = css`
  position: absolute;
  top: 10px;
  right: 10px;
  color: #585858;
  cursor: pointer;
`
export const modal_content = css`
  padding-top: 2em;
`
export const modal_footer = css`
  padding-top: 2em;
  display: flex;
  align-items: center;
  justify-content: space-around;
`