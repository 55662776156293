import { CREATE_GROUP_POST_ERROR, CREATE_GROUP_POST_START, CREATE_GROUP_POST_SUCCESS } from "../../types";

export default function create_group_posts(state, action) {
  switch (action.type) {
    case CREATE_GROUP_POST_START:
      return {
        ...state,
        loading: true,
        error: null
      };
    case CREATE_GROUP_POST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null
      };
    case CREATE_GROUP_POST_ERROR:
      return {
        ...state,
        loading: false,
        error: action.detail
      };
    default:
      return { ...state };
  }
}