import {takeLatest, put, call} from 'redux-saga/effects';
import {GET_CYCLE_START, GET_CYCLE_SUCCESS, GET_CYCLE_ERROR} from "../../types";

import apiCall from '../../api/apiGet';

export function* getCycle({payload}) {
    try {
        const url = '/devices_cycle/';
        const method = 'GET';
        const headers = {Authorization: 'Bearer ' + payload.token};
        const results = yield call(apiCall, url, method, headers);
        yield put({type: GET_CYCLE_SUCCESS, results: results.data});
    } catch (error) {
        yield put({type: GET_CYCLE_ERROR, error});
    }
}

export default function* cycle() {
    yield takeLatest(GET_CYCLE_START, getCycle);
}