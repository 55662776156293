/** @jsxImportSource @emotion/react */
import React from 'react'
import {ManagementForm, ManagementPreview} from '../../../components/templates'
import * as styles from '../index.styles'
import Provider from '../../../context/ManagementContext'
import { breadcrumbItems } from '../../utils'
import { Breadcrumb } from '../../../components/atoms'

const { index, contentManagement, manageManagements } = breadcrumbItems

const ManageNews: React.FC = () => {

    return (
        <Provider>
            <Breadcrumb items={[index, contentManagement, manageManagements]} />
            <div css={styles.root}>
                <div css={styles.main}>
                    <ManagementForm />
                </div>
                <div css={styles.right}>
                    <ManagementPreview />
                </div>
            </div>
        </Provider>
    )
}

export default ManageNews