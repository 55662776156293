import { createContext, FC, useState } from 'react'

export type Data = {
  id?: string
  question: string
  answer: string
  image?: File
  is_image: boolean
  active: boolean
}
export interface DataContext {
  formData: Data,
  handleChangeFormData: (data: Partial<Data>) => void,
  handleUpdateFormData: (data: Partial<Data>) => void,
}

const initialData: Data = {
  id: '',
  question: '',
  answer: '',
  image: undefined,
  is_image: true,
  active: true
}

export const Context = createContext<DataContext>({
  formData: initialData,
  handleChangeFormData: (data: Partial<Data>) => {},
  handleUpdateFormData: (data: Partial<Data>) => {},
})

const NewFormDataProvider: FC = (props) => {
  const [formData, setFormData] = useState<Data>(initialData)

  const handleChangeFormData = (data: Partial<Data>) =>
    setFormData({ ...formData, ...data })

  const handleUpdateFormData = (data: Partial<Data>) => {
    if (data && data?.id) {
      setFormData({
        ...formData,
        id: data.id,
        question: data.question || initialData.question,
        answer: data.answer || initialData.answer,
        image: data.image || initialData.image,
        is_image: data.is_image || initialData.is_image,
        active: data.active || false
      })
    }
  }

  return (
    <Context.Provider value={{ formData, handleChangeFormData, handleUpdateFormData }}>
      {props.children}
    </Context.Provider>
  )
}

export default NewFormDataProvider
