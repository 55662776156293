export const columns = [
  {
    Header: 'Id',
    accessor: 'id',
  },
  {
    Header: 'Gerencia',
    accessor: 'management',
  },
  {
    Header: 'Region',
    accessor: 'region',
  },
  {
    Header: 'Sector',
    accessor: 'sector',
  },
  {
    Header: 'Editar',
    accessor: 'editManagement',
  },
  {
    Header: 'Eliminar',
    accessor: 'deleteManagement',
  },
  {
    Header: 'Estado',
    accessor: 'active',
  },
]

