import { takeLatest, put, call } from 'redux-saga/effects'

import apiCall from '../../api/apiGet'
import { GET_OFFERS_ERROR, GET_OFFERS_START, GET_OFFERS_SUCCESS } from '../../types'

export function* getOffers({ payload }) {
  try {
    const url = '/offers_by_cycle/'
    const method = 'GET'
    const headers = { Authorization: 'Bearer ' + payload.token }
    const results = yield call(apiCall, url, method, headers)
    yield put({ type: GET_OFFERS_SUCCESS, results: results.data })
  } catch (error) {
    yield put({ type: GET_OFFERS_ERROR, error })
  }
}

export default function* offers() {
  yield takeLatest(GET_OFFERS_START, getOffers)
}
