import {takeLatest, put, call} from 'redux-saga/effects';
import apiCall from '../../api';
import {DELETE_CDC_CARD_ERROR, DELETE_CDC_CARD_START, DELETE_CDC_CARD_SUCCESS} from "../../types";

export function* deleteCC({payload}) {
    try {
        const url = `/cdc_card_caregories/${payload.id}/`;
        const method = 'DELETE';
        const headers = {Authorization: 'Bearer ' + payload.token, 'Content-Type':'application/json'};
        const results = yield call(apiCall, url, method, payload.formData, headers);
        yield put({type: DELETE_CDC_CARD_SUCCESS, results: results.data});
    } catch (error) {
        yield put({type: DELETE_CDC_CARD_ERROR, error});
    }
}

export default function* delete_card_category() {
    yield takeLatest(DELETE_CDC_CARD_START, deleteCC);
}