import {CREATE_NOTIFICATION_ERROR, CREATE_NOTIFICATION_START, CREATE_NOTIFICATION_SUCCESS} from "../../types";

export default function create_notification(state, action) {
  switch (action.type) {
    case CREATE_NOTIFICATION_START:
      return {
        ...state,
        data: [],
        loading: true,
        error: null
      };
    case CREATE_NOTIFICATION_SUCCESS:
      return {
        ...state,
        data: action.results,
        loading: false,
        error: null
      };
    case CREATE_NOTIFICATION_ERROR:
      return {
        ...state,
        data: [],
        loading: false,
        error: action.detail
      };
    default:
      return { ...state };
  }
}
