import { css } from "@emotion/react";

export const root = css`
  padding: 24px;
  padding-bottom: 6px;
  width: 100%:
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`
export const input = css`
  font-size: 12px;
  margin-top: 5px;
  .css-1s2u09g-control {
    align-items: center;
    background-color: hsl(0, 0%, 100%);
    border-color: #6C6C6C;
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    cursor: default;
    flex-wrap: wrap;
    justify-content: space-between;
    min-height: 38px;
    outline: 0!important;
    position: relative;
    transition: all 100ms;
    box-sizing: border-box;
  }
`