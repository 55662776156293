/** @jsxImportSource @emotion/react */
import React from 'react'
import { SerializedStyles } from '@emotion/react'
import { CardSection, HeaderSection } from '../../molecules'
type Props = {
  children: React.ReactNode
  header: React.ReactNode
  headerAction?: React.ReactNode
  style?: SerializedStyles
}

const ContentSection: React.FC<Props> = (props) => {
  return (
    <CardSection style={props.style} padding="24px">
      <HeaderSection>{props.header}</HeaderSection>
      {props.children}
    </CardSection>
  )
}

export default ContentSection
