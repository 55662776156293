/** @jsxImportSource @emotion/react */
import React, { Fragment } from 'react'
import { useNavigate } from 'react-router'
import { useSelector, useDispatch } from 'react-redux';
import { CreateSide, ManageTableUsers } from '../../../components/templates'
import * as styles from '../index.styles'
import { columns } from './mockData'
import { breadcrumbItems } from '../../utils'
import { Breadcrumb } from '../../../components/atoms'
import { useLocation } from 'react-router-dom';
import {useEffect} from 'react';
import { userdata } from '../../../redux/actions/auth/userdata'

const { index, users } = breadcrumbItems

const ManageUsers: React.FC = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const users_list = useSelector((state: any) => state.userdata.data)
  const permissions_list = useSelector((state: any) => state.permissions.data)
  const location = useLocation();
  const token = useSelector((state: any) => state.login.access)

  useEffect(() => {
    dispatch(userdata({ token }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  return (
    <Fragment>
      <Breadcrumb items={[index, users]} />
      {permissions_list.find((element: any) => element.codename === 'add_user') ? (
      <div css={styles.root}>
        <div css={styles.main}>
          <ManageTableUsers
            title="Administrar usuarios"
            subtitle="Usuarios creados"
            columns={columns}
            data={users_list || []}
          />
        </div>
        <div css={styles.right}>
          <CreateSide
            title="Crear nuevo usuario"
            message="Massa vulputate id placerat vitae ornare a orci. Gravida augue viverra pretium adipiscing mauris."
            textButton="Crear nuevo usuario"
            onCreate={() => navigate('/app/users/create')}
          />
        </div>
      </div>
      ) : (<div css={styles.root}><h1>No tiene permisos para trabajar en esta sección</h1></div>)
      }      
    </Fragment>
  )
}

export default ManageUsers
