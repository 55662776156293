/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react'
import React, {useContext} from 'react'
import { ThemeUI } from '../../../theme'
import { Button, Card, Text } from '../../atoms'
import { ContentSection } from '../../organisms'
import * as styles from './index.styles'
import { Context } from '../../../context/GroupNewContext'
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {create_group_news} from "../../../redux/actions/news/create_group_news";
import {update_group_news} from "../../../redux/actions/news/update_group_news";

const NewGroupPreview: React.FC = () => {
  const theme = useTheme() as ThemeUI
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const token = useSelector((state: any) => state.login.access)
  const { formData } = useContext(Context)
  let { id } = useParams()

  const handleSubmit = (token: any, formData: any) => {
    if (id) {
      dispatch(update_group_news({ token, formData, navigate }))
    } else {
      dispatch(create_group_news({ token, formData, navigate }))
    }
  }

  return (
    <ContentSection
      style={styles.root}
      header={
        <Text textType='p' weight='bold'>Vista Previa</Text>
      }
    >
      <div css={(theme: any) => styles.preview(theme)}>
        <Card style={css({ overflow: 'hidden' })}>
          <Text textType='sm' style={styles.previewTitle(theme)}>
            {formData.title}
          </Text>
          <div css={styles.previewDescription(theme)}>
            <Text textType='sm' weight='light'>
              {formData.description}
            </Text>
            <Text textType='sm' weight='bold'>
              {formData.category}
            </Text>
          </div>
        </Card>
      </div>
      <Button onClick={() => handleSubmit(token, formData)} disabled={!formData.title || !formData.category}>
        {id ? 'Guardar cambios del' : 'Publicar'} grupo
      </Button>
    </ContentSection>
  )
}


export default NewGroupPreview
