import { Store } from 'redux';
import axios from "axios";

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL_V1 +'/',
  headers: {
    'Content-Type': 'application/json',
  },
})

let store: Store

export const injectStore = (_store: Store) => { store = _store }

instance.interceptors.request.use(async (config) => {
  const state = store.getState()
  if (state.auth.login.data) {
    const { token, type } = state.auth.login.data?.auth
    const { headers } = config
    if (headers) headers.Authorization = `${type} ${token}`
  }
  return config
}, (error) => {
  return Promise.reject(error)
})

export default instance
