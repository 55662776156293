import * as React from "react"

export default React.memo((props: React.SVGProps<SVGSVGElement>) => (
    <svg
        width={13}
        height={12}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g clip-path="url(#clip0_176_3547)">
            <path d="M8.8 2.1C8.8 2.1 8.8 2 8.8 2.1C8.8 2 8.8 2 8.8 2.1L6.8 0.0999998C6.8 0.0999998 6.8 0.0999998 6.7 0.0999998H1.3C1.2 -1.52737e-07 1.1 0.0999999 1.1 0.2V1.2H0.1C0.1 1.1 0 1.2 0 1.3V11.7C0 11.8 0.1 11.9 0.2 11.9H7.5C7.6 11.9 7.7 11.8 7.7 11.7V10.7H8.7C8.8 10.7 8.9 10.6 8.9 10.5L8.8 2.1ZM8.2 1.9H6.9V0.6L8.2 1.9ZM7.3 11.6H0.4V1.6H1.2V10.7C1.2 10.8 1.3 10.9 1.4 10.9H7.4V11.6H7.3ZM1.5 10.4V0.4H6.5V2.2C6.5 2.3 6.6 2.4 6.7 2.4H8.5V10.5H1.5V10.4Z"/>
            <path d="M11.5999 1.2C11.5999 1.1 11.4999 1.1 11.3999 1.1H11.2999C11.1999 0.3 10.9999 0 10.6999 0C10.3999 0 10.2999 0.3 10.0999 1.1H9.9999C9.8999 1.1 9.7999 1.2 9.7999 1.2C9.5999 2.2 9.3999 3.8 9.3999 5.6C9.3999 8 9.8999 10.4 10.1999 11.6C10.2999 11.8 10.3999 12 10.6999 12C10.8999 12 11.0999 11.9 11.1999 11.6C11.4999 10.4 11.9999 8 11.9999 5.6C11.9999 3.8 11.8999 2.2 11.5999 1.2ZM11.5999 5.6C11.5999 6.8 11.4999 8 11.2999 9H10.0999C9.8999 8 9.7999 6.8 9.7999 5.6C9.7999 4.5 9.7999 3.5 9.8999 2.7H11.3999C11.5999 3.5 11.5999 4.5 11.5999 5.6ZM11.0999 10.1H10.2999C10.2999 9.9 10.1999 9.6 10.1999 9.3H11.2999C11.1999 9.6 11.1999 9.9 11.0999 10.1ZM9.9999 2.3C9.9999 2 10.0999 1.7 10.0999 1.5H10.1999H10.9999H11.0999C11.1999 1.7 11.1999 2 11.1999 2.3H9.9999ZM10.6999 0.4C10.6999 0.5 10.7999 0.7 10.8999 1.1H10.4999C10.5999 0.7 10.6999 0.5 10.6999 0.4ZM10.6999 11.6C10.6999 11.6 10.5999 11.6 10.5999 11.5C10.4999 11.2 10.4999 10.9 10.3999 10.5H11.0999C10.9999 10.9 10.8999 11.2 10.8999 11.5C10.7999 11.5 10.7999 11.6 10.6999 11.6Z"/>
            <path d="M10.7 8.3C10.8 8.3 10.9 8.2 10.9 8.1V3.5C10.9 3.4 10.8 3.3 10.7 3.3C10.6 3.3 10.5 3.4 10.5 3.5V8.2C10.6 8.2 10.6 8.3 10.7 8.3Z"/>
            <path d="M3.5001 2.5C3.4001 2.5 3.3001 2.5 3.3001 2.6L2.9001 3.4L2.5001 3C2.5001 3 2.4001 3 2.3001 3C2.2001 3.1 2.2001 3.2 2.3001 3.3L2.8001 3.8L2.9001 3.9C3.0001 3.9 3.0001 3.9 3.0001 3.8L3.5001 2.8C3.6001 2.6 3.6001 2.5 3.5001 2.5Z"/>
            <path d="M3.5002 4.3C3.4002 4.3 3.3002 4.3 3.3002 4.4L2.9002 5.2L2.5002 4.8C2.4002 4.7 2.3002 4.7 2.2002 4.8C2.2002 4.9 2.2002 5 2.3002 5.1L2.8002 5.6L2.9002 5.7C3.0002 5.7 3.0002 5.7 3.0002 5.6L3.5002 4.6C3.6002 4.4 3.6002 4.3 3.5002 4.3Z"/>
            <path d="M3.5 6.1C3.4 6.1 3.3 6.1 3.3 6.2L2.9 7L2.5 6.7C2.4 6.6 2.3 6.6 2.2 6.7C2.1 6.8 2.1 6.9 2.2 7L2.7 7.5L2.8 7.6C2.9 7.6 2.9 7.6 2.9 7.5L3.4 6.5C3.6 6.3 3.6 6.2 3.5 6.1Z"/>
            <path d="M7.3002 3H4.4002C4.3002 3 4.2002 3.1 4.2002 3.2C4.2002 3.3 4.3002 3.4 4.4002 3.4H7.3002C7.4002 3.4 7.5002 3.3 7.5002 3.2C7.5002 3.1 7.4002 3 7.3002 3Z"/>
            <path d="M4.4002 5.2H6.2002C6.3002 5.2 6.4002 5.1 6.4002 5C6.4002 4.9 6.3002 4.8 6.2002 4.8H4.4002C4.3002 4.8 4.2002 4.9 4.2002 5C4.2002 5.1 4.3002 5.2 4.4002 5.2Z"/>
            <path d="M7.3002 6.6H4.4002C4.3002 6.6 4.2002 6.7 4.2002 6.8C4.2002 6.9 4.3002 7 4.4002 7H7.3002C7.4002 7 7.5002 6.9 7.5002 6.8C7.5002 6.7 7.4002 6.6 7.3002 6.6Z"/>
            <path d="M6.8002 8.39999H4.4002C4.3002 8.39999 4.2002 8.49999 4.2002 8.59999C4.2002 8.69999 4.3002 8.79999 4.4002 8.79999H6.8002C6.9002 8.79999 7.0002 8.69999 7.0002 8.59999C6.9002 8.49999 6.9002 8.39999 6.8002 8.39999Z"/>
            <path d="M3.1001 8.39999H2.8001C2.7001 8.39999 2.6001 8.49999 2.6001 8.59999C2.6001 8.69999 2.7001 8.79999 2.8001 8.79999H3.1001C3.2001 8.79999 3.3001 8.69999 3.3001 8.59999C3.2001 8.49999 3.2001 8.39999 3.1001 8.39999Z"/>
        </g>
        <defs>
            <clipPath id="clip0_176_3547">
                <rect width="12" height="11.9" fill="white"/>
            </clipPath>
        </defs>
    </svg>
))
