import { CREATE_OFFERS_ERROR, CREATE_OFFERS_START, CREATE_OFFERS_SUCCESS } from "../../types";

export default function create_offers(state, action) {
  switch (action.type) {
    case CREATE_OFFERS_START:
      return {
        ...state,
        loading: true,
        error: null
      };
    case CREATE_OFFERS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null
      };
    case CREATE_OFFERS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.detail
      };
    default:
      return { ...state };
  }
}
