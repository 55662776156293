/** @jsxImportSource @emotion/react */
import React from 'react'
import { TextProps } from './types'
import { textStyle } from './index.styles'

const Text: React.FC<TextProps> = ({ children, ...props }) => (
  <div css={(theme: any) => textStyle({ theme, ...props })} >
    {children}
  </div>
)

export default Text
