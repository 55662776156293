/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import React, {FC, useContext, useEffect} from 'react'
import { Context } from '../../../context/CardCategoryContext'
import { FileInput, SwitchInput, Text } from '../../atoms'
import { ContentSection } from '../../organisms'
import {useParams} from "react-router-dom";
import Select from "react-select";
import { CATEGORIES_ALL } from '../../../category_all'
import * as styles from "../Header/index.styles";
import {useSelector} from "react-redux";

const OffersForm: FC = () => {
  const { formData, handleChangeFormData: handleChange, handleUpdateFormData: handleData } = useContext(Context)
  let { id } = useParams();
  const detail_data = useSelector((state: any) => state.card_category.data)
  const card_data = id ? detail_data?.find((r:any) => r.id === parseInt(id+'')) : null

  useEffect(() => {
    if (!!id) {
      handleData(card_data)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, card_data])

  const categoryInit = card_data?.category ? { value: card_data.category, label: card_data.category } : { value: '', label: '' }

  const handleCategory = (e: any) => {
    handleChange({ category: e.value })
  }

  return (
    <ContentSection style={css`display: grid;gap: 18px;`}
      header={
        <React.Fragment>
          <Text textType="h4" weight="black">
            {id ? 'Editar':'Nueva'} tarjeta
          </Text>
        </React.Fragment>
      }
    >
      <div style={{ width: 360 }}>
        <FileInput
          label="Imagen"
          value={formData.image}
          onChange={(value) => handleChange({ image: value })}
          accept="image/*"
        />
      </div>
      <Text textType="label">
        CATEGORÍA :{' '}
        <Select css={styles.input}
                options={CATEGORIES_ALL}
                defaultValue={categoryInit}
                onChange={(e) =>  handleCategory(e)}
        />
      </Text>
      <SwitchInput checked={formData.active}
                   onCheckedChange={(value) => handleChange({ active: value })}
                   label={formData.active ? 'Activo': 'Inactivo'}
      />
    </ContentSection>
  )
}

export default OffersForm
