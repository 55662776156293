/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import React, {FC, useContext, useEffect, useState} from 'react'
import { Context } from '../../../context/GroupNCycleContext'
import {BaseInput, SwitchInput, Text} from '../../atoms'
import { ContentSection } from '../../organisms'
import {CATEGORIES_ALL} from "../../../category_all";
import {useParams} from "react-router";
import {useSelector} from "react-redux";
import Select from "react-select";
import * as styles from "../Header/index.styles";

const validateNumber = (data: string) =>
    /^[0-9]+$/.test(data)

const CycleGroupForm: FC = () => {
  const { formData, handleChangeFormData: handleChange, handleUpdateFormData: handleData } = useContext(Context)
  let { id } = useParams()
  const detail_data = useSelector((state: any) => state.group_cycle.data)
  const group_cycle = id ? detail_data?.find((r:any) => r.id === parseInt(id+'')) : null

  const categoryInit = group_cycle?.category && id ? { value: group_cycle.category, label: group_cycle.category } : { value: '', label: '' }

  const handleCategory = (e: any) => {
    handleChange({ category: e.value })
  }

  useEffect(() => {
    if (!!id) {
      handleData(group_cycle)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, group_cycle])

  const [isCycleValid, setIsCycleValid] = useState<boolean>(false)

  useEffect(() => {
    setIsCycleValid(validateNumber(formData.cycle))
  }, [formData.cycle])

  return (
    <ContentSection
      style={css`display: grid; gap: 18px;`}
      header={
        <React.Fragment>
          <Text textType='h4' weight='black'>{id ? 'Editar' : 'Nuevo'} grupo de noticias por ciclo</Text>
        </React.Fragment>
      }
    >
      <BaseInput
        label="Ciclo"
        placeholder="Escribe un código de  ciclo"
        value={formData.cycle}
        onChange={(value) => handleChange({ cycle: value })}
        maxLength={30}
        error={!isCycleValid && formData.cycle.length > 0}
        success={isCycleValid}
        hint={!isCycleValid && formData.cycle.length > 0 ? 'Digite numeros' : ''}
      />
      <Text textType="label">
        CATEGORÍA :{' '}
        <Select css={styles.input}
                options={CATEGORIES_ALL}
                placeholder="Selecciona una categoría"
                defaultValue={categoryInit}
                onChange={(e) =>  handleCategory(e)}
        />
      </Text>
      <SwitchInput checked={formData.active}
        onCheckedChange={(value) => handleChange({ active: value })}
        label={formData.active ? 'Activo': 'Inactivo'}
      />
    </ContentSection>
  )
}

export default CycleGroupForm
