import {takeLatest, put, call} from 'redux-saga/effects';
import apiCall from '../../api';
import {DELETE_NEWS_ERROR, DELETE_NEWS_START, DELETE_NEWS_SUCCESS} from "../../types";

export function* deleteNews({payload}) {
    try {
        const url = `/news/${payload.id}/`;
        const method = 'DELETE';
        const headers = {Authorization: 'Bearer ' + payload.token, 'Content-Type':'application/json'};
        const results = yield call(apiCall, url, method, payload.formData, headers);
        yield put({type: DELETE_NEWS_SUCCESS, results: results.data});
        yield put(payload.navigate('/app/content-management/manage-group-news'));
    } catch (error) {
        yield put({type: DELETE_NEWS_ERROR, error});
    }
}

export default function* delete_news() {
    yield takeLatest(DELETE_NEWS_START, deleteNews);
}