import palette from "./palette"
import typography from "./typography"

const breakpoints = {
  desktop: "1024px",
  tablet: "768px",
  mobile: "480px"
}

const spacing = {
  xs: '1rem',
  sm: '2rem',
  md: '3rem',
  lg: '4rem',
  xl: '5rem'
}

const theme = {
  breakpoints,
  typography,
  palette,
  spacing,
  layout: {
    button: {
      primary: {
        default: palette.primary,
        hover: '#5942AC',
        disabled: palette.primary30,
      },
      secondary: {
        default: palette.secondary,
        hover: palette.secondary30,
        disabled: palette.secondary20,
      },
      success: {
        default: palette.success,
        hover: '#76FAD4',
        disabled: '#B5FFEA',
      },
      danger: {
        default: palette.danger,
        hover: '#F5A374',
        disabled: '#F6C8AD',
      },
      warning: {
        default: palette.warning,
        hover: '#FFF9A4',
        disabled: '#FFFCD0',
      },
      info: {
        default: palette.info,
        hover: '#91E7F4',
        disabled: '#D6F2F7',
      },
    }
  }
}

export default theme;
type ThemeUI = typeof theme;
export type { ThemeUI };
