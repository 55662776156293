import {takeLatest, put, call} from 'redux-saga/effects';
import {GET_ALL_BANNER_ERROR, GET_ALL_BANNER_START, GET_ALL_BANNER_SUCCESS} from "../../types";

import apiCall from '../../api/apiGet';

export function* getBanners({payload}) {
  try {
    const url = '/banners/';
    const method = 'GET';
    const headers = {Authorization: 'Bearer ' + payload.token};
    const results = yield call(apiCall, url, method, headers);
    yield put({type: GET_ALL_BANNER_SUCCESS, results: results.data});
  } catch (error) {
    yield put({type: GET_ALL_BANNER_ERROR, error});
  }
}

export default function* banners() {
  yield takeLatest(GET_ALL_BANNER_START, getBanners);
}
