import {GET_ONE_MANAGEMENT_START, GET_ONE_MANAGEMENT_SUCCESS, GET_ONE_MANAGEMENT_ERROR} from "../../types";

export default function get_one_management(state, action) {
    switch (action.type) {
        case GET_ONE_MANAGEMENT_START:
            return {
                ...state,
                data: [],
                loading: true,
                error: null
            };
        case GET_ONE_MANAGEMENT_SUCCESS:
            return {
                ...state,
                data: action.results,
                loading: false,
                error: null
            };
        case GET_ONE_MANAGEMENT_ERROR:
            return {
                ...state,
                data: [],
                loading: false,
                error: action.detail
            };
        default:
            return { ...state };
    }
}
