/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react'
import React, { useContext } from 'react'
import { ThemeUI } from '../../../theme'
import { Button, Card, Text } from '../../atoms'
import { ContentSection } from '../../organisms'
import * as styles from './index.styles'
import { Context } from '../../../context/EventsContext'
import { useDispatch, useSelector } from 'react-redux'
import { create_event } from '../../../redux/actions/events/create_event'
import {useNavigate, useParams} from 'react-router-dom'
import moment from 'moment'
import {update_events} from "../../../redux/actions/events/update_events";


const NewPreview: React.FC = () => {
  const theme = useTheme() as ThemeUI
  const navigate = useNavigate()
  const { formData } = useContext(Context)
  const dispatch = useDispatch()
  const token = useSelector((state: any) => state.login.access)
  let { id } = useParams()

  const handleSubmit = (token: any, formData: any) => {
    const data = {
      ...formData,
      date: moment(formData.date).format('YYYY-MM-DD'),
      hour_start: moment(formData.hour_start).format().slice(0, 19),
      hour_end: moment(formData.hour_end).format().slice(0, 19),
    }
    if (id) {
      dispatch(update_events({ token, formData: data, navigate }))
    }
    else {
      dispatch(create_event({ token, formData: data, navigate }))
    }
  }

  return (
    <ContentSection
      style={styles.root}
      header={
        <Text textType="p" weight="bold">
          Vista Previa
        </Text>
      }
    >
      <div css={(theme: any) => styles.preview(theme)}>
        <Card style={css({ overflow: 'hidden' })}>
          <Text textType='sm' style={styles.previewTitle(theme)}>
            {formData.title}
          </Text>
          <div css={styles.previewDescription(theme)}>
            <Text textType="sm" weight="light">
              {formData.description}
            </Text>
            <Text textType="sm" weight="light">
              {formData.url}
            </Text>
            <Text textType="sm" weight="light">
              {formData.category}
            </Text>
            <Text textType="sm" weight="light">
              {formData.cycle}
            </Text>
          </div>
          <img
            src={formData.image !== undefined && !formData.image ? URL.createObjectURL(formData.image) : 'https://via.placeholder.com/300x200'}
            alt='placeholder'
            css={styles.previewImage}
          />
        </Card>
      </div>
      <Button onClick={() => handleSubmit(token, formData)}
              disabled={!formData.title || !formData.date || !formData.hour_start || !formData.hour_end ||
                  !formData.category || !formData.management || !formData.cycle}>
        {!id ? 'Publicar' : 'Guardar'} eventos
      </Button>
    </ContentSection>
  )
}

export default NewPreview
