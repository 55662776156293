import { css } from '@emotion/react';
import { ThemeUI } from '../../../theme';

export const root = css`
  display: grid;
  gap: 12px;
`

export const preview = (theme: ThemeUI) => css`
  display: flex;
  flex-direction: column;
  border: 1px dashed #D7D7D7;
  border-radius: 4px;
  background-color: ${theme.palette.secondary};
  padding: 32px 10px;
`

export const previewTitle = (theme: ThemeUI) => css`
  background-color: ${theme.palette.primary10};
  padding: 12px 12px 6px;
`

export const previewImage = css`
  width: 100%;
`
export const previewDescription = (theme: ThemeUI) => css`
  padding: 12px 12px;
  border-bottom: 1px solid ${theme.palette.secondary20};
  display: grid;
  gap: 6px
`

export const previewButton = (theme: ThemeUI) => css`
  margin: 12px;
  width: fill-available;
`