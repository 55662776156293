export const LOGOUT = 'LOGOUT';

export const POST_LOGIN_START = 'POST_LOGIN_START';
export const POST_LOGIN_SUCCESS = 'POST_LOGIN_SUCCESS';
export const POST_LOGIN_ERROR = 'POST_LOGIN_ERROR';

export const POST_REGISTER_START = 'POST_REGISTER_START';
export const POST_REGISTER_SUCCESS = 'POST_REGISTER_SUCCESS';
export const POST_REGISTER_ERROR = 'POST_REGISTER_ERROR';

export const GET_USER_START = 'GET_USER_START';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_ERROR = 'GET_USER_ERROR';

export const GET_PERMISSIONS_START = 'GET_PERMISSIONS_START';
export const GET_PERMISSIONS_SUCCESS = 'GET_PERMISSIONS_SUCCESS';
export const GET_PERMISSIONS_ERROR = 'GET_PERMISSIONS_ERROR';

export const GET_MANAGEMENT_START = 'GET_MANAGEMENT_START';
export const GET_MANAGEMENT_SUCCESS = 'GET_MANAGEMENT_SUCCESS';
export const GET_MANAGEMENT_ERROR = 'GET_MANAGEMENT_ERROR';

export const CREATE_MANAGEMENT_START = 'CREATE_MANAGEMENT_START';
export const CREATE_MANAGEMENT_SUCCESS = 'CREATE_MANAGEMENT_SUCCESS';
export const CREATE_MANAGEMENT_ERROR = 'CREATE_MANAGEMENT_ERROR';

export const UPDATE_MANAGEMENT_START = 'UPDATE_MANAGEMENT_START';
export const UPDATE_MANAGEMENT_SUCCESS = 'UPDATE_MANAGEMENT_SUCCESS';
export const UPDATE_MANAGEMENT_ERROR = 'UPDATE_MANAGEMENT_ERROR';

export const GET_ONE_MANAGEMENT_START = 'GET_ONE_MANAGEMENT_START';
export const GET_ONE_MANAGEMENT_SUCCESS = 'GET_ONE_MANAGEMENT_SUCCESS';
export const GET_ONE_MANAGEMENT_ERROR = 'GET_ONE_MANAGEMENT_ERROR';

export const DELETE_MANAGEMENT_START = 'DELETE_MANAGEMENT_START';
export const DELETE_MANAGEMENT_SUCCESS = 'DELETE_MANAGEMENT_SUCCESS';
export const DELETE_MANAGEMENT_ERROR = 'DELETE_MANAGEMENT_ERROR';

export const GET_GROUP_NEWS_START = 'GET_GROUP_NEWS_START';
export const GET_GROUP_NEWS_SUCCESS = 'GET_GROUP_NEWS_SUCCESS';
export const GET_GROUP_NEWS_ERROR = 'GET_GROUP_NEWS_ERROR';

export const CREATE_GROUP_NEWS_START = 'CREATE_GROUP_NEWS_START';
export const CREATE_GROUP_NEWS_SUCCESS = 'CREATE_GROUP_NEWS_SUCCESS';
export const CREATE_GROUP_NEWS_ERROR = 'CREATE_GROUP_NEWS_ERROR';

export const UPDATE_GROUP_NEWS_START = 'UPDATE_GROUP_NEWS_START';
export const UPDATE_GROUP_NEWS_SUCCESS = 'UPDATE_GROUP_NEWS_SUCCESS';
export const UPDATE_GROUP_NEWS_ERROR = 'UPDATE_GROUP_NEWS_ERROR';

export const CREATE_NEWS_START = 'CREATE_NEWS_START';
export const CREATE_NEWS_SUCCESS = 'CREATE_NEWS_SUCCESS';
export const CREATE_NEWS_ERROR = 'CREATE_NEWS_ERROR';

export const GET_NEWS_DETAIL_START = 'GET_NEWS_DETAIL_START';
export const GET_NEWS_DETAIL_SUCCESS = 'GET_NEWS_DETAIL_SUCCESS';
export const GET_NEWS_DETAIL_ERROR = 'GET_NEWS_DETAIL_ERROR';

export const UPDATE_NEWS_START = 'UPDATE_NEWS_START';
export const UPDATE_NEWS_SUCCESS = 'UPDATE_NEWS_SUCCESS';
export const UPDATE_NEWS_ERROR = 'UPDATE_NEWS_ERROR';

export const GET_GROUP_DETAIL_NEWS_START = 'GET_GROUP_DETAIL_NEWS_START';
export const GET_GROUP_DETAIL_NEWS_SUCCESS = 'GET_GROUP_DETAIL_NEWS_SUCCESS';
export const GET_GROUP_DETAIL_NEWS_ERROR = 'GET_GROUP_DETAIL_NEWS_ERROR';

export const DELETE_GROUP_NEWS_START = 'DELETE_GROUP_NEWS_START';
export const DELETE_GROUP_NEWS_SUCCESS = 'DELETE_GROUP_NEWS_SUCCESS';
export const DELETE_GROUP_NEWS_ERROR = 'DELETE_GROUP_NEWS_ERROR';

export const DELETE_NEWS_START = 'DELETE_NEWS_START';
export const DELETE_NEWS_SUCCESS = 'DELETE_NEWS_SUCCESS';
export const DELETE_NEWS_ERROR = 'DELETE_NEWS_ERROR';

export const GET_ALL_BANNER_START = 'GET_ALL_BANNER_START';
export const GET_ALL_BANNER_SUCCESS = 'GET_ALL_BANNER_SUCCESS';
export const GET_ALL_BANNER_ERROR = 'GET_ALL_BANNER_ERROR';

export const CREATE_BANNER_START = 'CREATE_BANNER_START';
export const CREATE_BANNER_SUCCESS = 'CREATE_BANNER_SUCCESS';
export const CREATE_BANNER_ERROR = 'CREATE_BANNER_ERROR';

export const DETAIL_BANNER_START = 'DETAIL_BANNER_START';
export const DETAIL_BANNER_SUCCESS = 'DETAIL_BANNER_SUCCESS';
export const DETAIL_BANNER_ERROR = 'DETAIL_BANNER_ERROR';

export const UPDATE_BANNER_START = 'UPDATE_BANNER_START';
export const UPDATE_BANNER_SUCCESS = 'UPDATE_BANNER_SUCCESS';
export const UPDATE_BANNER_ERROR = 'UPDATE_BANNER_ERROR';

export const DELETE_BANNER_START = 'DELETE_BANNER_START';
export const DELETE_BANNER_SUCCESS = 'DELETE_BANNER_SUCCESS';
export const DELETE_BANNER_ERROR = 'DELETE_BANNER_ERROR';//

export const GET_ALL_NOTIFICATION_START = 'GET_ALL_NOTIFICATION_START';
export const GET_ALL_NOTIFICATION_SUCCESS = 'GET_ALL_NOTIFICATION_SUCCESS';
export const GET_ALL_NOTIFICATION_ERROR = 'GET_ALL_NOTIFICATION_ERROR';

export const CREATE_NOTIFICATION_START = 'CREATE_NOTIFICATION_START';
export const CREATE_NOTIFICATION_SUCCESS = 'CREATE_NOTIFICATION_SUCCESS';
export const CREATE_NOTIFICATION_ERROR = 'CREATE_NOTIFICATION_ERROR';

export const UPDATE_NOTIFICATION_START = 'UPDATE_NOTIFICATION_START';
export const UPDATE_NOTIFICATION_SUCCESS = 'UPDATE_NOTIFICATION_SUCCESS';
export const UPDATE_NOTIFICATION_ERROR = 'UPDATE_NOTIFICATION_ERROR';

export const DELETE_NOTIFICATION_START = 'DELETE_NOTIFICATION_START';
export const DELETE_NOTIFICATION_SUCCESS = 'DELETE_NOTIFICATION_SUCCESS';
export const DELETE_NOTIFICATION_ERROR = 'DELETE_NOTIFICATION_ERROR';

export const GET_FCM_HISTORY_START = 'GET_FCM_HISTORY_START';
export const GET_FCM_HISTORY_SUCCESS = 'GET_FCM_HISTORY_SUCCESS';
export const GET_FCM_HISTORY_ERROR = 'GET_FCM_HISTORY_ERROR';

export const POST_FCM_SEND_START = 'POST_FCM_SEND_START';
export const POST_FCM_SEND_SUCCESS = 'POST_FCM_SEND_SUCCESS';
export const POST_FCM_SEND_ERROR = 'POST_FCM_SEND_ERROR';

export const POST_FCM_SEND_CSV_START = 'POST_FCM_SEND_CSV_START';
export const POST_FCM_SEND_CSV_SUCCESS = 'POST_FCM_SEND_CSV_SUCCESS';
export const POST_FCM_SEND_CSV_ERROR = 'POST_FCM_SEND_CSV_ERROR';

export const GET_FCM_DEVICES_START = 'GET_FCM_DEVICES_START';
export const GET_FCM_DEVICES_SUCCESS = 'GET_FCM_DEVICES_SUCCESS';
export const GET_FCM_DEVICES_ERROR = 'GET_FCM_DEVICES_ERROR';

export const GET_QUESTIONS_START = 'GET_QUESTIONS_START';
export const GET_QUESTIONS_SUCCESS = 'GET_QUESTIONS_SUCCESS';
export const GET_QUESTIONS_ERROR = 'GET_QUESTIONS_ERROR';

export const CREATE_QUESTIONS_START = 'CREATE_QUESTIONS_START';
export const CREATE_QUESTIONS_SUCCESS = 'CREATE_QUESTIONS_SUCCESS';
export const CREATE_QUESTIONS_ERROR = 'CREATE_QUESTIONS_ERROR';

export const UPDATE_QUESTIONS_START = 'UPDATE_QUESTIONS_START';
export const UPDATE_QUESTIONS_SUCCESS = 'UPDATE_QUESTIONS_SUCCESS';
export const UPDATE_QUESTIONS_ERROR = 'UPDATE_QUESTIONS_ERROR';

export const DELETE_QUESTIONS_START = 'DELETE_QUESTIONS_START';
export const DELETE_QUESTIONS_SUCCESS = 'DELETE_QUESTIONS_SUCCESS';
export const DELETE_QUESTIONS_ERROR = 'DELETE_QUESTIONS_ERROR';

export const GET_EVENTS_START = 'GET_EVENTS_START';
export const GET_EVENTS_SUCCESS = 'GET_EVENTS_SUCCESS';
export const GET_EVENTS_ERROR = 'GET_EVENTS_ERROR';

export const CREATE_EVENTS_START = 'CREATE_EVENTS_START';
export const CREATE_EVENTS_SUCCESS = 'CREATE_EVENTS_SUCCESS';
export const CREATE_EVENTS_ERROR = 'CREATE_EVENTS_ERROR';

export const DETAIL_EVENTS_START = 'DETAIL_EVENTS_START';
export const DETAIL_EVENTS_SUCCESS = 'DETAIL_EVENTS_SUCCESS';
export const DETAIL_EVENTS_ERROR = 'DETAIL_EVENTS_ERROR';

export const UPDATE_EVENTS_START = 'UPDATE_EVENTS_START';
export const UPDATE_EVENTS_SUCCESS = 'UPDATE_EVENTS_SUCCESS';
export const UPDATE_EVENTS_ERROR = 'UPDATE_EVENTS_ERROR';

export const DELETE_EVENTS_START = 'DELETE_EVENTS_START';
export const DELETE_EVENTS_SUCCESS = 'DELETE_EVENTS_SUCCESS';
export const DELETE_EVENTS_ERROR = 'DELETE_EVENTS_ERROR';

export const GET_EVENTS_CYCLE_START = 'GET_EVENTS_CYCLE_START';
export const GET_EVENTS_CYCLE_SUCCESS = 'GET_EVENTS_CYCLE_SUCCESS';
export const GET_EVENTS_CYCLE_ERROR = 'GET_EVENTS_CYCLE_ERROR';//

export const CREATE_GROUP_POST_START = 'CREATE_GROUP_POST_START';
export const CREATE_GROUP_POST_SUCCESS = 'CREATE_GROUP_POST_SUCCESS';
export const CREATE_GROUP_POST_ERROR = 'CREATE_GROUP_POST_ERROR';

export const GET_GROUP_POSTS_START = 'GET_GROUP_POSTS_START';
export const GET_GROUP_POSTS_SUCCESS = 'GET_GROUP_POSTS_SUCCESS';
export const GET_GROUP_POSTS_ERROR = 'GET_GROUP_POSTS_ERROR';

export const CREATE_POSTS_START = 'CREATE_POSTS_START';
export const CREATE_POSTS_SUCCESS = 'CREATE_POSTS_SUCCESS';
export const CREATE_POSTS_ERROR = 'CREATE_POSTS_ERROR';

export const GET_GROUP_DETAIL_POSTS_START = 'GET_GROUP_DETAIL_POSTS_START';
export const GET_GROUP_DETAIL_POSTS_SUCCESS = 'GET_GROUP_DETAIL_POSTS_SUCCESS';
export const GET_GROUP_DETAIL_POSTS_ERROR = 'GET_GROUP_DETAIL_POSTS_ERROR';

export const GET_GROUP_POST_CYCLE_START = 'GET_GROUP_POST_CYCLE_START';
export const GET_GROUP_POST_CYCLE_SUCCESS = 'GET_GROUP_POST_CYCLE_SUCCESS';
export const GET_GROUP_POST_CYCLE_ERROR = 'GET_GROUP_POST_CYCLE_ERROR';

export const GET_NEWS_DETAIL_POST_START = 'GET_NEWS_DETAIL_POST_START';
export const GET_NEWS_DETAIL_POST_SUCCESS = 'GET_NEWS_DETAIL_POST_SUCCESS';
export const GET_NEWS_DETAIL_POST_ERROR = 'GET_NEWS_DETAIL_POST_ERROR';

export const UPDATE_GROUP_POSTS_START = 'UPDATE_GROUP_POSTS_START';
export const UPDATE_GROUP_POSTS_SUCCESS = 'UPDATE_GROUP_POSTS_SUCCESS';
export const UPDATE_GROUP_POSTS_ERROR = 'UPDATE_GROUP_POSTS_ERROR';

export const DELETE_GROUP_POSTS_START = 'DELETE_GROUP_POSTS_START';
export const DELETE_GROUP_POSTS_SUCCESS = 'DELETE_GROUP_POSTS_SUCCESS';
export const DELETE_GROUP_POSTS_ERROR = 'DELETE_GROUP_POSTS_ERROR';

export const UPDATE_POSTS_START = 'UPDATE_POSTS_START';
export const UPDATE_POSTS_SUCCESS = 'UPDATE_POSTS_SUCCESS';
export const UPDATE_POSTS_ERROR = 'UPDATE_POSTS_ERROR';

export const DELETE_POSTS_START = 'DELETE_POSTS_START';
export const DELETE_POSTS_SUCCESS = 'DELETE_POSTS_SUCCESS';
export const DELETE_POSTS_ERROR = 'DELETE_POSTS_ERROR';

export const GET_MAGAZINES_START = 'GET_MAGAZINES_START';
export const GET_MAGAZINES_SUCCESS = 'GET_MAGAZINES_SUCCESS';
export const GET_MAGAZINES_ERROR = 'GET_MAGAZINES_ERROR';

export const CREATE_MAGAZINE_START = 'CREATE_MAGAZINE_START';
export const CREATE_MAGAZINE_SUCCESS = 'CREATE_MAGAZINE_SUCCESS';
export const CREATE_MAGAZINE_ERROR = 'CREATE_MAGAZINE_ERROR';

export const UPDATE_MAGAZINE_START = 'UPDATE_MAGAZINE_START';
export const UPDATE_MAGAZINE_SUCCESS = 'UPDATE_MAGAZINE_SUCCESS';
export const UPDATE_MAGAZINE_ERROR = 'UPDATE_MAGAZINE_ERROR';

export const DELETE_MAGAZINE_START = 'DELETE_MAGAZINE_START';
export const DELETE_MAGAZINE_SUCCESS = 'DELETE_MAGAZINE_SUCCESS';
export const DELETE_MAGAZINE_ERROR = 'DELETE_MAGAZINE_ERROR';

export const GET_CYCLE_START = 'GET_CYCLE_START';
export const GET_CYCLE_SUCCESS = 'GET_CYCLE_SUCCESS';
export const GET_CYCLE_ERROR = 'GET_CYCLE_ERROR';

export const GET_NATURE_MAGAZINE_START = 'GET_NATURE_MAGAZINES_START';
export const GET_NATURE_MAGAZINE_SUCCESS = 'GET_NATURE_MAGAZINES_SUCCESS';
export const GET_NATURE_MAGAZINE_ERROR = 'GET_NATURE_MAGAZINES_ERROR';

export const CREATE_NATURE_MAGAZINE_START = 'CREATE_NATURE_MAGAZINE_START';
export const CREATE_NATURE_MAGAZINE_SUCCESS = 'CREATE_NATURE_MAGAZINE_SUCCESS';
export const CREATE_NATURE_MAGAZINE_ERROR = 'CREATE_NATURE_MAGAZINE_ERROR';

export const UPDATE_NATURE_MAGAZINE_START = 'UPDATE_NATURE_MAGAZINE_START';
export const UPDATE_NATURE_MAGAZINE_SUCCESS = 'UPDATE_NATURE_MAGAZINE_SUCCESS';
export const UPDATE_NATURE_MAGAZINE_ERROR = 'UPDATE_NATURE_MAGAZINE_ERROR';

export const DELETE_NATURE_MAGAZINE_START = 'DELETE_NATURE_MAGAZINE_START';
export const DELETE_NATURE_MAGAZINE_SUCCESS = 'DELETE_NATURE_MAGAZINE_SUCCESS';
export const DELETE_NATURE_MAGAZINE_ERROR = 'DELETE_NATURE_MAGAZINE_ERROR';

export const GET_GROUP_CYCLE_START = 'GET_GROUP_CYCLE_START';
export const GET_GROUP_CYCLE_SUCCESS = 'GET_GROUP_CYCLE_SUCCESS';
export const GET_GROUP_CYCLE_ERROR = 'GET_GROUP_CYCLE_ERROR';

export const CREATE_GROUP_CYCLE_START = 'CREATE_GROUP_CYCLE_START';
export const CREATE_GROUP_CYCLE_SUCCESS = 'CREATE_GROUP_CYCLE_SUCCESS';
export const CREATE_GROUP_CYCLE_ERROR = 'CREATE_GROUP_CYCLE_ERROR';

export const UPDATE_GROUP_CYCLE_START = 'UPDATE_GROUP_CYCLE_START';
export const UPDATE_GROUP_CYCLE_SUCCESS = 'UPDATE_GROUP_CYCLE_SUCCESS';
export const UPDATE_GROUP_CYCLE_ERROR = 'UPDATE_GROUP_CYCLE_ERROR';

export const DELETE_GROUP_CYCLE_START = 'DELETE_GROUP_CYCLE_START';
export const DELETE_GROUP_CYCLE_SUCCESS = 'DELETE_GROUP_CYCLE_SUCCESS';
export const DELETE_GROUP_CYCLE_ERROR = 'DELETE_GROUP_CYCLE_ERROR';

export const DETAIL_NEW_CYCLE_START = 'DETAIL_NEW_CYCLE_START';
export const DETAIL_NEW_CYCLE_SUCCESS = 'DETAIL_NEW_CYCLE_SUCCESS';
export const DETAIL_NEW_CYCLE_ERROR = 'DETAIL_NEW_CYCLE_ERROR';

export const CREATE_NEW_CYCLE_START = 'CREATE_NEW_CYCLE_START';
export const CREATE_NEW_CYCLE_SUCCESS = 'CREATE_NEW_CYCLE_SUCCESS';
export const CREATE_NEW_CYCLE_ERROR = 'CREATE_NEW_CYCLE_ERROR';

export const UPDATE_NEW_CYCLE_START = 'UPDATE_NEW_CYCLE_START';
export const UPDATE_NEW_CYCLE_SUCCESS = 'UPDATE_NEW_CYCLE_SUCCESS';
export const UPDATE_NEW_CYCLE_ERROR = 'UPDATE_NEW_CYCLE_ERROR';

export const DELETE_NEW_CYCLE_START = 'DELETE_NEW_CYCLE_START';
export const DELETE_NEW_CYCLE_SUCCESS = 'DELETE_NEW_CYCLE_SUCCESS';
export const DELETE_NEW_CYCLE_ERROR = 'DELETE_NEW_CYCLE_ERROR';

export const GET_OFFERS_START = 'GET_OFFERS_START';
export const GET_OFFERS_SUCCESS = 'GET_OFFERS_SUCCESS';
export const GET_OFFERS_ERROR = 'GET_OFFERS_ERROR';

export const CREATE_OFFERS_START = 'CREATE_OFFERS_START';
export const CREATE_OFFERS_SUCCESS = 'CREATE_OFFERS_SUCCESS';
export const CREATE_OFFERS_ERROR = 'CREATE_OFFERS_ERROR';

export const UPDATE_OFFERS_START = 'UPDATE_OFFERS_START';
export const UPDATE_OFFERS_SUCCESS = 'UPDATE_OFFERS_SUCCESS';
export const UPDATE_OFFERS_ERROR = 'UPDATE_OFFERS_ERROR';

export const DELETE_OFFERS_START = 'DELETE_OFFERS_START';
export const DELETE_OFFERS_SUCCESS = 'DELETE_OFFERS_SUCCESS';
export const DELETE_OFFERS_ERROR = 'DELETE_OFFERS_ERROR';

export const GET_CDC_CARD_START = 'GET_CDC_CARD_START';
export const GET_CDC_CARD_SUCCESS = 'GET_CDC_CARD_SUCCESS';
export const GET_CDC_CARD_ERROR = 'GET_CDC_CARD_ERROR';

export const CREATE_CDC_CARD_START = 'CREATE_CDC_CARD_START';
export const CREATE_CDC_CARD_SUCCESS = 'CREATE_CDC_CARD_SUCCESS';
export const CREATE_CDC_CARD_ERROR = 'CREATE_CDC_CARD_ERROR';

export const UPDATE_CDC_CARD_START = 'UPDATE_CDC_CARD_START';
export const UPDATE_CDC_CARD_SUCCESS = 'UPDATE_CDC_CARD_SUCCESS';
export const UPDATE_CDC_CARD_ERROR = 'UPDATE_CDC_CARD_ERROR';

export const DELETE_CDC_CARD_START = 'DELETE_CDC_CARD_START';
export const DELETE_CDC_CARD_SUCCESS = 'DELETE_CDC_CARD_SUCCESS';
export const DELETE_CDC_CARD_ERROR = 'DELETE_CDC_CARD_ERROR';

export const GET_GROWTH_PATH_START = 'GET_GROWTH_PATH_START';
export const GET_GROWTH_PATH_SUCCESS = 'GET_GROWTH_PATH_SUCCESS';
export const GET_GROWTH_PATH_ERROR = 'GET_GROWTH_PATH_ERROR';

export const CREATE_GROWTH_PATH_START = 'CREATE_GROWTH_PATH_START';
export const CREATE_GROWTH_PATH_SUCCESS = 'CREATE_GROWTH_PATH_SUCCESS';
export const CREATE_GROWTH_PATH_ERROR = 'CREATE_GROWTH_PATH_ERROR';

export const UPDATE_GROWTH_PATH_START = 'UPDATE_GROWTH_PATH_START';
export const UPDATE_GROWTH_PATH_SUCCESS = 'UPDATE_GROWTH_PATH_SUCCESS';
export const UPDATE_GROWTH_PATH_ERROR = 'UPDATE_GROWTH_PATH_ERROR';

export const DELETE_GROWTH_PATH_START = 'DELETE_GROWTH_PATH_START';
export const DELETE_GROWTH_PATH_SUCCESS = 'DELETE_GROWTH_PATH_SUCCESS';
export const DELETE_GROWTH_PATH_ERROR = 'DELETE_GROWTH_PATH_ERROR';

export const POST_EVENTS_SEND_CSV_START = 'POST_EVENTS_SEND_CSV_START';
export const POST_EVENTS_SEND_CSV_SUCCESS = 'POST_EVENTS_SEND_CSV_SUCCESS';
export const POST_EVENTS_SEND_CSV_ERROR = 'POST_EVENTS_SEND_CSV_ERROR';

export const GET_DRAWER_START = 'GET_DRAWER_START';
export const GET_DRAWER_SUCCESS = 'GET_DRAWER_SUCCESS';
export const GET_DRAWER_ERROR = 'GET_DRAWER_ERROR';

export const CREATE_DRAWER_START = 'CREATE_DRAWER_START';
export const CREATE_DRAWER_SUCCESS = 'CREATE_DRAWER_SUCCESS';
export const CREATE_DRAWER_ERROR = 'CREATE_DRAWER_ERROR';

export const UPDATE_DRAWER_START = 'UPDATE_DRAWER_START';
export const UPDATE_DRAWER_SUCCESS = 'UPDATE_DRAWER_SUCCESS';
export const UPDATE_DRAWER_ERROR = 'UPDATE_DRAWER_ERROR';

export const DELETE_DRAWER_START = 'DELETE_DRAWER_START';
export const DELETE_DRAWER_SUCCESS = 'DELETE_DRAWER_SUCCESS';
export const DELETE_DRAWER_ERROR = 'DELETE_DRAWER_ERROR';

export const REMOVE_POSTS_START = 'REMOVE_POSTS_START';
export const REMOVE_POSTS_SUCCESS = 'REMOVE_POSTS_SUCCESS';
export const REMOVE_POSTS_ERROR = 'REMOVE_POSTS_ERROR';