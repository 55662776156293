import { css } from "@emotion/react";

export const root = css`
  padding: 24px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  & div{
    margin-right: 12px;
  }
  justify-self: flex-end;
`