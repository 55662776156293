import {takeLatest, put, call} from 'redux-saga/effects';
import apiCall from '../../api';
import { CREATE_DRAWER_ERROR, CREATE_DRAWER_START, CREATE_DRAWER_SUCCESS } from "../../types";

export function* create({payload}) {
  try {
    const url = '/drawer/';
    const method = 'POST';
    const headers = {Authorization: 'Bearer ' + payload.token, 'Content-Type':'multipart/form-data'};
    const data = (object) => Object.keys(object).reduce((formData, key) => {
      formData.append(key, object[key]);
      return formData;
    }, new FormData());
    const results = yield call(apiCall, url, method, data(payload.formData), headers);
    yield put({type: CREATE_DRAWER_SUCCESS, results: results.data});
    yield put(payload.navigate('/app/content-management/manage-drawer'));
  } catch (error) {
    yield put({type: CREATE_DRAWER_ERROR, error});
  }
}

export default function* create_drawer() {
  yield takeLatest(CREATE_DRAWER_START, create);
}
