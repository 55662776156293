/** @jsxImportSource @emotion/react */
import React from 'react'
import {EventsForm, EventsPreview} from '../../../components/templates'
import * as styles from '../index.styles'
import Provider from '../../../context/EventsContext'
import { breadcrumbItems } from '../../utils'
import { Breadcrumb } from '../../../components/atoms'

const { index, contentManagement, manageEvents } = breadcrumbItems

const ManageNews: React.FC = () => {
  return (
    <Provider>
      <Breadcrumb items={[index, contentManagement, manageEvents]} />
      <div css={styles.root}>
        <div css={styles.main}>
          <EventsForm />
        </div>
        <div css={styles.right}>
          <EventsPreview />
        </div>
      </div>
    </Provider>
  )
}

export default ManageNews
