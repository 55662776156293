import { css, SerializedStyles } from "@emotion/react";
import { ThemeUI } from "../../../theme";

type Props = {
  theme: ThemeUI;
  style?: SerializedStyles;
}

export const root = ({ theme, style }: Props) => css`
  border-radius: 6px;
  background-color: ${theme.palette.white};
  ${style};
`