/** @jsxImportSource @emotion/react */
import React, { Fragment, useEffect } from 'react'
import { useNavigate } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { CreateSide, ManageTable } from '../../../components/templates'
import * as styles from '../index.styles'
import {columns} from "./mockData";
import { breadcrumbItems } from '../../utils'
import { Breadcrumb } from '../../../components/atoms'
import {growth_path} from "../../../redux/actions/growth-path/growth_path";

const { index, contentManagement, manageGrowthPath } = breadcrumbItems

const ManageGrowthPath: React.FC = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const token = useSelector((state: any) => state.login.access)
  const data_path = useSelector((state: any) => state.growth_path.data)

  useEffect(() => {
    dispatch(growth_path({ token }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Fragment>
      <Breadcrumb items={[index, contentManagement, manageGrowthPath]} />
      <div css={styles.root}>
        <div css={styles.main}>
          <ManageTable
            title="Camino de Crecimiento"
            subtitle="Camino de crecimiento publicados"
            columns={columns}
            data={data_path || []}
            cycle={[]}
            management={[]}
            stateCategory={true}
          />
        </div>
        <div css={styles.right}>
          <CreateSide
            title="Crear nueva camino de crecimiento"
            message="Massa vulputate id placerat vitae ornare a orci. Gravida augue viverra pretium adipiscing mauris."
            textButton="Crear nueva camino"
            onCreate={() => navigate('/app/content-management/create-growth-path')}
          />
        </div>
      </div>
    </Fragment>
  )
}

export default ManageGrowthPath
