import {UPDATE_GROUP_CYCLE_START, UPDATE_GROUP_CYCLE_SUCCESS, UPDATE_GROUP_CYCLE_ERROR} from "../../types";

export default function update_group_cycle(state, action) {
    switch (action.type) {
        case UPDATE_GROUP_CYCLE_START:
            return {
                ...state,
                data: [],
                loading: true,
                error: null
            };
        case UPDATE_GROUP_CYCLE_SUCCESS:
            return {
                ...state,
                data: action.results,
                loading: false,
                error: null
            };
        case UPDATE_GROUP_CYCLE_ERROR:
            return {
                ...state,
                data: [],
                loading: false,
                error: action.detail
            };
        default:
            return { ...state };
    }
}