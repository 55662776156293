import {takeLatest, put, call} from 'redux-saga/effects';
import apiCall from '../../api/apiGet';
import {GET_FCM_HISTORY_ERROR, GET_FCM_HISTORY_START, GET_FCM_HISTORY_SUCCESS} from "../../types";

export function* create({payload}) {
  try {
    const url = '/fcm_history/';
    const method = 'GET';
    const headers = {Authorization: 'Bearer ' + payload.token, 'Content-Type':'application/json'};
    const results = yield call(apiCall, url, method, headers);
    yield put({type: GET_FCM_HISTORY_SUCCESS, results: results.data});
  } catch (error) {
    yield put({type: GET_FCM_HISTORY_ERROR, error});
  }
}

export default function* fcm_history() {
  yield takeLatest(GET_FCM_HISTORY_START, create);
}
