/** @jsxImportSource @emotion/react */
import React, { Fragment, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { CreateSide, ManageTable } from '../../../components/templates'
import * as styles from '../index.styles'
import { columns } from './mockData'
import { breadcrumbItems } from '../../utils'
import { Breadcrumb } from '../../../components/atoms'
import { group_cycle } from "../../../redux/actions/group_cycle/group_cycle";

const { index, contentManagement, manageCycle } = breadcrumbItems

const ManageCycle: React.FC = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const token = useSelector((state: any) => state.login.access)
  const data_cycle = useSelector((state: any) => state.group_cycle.data)
  const location = useLocation();

  useEffect(() => {
    dispatch(group_cycle({ token }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  return (
    <Fragment>
      <Breadcrumb items={[index, contentManagement, manageCycle]} />
      <div css={styles.root}>
        <div css={styles.main}>
          <ManageTable
            title="Administrar Noticias por ciclo"
            subtitle="Grupo de noticias por ciclo publicados"
            columns={columns}
            data={data_cycle || []}
            cycle={[]}
            management={[]}
            stateCategory={false}
          />
        </div>
        <div css={styles.right}>
          <CreateSide
            title="Crear nueva grupo por ciclo"
            message="Massa vulputate id placerat vitae ornare a orci. Gravida augue viverra pretium adipiscing mauris."
            textButton="Crear nueva grupo por ciclo"
            onCreate={() => navigate('/app/content-management/create-group-n-cycle')}
          />
        </div>
      </div>
    </Fragment>
  )
}

export default ManageCycle
