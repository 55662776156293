import {CREATE_BANNER_ERROR, CREATE_BANNER_START, CREATE_BANNER_SUCCESS} from "../../types";

export default function create_banner(state, action) {
  switch (action.type) {
    case CREATE_BANNER_START:
      return {
        ...state,
        loading: true,
        error: null
      };
    case CREATE_BANNER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null
      };
    case CREATE_BANNER_ERROR:
      return {
        ...state,
        loading: false,
        error: action.detail
      };
    default:
      return {...state};
  }
}
