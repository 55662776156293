import {
  CREATE_EVENTS_ERROR,
  CREATE_EVENTS_SUCCESS,
  CREATE_EVENTS_START,
} from "../../types";

export default function create_banner(state, action) {
  switch (action.type) {
    case CREATE_EVENTS_START:
      return {
        ...state,
        loading: true,
        error: null
      };
    case CREATE_EVENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null
      };
    case CREATE_EVENTS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.detail
      };
    default:
      return { ...state };
  }
}
