/** @jsxImportSource @emotion/react */
import React from 'react'
import * as SwitchPrimitive from '@radix-ui/react-switch'
import * as styles from './index.styles'
import { useTheme } from '@emotion/react'
import { ThemeUI } from '../../../theme'
import { Text } from '..'

export type Props = {
  label?: string
  name?: string
  disabled?: boolean
  checked?: boolean
  onCheckedChange: (checked: boolean) => void
}

const SwitchDemo: React.FC<Props> = (props) => {
  const theme = useTheme() as ThemeUI
  return (
    <div css={styles.root(theme)}>
      <SwitchPrimitive.Root {...props} css={styles.container(theme)}>
        <SwitchPrimitive.Thumb css={styles.thumb(theme)} />
      </SwitchPrimitive.Root>
      <Text textType="label">{props.label}</Text>
    </div>
  )
}

export default SwitchDemo
