import { css } from "@emotion/react";

export const root = css`
  padding: 18px;
  display: flex;
  justify-content: space-between;
`

export const left = css`
  display: flex;
  flex-direction: column;
  padding-top: 18px;
  padding-bottom: 18px;
  justify-content: space-between;
  & > div {
    &:nth-of-type(2) {
      display: flex;
      padding: 10px;
      white-space: nowrap;
      & > div:nth-of-type(1) {
        margin-right: 10px;
      }
    }
  }
`