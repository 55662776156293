export const columns = [
    {
        Header: 'Titulo',
        accessor: 'title',
    },
    {
        Header: 'Descripción',
        accessor: 'description',
    },
    {
        Header: 'Imagen',
        accessor: 'image',
    },
    {
        Header: 'Imagen descripción',
        accessor: 'image_description',
    },
    {
        Header: 'Editar',
        accessor: 'editCycle',
    },
    {
        Header: 'Eliminar',
        accessor: 'deleteCycle',
    },
    {
        Header: 'Estado',
        accessor: 'active',
    },
]
