import { css } from '@emotion/react'
import { ThemeUI } from '../../../theme'
import { StyleProps } from './types'

type Props = { theme: ThemeUI, hasPath?: boolean } & StyleProps

export const root = (props: Props) => css`
  display: flex;
  align-items: center;
  margin-left: 24px;
  margin-right: 24px;
`

export const link = (props: Props) => css`
  text-decoration: none;
  margin-right: 10px;
  color: ${props.theme.palette.text};
  cursor: default;
  ${props.hasPath && `
    cursor: pointer;
    &:hover {
      color: ${props.theme.palette.primary};
    }
  `
  }
`

export const separator = (props: Props) => css`
  margin-right: 10px;
`
