import {takeLatest, put, call} from 'redux-saga/effects';
import { GET_DRAWER_ERROR, GET_DRAWER_START, GET_DRAWER_SUCCESS } from "../../types";

import apiCall from '../../api/apiGet';

export function* getDrawer({payload}) {
  try {
    const url = '/drawer/';
    const method = 'GET';
    const headers = {Authorization: 'Bearer ' + payload.token};
    const results = yield call(apiCall, url, method, headers);
    yield put({type: GET_DRAWER_SUCCESS, results: results.data});
  } catch (error) {
    yield put({type: GET_DRAWER_ERROR, error});
  }
}

export default function* drawer() {
  yield takeLatest(GET_DRAWER_START, getDrawer);
}
