import {DELETE_NEW_CYCLE_ERROR, DELETE_NEW_CYCLE_START, DELETE_NEW_CYCLE_SUCCESS} from "../../types";

export default function delete_new_cycle(state, action) {
    switch (action.type) {
        case DELETE_NEW_CYCLE_START:
            return {
                ...state,
                loading: true,
                error: null
            };
        case DELETE_NEW_CYCLE_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null
            };
        case DELETE_NEW_CYCLE_ERROR:
            return {
                ...state,
                loading: false,
                error: action.detail
            };
        default:
            return {...state};
    }
}
