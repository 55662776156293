import {takeLatest, put, call} from 'redux-saga/effects';
import apiCall from '../../api';
import {DELETE_OFFERS_ERROR, DELETE_OFFERS_START, DELETE_OFFERS_SUCCESS} from "../../types";

export function* deleteO({payload}) {
    try {
        const url = `/offers_by_cycle/${payload.id}/`;
        const method = 'DELETE';
        const headers = {Authorization: 'Bearer ' + payload.token, 'Content-Type':'application/json'};
        const results = yield call(apiCall, url, method, payload.formData, headers);
        yield put({type: DELETE_OFFERS_SUCCESS, results: results.data});
        yield put(payload.navigate('/app/content-management/manage-offers-by-cycle'))
    } catch (error) {
        yield put({type: DELETE_OFFERS_ERROR, error});
    }
}

export default function* delete_offers() {
    yield takeLatest(DELETE_OFFERS_START, deleteO);
}