import {takeLatest, put, call} from 'redux-saga/effects';
import apiCall from '../../api';
import {UPDATE_BANNER_ERROR, UPDATE_BANNER_START, UPDATE_BANNER_SUCCESS} from "../../types";

export function* update({payload}) {
    try {
        const url = `/banners/${payload.formData.id}/`;
        const method = 'PUT';
        const headers = {Authorization: 'Bearer ' + payload.token, 'Content-Type':'multipart/form-data'};
        const data = (object) => Object.keys(object).reduce((formData, key) => {
            formData.append(key, object[key]);
            return formData;
        }, new FormData());
        const results = yield call(apiCall, url, method, data(payload.formData), headers);
        yield put({type: UPDATE_BANNER_SUCCESS, results: results.data});
        yield put(payload.navigate('/app/content-management/manage-banners'));
    } catch (error) {
        yield put({type: UPDATE_BANNER_ERROR, error});
    }
}

export default function* update_banner() {
    yield takeLatest(UPDATE_BANNER_START, update);
}