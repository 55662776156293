export const columns = [
  {
    Header: 'Ciclo',
    accessor: 'cycle',
  },
  {
    Header: 'Nivel',
    accessor: 'category',
  },
  {
    Header: 'Agregar noticias ciclo',
    accessor: 'idNCycle',
  },
  {
    Header: 'Ver grupo',
    accessor: 'watchCycle',
  },
  {
    Header: 'Estado',
    accessor: 'active',
  },
]
