import {takeLatest, put, call} from 'redux-saga/effects';
import apiCall from '../../api/apiGet';
import {GET_EVENTS_ERROR, GET_EVENTS_START, GET_EVENTS_SUCCESS } from "../../types";

export function* getEvent({payload}) {
  try {
    const url = '/events/';
    const method = 'GET';
    const headers = {Authorization: 'Bearer ' + payload.token};
    const results = yield call(apiCall, url, method, headers);
    yield put({type: GET_EVENTS_SUCCESS, results: results.data});
  } catch (error) {
    yield put({type: GET_EVENTS_ERROR, error});
  }
}

export default function* events() {
  yield takeLatest(GET_EVENTS_START, getEvent);
}
