import {takeLatest, put, call} from 'redux-saga/effects';
import apiCall from '../../api';
import {UPDATE_EVENTS_ERROR, UPDATE_EVENTS_START, UPDATE_EVENTS_SUCCESS} from "../../types";

export function* update({payload}) {
    try {
        const url = `/events/${payload.formData.id}/`;
        const method = 'PUT';
        delete payload.formData.id
        const headers = { Authorization: 'Bearer ' + payload.token, 'Content-Type': 'multipart/form-data' };
        const data = (object) => Object.keys(object).reduce((formData, key) => {
            if (key !== 'id') {
                formData.append(key, object[key]);
            }
            return formData;
        }, new FormData());
        const results = yield call(apiCall, url, method, data(payload.formData), headers);
        yield put({type: UPDATE_EVENTS_SUCCESS, results: results.data});
        yield put(payload.navigate('/app/content-management/manage-events'));
    } catch (error) {
        yield put({type: UPDATE_EVENTS_ERROR, error});
    }
}

export default function* update_events() {
    yield takeLatest(UPDATE_EVENTS_START, update);
}
