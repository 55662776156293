import {takeLatest, put, call} from 'redux-saga/effects';
import apiCall from '../../api';
import {UPDATE_NOTIFICATION_ERROR, UPDATE_NOTIFICATION_START, UPDATE_NOTIFICATION_SUCCESS} from "../../types";
import moment from "moment";

export function* update({payload}) {
    try {
        const url = `/notifications/${payload.formData.id}/`;
        const method = 'PUT';
        const headers = {Authorization: 'Bearer ' + payload.token, 'Content-Type':'multipart/form-data'};
        const data = (object) => Object.keys(object).reduce((formData, key) => {
            if(key === 'date_initial') {
                let initial = moment(object[key]).format('YYYY-MM-DD');
                formData.append(key, initial);
            } else if (key === 'date_ending') {
                let ending = moment(object[key]).format('YYYY-MM-DD');
                formData.append(key, ending);
            } else {
                formData.append(key, object[key]);
            }
            return formData;
        }, new FormData());
        const results = yield call(apiCall, url, method, data(payload.formData), headers);
        yield put({type: UPDATE_NOTIFICATION_SUCCESS, results: results.data});
        yield put(payload.navigate('/app/content-management/manage-notifications'));
    } catch (error) {
        yield put({type: UPDATE_NOTIFICATION_ERROR, error});
    }
}

export default function* update_notification() {
    yield takeLatest(UPDATE_NOTIFICATION_START, update);
}
