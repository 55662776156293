export const columns = [
  {
    Header: 'Titulo',
    accessor: 'title',
  },
  {
    Header: 'Contenido',
    accessor: 'description',
  },
  {
    Header: 'Ciclo',
    accessor: 'cycle',
  },
  {
    Header: 'Nivel',
    accessor: 'category',
  },
  {
    Header: 'Gerencia',
    accessor: 'managementCad',
  },
  {
    Header: 'Imagen',
    accessor: 'cover',
  },
  {
    Header: 'Estado',
    accessor: 'active',
  },
  {
    Header: 'Agregar Post',
    accessor: 'id',
  },
  {
    Header: 'Ver grupo',
    accessor: 'watchPosts',
  },
]