export const columns = [
  {
    Header: 'Titulo',
    accessor: 'title',
  },
  {
    Header: 'Contenido',
    accessor: 'description',
  },
  {
    Header: 'Ciclo',
    accessor: 'cycle',
  },
  {
    Header: 'Nivel',
    accessor: 'category',
  },
  {
    Header: 'Gerencia',
    accessor: 'management',
  },
  {
    Header: 'Enlace',
    accessor: 'url',
  },
  {
    Header: 'Fecha',
    accessor: 'date',
  },
  {
    Header: 'Hora de inicio',
    accessor: 'hour_init',
  },
  {
    Header: 'Hora de fin',
    accessor: 'hour_fin',
  },
  {
    Header: 'Editar',
    accessor: 'editEvent',
  },
  {
    Header: 'Eliminar',
    accessor: 'deleteEvent',
  },
  {
    Header: 'Estado',
    accessor: 'active',
  },
]