import { css } from "@emotion/react";

export const root = css`
  display: flex;
  justify-content: stretch;
  align-items: start;
  padding: 24px;
`

export const main = css`
  flex-grow: 1;
  display: grid;
  gap: 12px;
`
  
  export const right = css`
  margin-left: 12px;
  width: 350px;
`
  export const imageCell = css`
    height: 25px;
`