/** @jsxImportSource @emotion/react */
import React, { Fragment, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { CreateSide, ManageTable } from '../../../components/templates'
import * as styles from '../index.styles'
import { columns } from './mockData'
import { breadcrumbItems } from '../../utils'
import { Breadcrumb } from '../../../components/atoms'
import { group_posts } from '../../../redux/actions/post/group_posts'

const { index, contentManagement, managePost } = breadcrumbItems

const ManagePost: React.FC = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const token = useSelector((state: any) => state.login.access)
  const group_data = useSelector((state: any) => state.group_posts)
  const management = useSelector((state: any) => state.management)
  const location = useLocation();
  const [formatData, setFormData] = useState(null);

  useEffect(() => {
    dispatch(group_posts({ token }))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(group_posts({ token }))
    setTimeout(() => { 
      if (group_data?.data.length > 0) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        setFormData(group_data.data?.map((item: any) => ({
          ...item,
          managementCad: item.management.toString(),
          postsCad: item.posts.toString(),
        })))
      } else {
        dispatch(group_posts({ token }))
        setTimeout(() => {
          setFormData(group_data.data?.map((item: any) => ({
            ...item,
            managementCad: item.management.toString(),
            postsCad: item.posts.toString(),
          })))
        }, 2500)
      }
    }, 2000)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const cycleData = useSelector((state: any) => {
    return state.group_posts_cycle.data?.map((v:any) => {
      return { value: v.cycle, label: v.cycle }
    })
  })
  cycleData.push({ value: 'Ninguno', label: 'Ninguno'})

  const managementData = management.data?.map((v:any) => {
    return { value: v.id, label: v.management }
  })
  managementData.push({ value: 'Ninguno', label: 'Ninguno'})

  // const formatData = group_data.data?.map((item: any) => ({
  //   ...item,
  //   managementCad: item.management.toString(),
  //   postsCad: item.posts.toString(),
  // }))

  return (
    <Fragment>
      <Breadcrumb items={[index, contentManagement, managePost]} />
      <div css={styles.root}>
        <div css={styles.main}>
          <ManageTable
            title="Administrar grupo de posteos"
            subtitle="Posteos publicados"
            columns={columns}
            data={formatData || []}
            cycle={cycleData || []}
            management={managementData || []}
            stateCategory={true}
          />
        </div>
        <div css={styles.right}>
          <CreateSide
            title="Crear grupo de posteo"
            message="Massa vulputate id placerat vitae ornare a orci. Gravida augue viverra pretium adipiscing mauris."
            textButton="Crear grupo de posteo"
            onCreate={() => navigate('/app/content-management/create-group-post')}
          />
        </div>
      </div>
    </Fragment>
  )
}

export default ManagePost
