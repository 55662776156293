/** @jsxImportSource @emotion/react */
import React, {useState, useEffect} from 'react'
import {Column} from 'react-table'
import {Text, Button, BaseInput} from '../../atoms'
import { TableBase } from '../../molecules'
import { ContentSection } from '../../organisms'
import * as styles from "../../molecules/TableBase/index.styles";
import {css} from "@emotion/react";

type Props = {
  title: string,
  subtitle: string,
  columns: Column<any>[],
  data: any[],
}

const ManageTableUsers: React.FC<Props> = ({title, subtitle, columns, data}) => {
  const [page, setPage] = useState(1);
  //const [size, setSize] = useState(10);

  const [filter, setFilter] = useState<string>('');

  const size = 10;
  const [total, setTotal] = useState(data.length);
  const [filters, setFilters] = useState<{key: string, value: string}[]>([]);
  const [rows, setRows] = useState(data.slice((page - 1) * size, (page * size)))

  const changeToPage = (page: number) => {
    setPage(page)
    setRows(data.slice((page - 1) * size, (page * size)))
  }

  const filterHandler = (key:any , value: string) => {
    if(key !== null) {
      const index = filters.findIndex((f:any) => f.key === key);
      if(index === -1) {
        setFilters((prev:any) => {
          prev.push({key, value})
          return prev
        })
      } else {
        setFilters(prev => {
          prev[index] = {key, value}
          return prev
        })
      }

      if(value === 'Ninguno') {
        setFilters(prev => {
          prev.splice(index, 1)
          return prev
        })
      }
    }

    let aux = data
    filters.forEach((filter:any) => {
      aux = aux.filter(row => {
        if (row[filter.key].length > 0) {
          return row[filter.key].indexOf(filter.value) > -1
        } else {
          return row[filter.key] === (filter.value)
        }
      })
    })

    if(key === null) {
      aux = globalFilter(aux, value)
    }

    setPage(1)
    setRows(getRowsByPage(aux, page))
    setTotal(aux.length)
  }

  const globalFilter = (rows:any[], text:string): any[] => {
    return rows.filter(row => {
      let sw = false;
      (Object.values(row)).forEach((v:any) => (v + '').toLowerCase().indexOf(text.toLowerCase()) > -1 ?  sw = true : null)
      return sw;
    })
  }

  const getRowsByPage = (rows:any, page:number) => {
    return rows.slice((page - 1) * size, (page * size))
  }

  const getPager = (total:number): number => {
    return Math.ceil(total / size)
  }

  useEffect(() => {
    setRows(data.slice((page - 1)*size, (page * size)))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
      <ContentSection style={css`padding: ${title === '' ? '0 0 0 0' : ''}`}
          header={
            <React.Fragment >
              <Text textType='h4' weight='black'>{title}</Text>
              <Text textType='p' weight='bold'>{subtitle}</Text>
            </React.Fragment>
          }
      >
        <div css={styles.actions}>
          <BaseInput style={css`max-width: 350px;self-justify: flex-end;display: ${title === '' ? 'none' : 'block'}`}
              name="search"
              value={filter}
              placeholder="Buscar"
              onChange={(event:any) => {
                setFilter(event)
                filterHandler(null, event)
              }}
          />
        </div>

        <TableBase columns={columns} data={rows}/>

        <div css={styles.actionsRigth}>
          <Button style={css({ width: '55px', margin: '0 0 0 5px' })} disabled={page === 1} onClick={() => changeToPage(1)}> {'<<'} </Button>
          <Button style={css({ width: '55px', margin: '0 0 0 5px' })} disabled={page === 1} onClick={() => changeToPage(page - 1)}>
            {'<'}
          </Button>
          <Button style={css({ width: '55px', margin: '0 0 0 5px' })} onClick={() => changeToPage(page + 1)} disabled={page >= getPager(total)}>
            {'>'}
          </Button>
          <Button style={css({ width: '55px', margin: '0 0 0 5px' })} disabled={page >= getPager(total)} onClick={() => changeToPage(getPager(total))}>
            {'>>'}
          </Button>
        </div>

        <div css={styles.actions}>
          <p>Pagina {page} de {getPager(total)}</p>
          <p>Total item {total}</p>
        </div>


      </ContentSection>
  )
}

export default ManageTableUsers

