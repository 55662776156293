import {GET_ALL_BANNER_ERROR, GET_ALL_BANNER_START, GET_ALL_BANNER_SUCCESS} from "../../types";

export default function banners(state, action) {
  switch (action.type) {
    case GET_ALL_BANNER_START:
      return {
        ...state,
        data: [],
        loading: true,
        error: null
      };
    case GET_ALL_BANNER_SUCCESS:
      return {
        ...state,
        data: action.results,
        loading: false,
        error: null
      };
    case GET_ALL_BANNER_ERROR:
      return {
        ...state,
        data: [],
        loading: false,
        error: action.detail
      };
    default:
      return { ...state };
  }
}
