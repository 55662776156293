/** @jsxImportSource @emotion/react */
import React from 'react'
import { Text } from '../../atoms'
import * as styles from './index.styles'
import BrainIcon from './BrainIcon'

const Footer: React.FC = () => {
  const goToUrl = () => {
    window.open('https://soydigital.tech/', '_blank');
  }

  return (
    <div css={styles.root}>
      <Text textType='p' >{new Date().getFullYear()} © <div style={{display: 'inline-block',cursor: 'pointer', margin: '0'}} onClick={goToUrl}>Soy Digital.</div> Made with </Text>
      <BrainIcon />
    </div>
  )
}

export default Footer
