/** @jsxImportSource @emotion/react */
import React, {useEffect, useState} from 'react'
import {CreateSide, ManageTable, Modal} from '../../../components/templates'
import * as styles from '../index.styles'
import Provider from '../../../context/WatchGroupNewsContext'
import { breadcrumbItems } from '../../utils'
import { Breadcrumb } from '../../../components/atoms'
import {useDispatch, useSelector} from "react-redux";
import {group_detail} from "../../../redux/actions/news/group_detail";
import {useParams} from "react-router-dom";
import {columns} from "./mockData";
import {useNavigate} from "react-router";
import {delete_group_news} from "../../../redux/actions/news/delete_group_news";

const { index, contentManagement, manageNews } = breadcrumbItems

const ManageNews: React.FC = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  let { id } = useParams();
  const token = useSelector((state: any) => state.login.access)
  const [show, setShow] = useState(false)
  const [modalData, setModalData] = useState({title: '', text: '', success: '', warning: ''})

  const detail_news = useSelector((state: any) => state.group_detail.data)

  useEffect(() => {
    if (!!id) {
      dispatch(group_detail({ token, id }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  const handleGroupDelete = (id: any) => {
    if (detail_news.news !== undefined) {
      if (detail_news?.news.length > 0) {
        setModalData({title: 'Tienes datos de noticias!', text: 'No puedes eliminar un grupo si cuenta con noticias, primero elimina tus noticias.', success: 'Aceptar', warning: ''})
      } else {
        setModalData({title: '¿Esta seguro que desea eliminar?', text: '', success: 'Cancelar', warning: 'Eliminar'})
      }
    } else {
      setModalData({title: '¿Esta seguro que desea eliminar?', text: '', success: 'Cancelar', warning: 'Eliminar'})
    }
    setShow(true)
  }
  const actionDelete = (id: any) => {
    dispatch(delete_group_news({ token, id }))
    navigate('/app/content-management/manage-group-news')
    setShow(false)
  }

  return (
    <Provider>
      <Breadcrumb items={[index, contentManagement, manageNews]} />
      <Modal
          show={show}
          text={modalData.text}
          title={modalData.title}
          btnSuccess={modalData.success}
          btnWarning={modalData.warning}
          onClickWarning={() => {actionDelete(id)}}
          onClickSuccess={() => {setShow(false)}}
          onClickClose={() => {setShow(false)}}
      />
      <div css={styles.root}>
        <div css={styles.main}>
          <ManageTable
              title={ detail_news?.title}
              subtitle={detail_news?.description}
              columns={columns}
              data={ detail_news?.news || []}
              cycle={[]}
              management={[]}
              stateCategory={false}
          />
        </div>
        <div css={styles.right}>
          <CreateSide
              title={'Editar grupo de noticias'}
              message="Massa vulputate id placerat vitae ornare a orci. Gravida augue viverra pretium adipiscing mauris."
              textButton={'Editar grupo de noticias'}
              onCreate={() => navigate(`/app/content-management/edit-group-new/${id}`)}
          />
          <CreateSide
              title={'Eliminar grupo de noticias'}
              message="Massa vulputate id placerat vitae ornare a orci. Gravida augue viverra pretium adipiscing mauris."
              textButton={'Eliminar grupo de noticias'}
              onCreate={() => handleGroupDelete(id)}
          />
        </div>
      </div>
    </Provider>
  )
}

export default ManageNews
