/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react'
import React, { useContext } from 'react'
import { ThemeUI } from '../../../theme'
import { Button, Card, Text } from '../../atoms'
import { ContentSection } from '../../organisms'
import * as styles from './index.styles'
import { Context } from '../../../context/ManagementContext'
import { useDispatch, useSelector } from 'react-redux'
import { create_management } from '../../../redux/actions/management/create_management'
import { update_management } from "../../../redux/actions/management/update_management";
import { useNavigate, useParams } from 'react-router-dom'

const NewPreview: React.FC = () => {
    const theme = useTheme() as ThemeUI
    const navigate = useNavigate()
    const { formData } = useContext(Context)
    const dispatch = useDispatch()
    const token = useSelector((state: any) => state.login.access)
    let { id } = useParams();
    const handleSubmit = (token: any, formData: any) => {
        if (!!id) {
            dispatch(update_management({ token, formData, navigate }))
        } else {
            dispatch(create_management({ token, formData, navigate }))
        }
    }

    return (
        <ContentSection
            style={styles.root}
            header={
                <Text textType="p" weight="bold">
                    Vista Previa
                </Text>
            }
        >
            <div css={(theme: any) => styles.preview(theme)}>
                <Card style={css({ overflow: 'hidden' })}>
                    <div css={styles.previewDescription(theme)}>
                        <Text textType="sm" weight="bold">
                            {formData.id}
                        </Text>
                        <Text textType="sm" weight="light">
                            {formData.management}
                        </Text>
                        <Text textType="sm" weight="light">
                            {formData.region}
                        </Text>
                        <Text textType="sm" weight="light">
                            {formData.sector}
                        </Text>
                    </div>
                </Card>
            </div>
            <Button onClick={() => handleSubmit(token, formData)}
                    disabled={(!formData.id || !formData.management || !formData.region || !formData.sector)}>
                {id ? 'Guardar':'Publicar'} gerencia
            </Button>
        </ContentSection>
    )
}

export default NewPreview