import {takeLatest, put, call} from 'redux-saga/effects';
import apiCall from '../api';
import { REMOVE_POSTS_ERROR, REMOVE_POSTS_START, REMOVE_POSTS_SUCCESS } from '../types';

export function* remove({payload}) {
    try {
        const url = `/posts/${payload.id}/`;
        const method = 'DELETE';
        const headers = {Authorization: 'Bearer ' + payload.token, 'Content-Type':'application/json'};
        const results = yield call(apiCall, url, method, payload.formData, headers);
        yield put({type: REMOVE_POSTS_SUCCESS, results: results.data});
    } catch (error) {
        yield put({type: REMOVE_POSTS_ERROR, error});
    }
}

export default function* remove_posts() {
    yield takeLatest(REMOVE_POSTS_START, remove);
}