/** @jsxImportSource @emotion/react */
import React, { ReactNode } from 'react'
import * as styles from './index.styles'
import { SerializedStyles } from '@emotion/react'

type Props = {
  children: ReactNode,
  style?: SerializedStyles,
}

const Card: React.FC<Props> = ({ children, style }) => {
  return (
    <div css={(theme: any) => styles.root({ theme, style })}>{children}</div>
  )
}
export default Card
