import * as React from "react"

export default React.memo((props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={12}
    height={14}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.9 7.837l-.8-.9c.2-.1.4-.3.6-.4.4-.4.4-1 0-1.4-.2-.1-.5-.2-.7-.2-.3 0-.5.1-.7.3-.1.1-.3.3-.4.5l-.9-.8-2.9-2.9c0-.1-.2-.1-.2 0l-.9.8c-.1-.2-.3-.4-.4-.6-.2-.1-.4-.2-.7-.2-.3 0-.5.1-.6.2-.2.2-.3.5-.3.7 0 .3.1.5.3.7.1.1.3.3.5.4l-.8.9-1 1s-.1.1-.1.2 0 .1.1.1c.3.1.6.3.7.4.2.2.2.6 0 .9-.3.2-.7.2-.9 0-.1-.1-.3-.4-.4-.7 0-.1-.1-.1-.1-.1-.1 0-.1 0-.2.1l-1 1c-.1 0-.1.1-.1.1s0 .1.1.1l.8.9c-.2.1-.4.3-.5.4-.2.1-.3.4-.3.7 0 .3.1.5.3.7.1.1.4.2.6.2.3 0 .5-.1.7-.3.1-.1.3-.3.4-.5l.9.8.1.1s.1 0 .1-.1l2.8-2.7.7.7c-.2.1-.4.3-.5.4-.2.1-.3.4-.3.7 0 .3.1.5.3.7.2.1.4.2.7.2.3 0 .5-.1.7-.3.1-.1.3-.3.4-.6l.8.9.1.1c.1 0 .1 0 .1-.1l2.9-2.9c.1 0 .1-.1.1-.1s0-.1-.1-.1zm-7.7-3.7s.1-.1.1-.2 0-.1-.1-.1c-.3-.2-.6-.3-.7-.4-.1-.2-.2-.3-.2-.5s.1-.3.2-.4c.2-.2.6-.2.9 0 .1.1.3.4.4.7l.1.1c.1 0 .1 0 .2-.1l.9-.9 2.7 2.7-.9.8s-.1.1-.1.2 0 .1.1.1c.3.1.6.3.7.4.2.2.2.6 0 .9-.2.2-.6.2-.9 0-.1 0-.3-.3-.4-.6 0-.1-.1-.1-.1-.1s-.1 0-.1.1h-.2l-.8.8-.7-.7c.2-.1.4-.3.6-.4.4-.4.4-1 0-1.4-.4-.4-1-.4-1.4 0-.1.2-.3.4-.4.6l-.8-.7.9-.9zm-1.1 6.5l-.9-1s-.1-.1-.2-.1-.1 0-.1.1c-.1.4-.3.7-.4.8-.2.2-.6.2-.9 0-.1-.1-.2-.3-.2-.4 0-.2.1-.3.2-.4.1-.2.4-.4.7-.5.1 0 .1-.1.1-.1 0-.1 0-.1-.1-.2l-.9-.9.7-.7c.1.2.3.4.4.6.4.4 1 .4 1.4 0 .4-.4.4-1 0-1.4-.1-.2-.3-.3-.5-.4l.7-.7.8.8H4s.1.1.2.1.1 0 .1-.1c.1-.3.3-.6.4-.7.2-.2.6-.2.9 0 .2.2.2.6 0 .9-.1.1-.4.3-.7.4-.1 0-.1.1-.1.1 0 .1 0 .1.1.2l.8.9-2.6 2.7zm5.8 0l-.9-1s-.1-.1-.2-.1-.1 0-.1.1c-.1.4-.3.7-.4.8-.2.2-.6.2-.9 0-.1-.1-.2-.3-.2-.4 0-.2.1-.3.2-.4.1-.1.4-.3.7-.4.1 0 .1-.1.1-.1 0-.1 0-.1-.1-.2l-.8-1 .7-.7c.1.2.3.4.4.6.2.1.4.2.7.2.3 0 .5-.1.7-.3.4-.4.4-1 0-1.4l-.6-.3.7-.7.9.9s.1.1.2.1.1 0 .1-.1c.1-.3.3-.6.4-.7.2-.2.6-.2.9 0 .2.2.2.6 0 .9-.1.1-.4.3-.7.4-.1 0-.1.1-.1.1 0 .1 0 .1.1.2l.9.8-2.7 2.7z"
    />
  </svg>
))
