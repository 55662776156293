/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import React, {FC, useContext, useEffect, useState} from 'react'
import DateTimePicker from 'react-datetime-picker'
import DatePicker from 'react-date-picker'

import { Context } from '../../../context/EventsContext'
import { BaseInput, SwitchInput, Text, TextareaInput, FileInput } from '../../atoms'
import { ContentSection } from '../../organisms'
import Select from "react-select";
import { CATEGORIES } from '../../../category'
import {useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import * as styles from "../Header/index.styles";
import moment from "moment";

const validateNumber = (data: string) =>
    /^[0-9]+$/.test(data)

const NewForm: FC = () => {
  const { formData, handleChangeFormData: handleChange, handleUpdateFormData: handleData } = useContext(Context)
  let { id } = useParams()
  const event_data = useSelector((state: any) => {
    return state.events.data.find((r: any) => parseInt(id+'') === r.id)
  })
  const managements_list = useSelector((state: any) => state.management.data)

  const categoryInit = event_data?.category ? { value: event_data.category, label: event_data.category } : { value: '', label: '' }
  const managementInit = event_data?.management ? managements_list.filter((r:any) => event_data?.management.indexOf(r.id) > -1) : null

  useEffect(() => {
    if (!!id) {
      handleData(event_data)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, event_data])

  const handleCategory = (e: any) => {
    handleChange({ category: e.value })
  }

  const handleManagement = (e: any) => {
    let temp: any[] = []
    e.forEach((item: { id: any }, index: any) => {
      temp.push(item.id)
    })
    handleChange({ management: temp })
  }

  const [isTitleValid, setTitleValid] = useState<boolean>(false)
  const [isDescriptionValid, setIsDescriptionValid] = useState<boolean>(false)
  const [isUrlValid, setIsUrlValid] = useState<boolean>(false)
  const [isCycleValid, setIsCycleValid] = useState<boolean>(false)

  useEffect(() => {
    setTitleValid(formData.title.length > 2)
  }, [formData.title])
  useEffect(() => {
    setIsDescriptionValid(formData.description.length > 2)
  }, [formData.description])
  useEffect(() => {
    setIsUrlValid(formData.url.length > 10)
  }, [formData.url])
  useEffect(() => {
    setIsCycleValid(validateNumber(formData.cycle))
  }, [formData.cycle])

  return (
    <ContentSection style={css`display: grid;gap: 18px;`}
      header={
        <React.Fragment>
          <Text textType="h4" weight="black">
            {id ? 'Editar' : 'Nuevo'} evento
          </Text>
        </React.Fragment>
      }
    >
      <BaseInput
        label="Título"
        value={formData.title}
        onChange={(value) => handleChange({ title: value })}
        placeholder="Escribe el titulo de tu entrada"
        maxLength={500}
        error={!isTitleValid && formData.title.length > 0}
        success={isTitleValid}
        hint={!isTitleValid && formData.title.length > 0 ? 'Digite mas de dos letras' : ''}
      />
      <TextareaInput
        label="Contenido"
        value={formData.description}
        onChange={(value) => handleChange({ description: value })}
        placeholder="Escribe el contenido de tu entrada"
        maxLength={1500}
        error={!isDescriptionValid && formData.description.length > 0}
        success={isDescriptionValid}
        hint={!isDescriptionValid && formData.description.length > 0 ? 'Digite mas de dos letras' : ''}
      />
      <Text textType="label">
        FECHA:{' '} <br />
        <DatePicker css={styles.input}
          value={moment(formData.date).utc().toDate()}
          onChange={(value: Date) =>
            handleChange({
              hour_end: value,
              hour_start: value,
              date: value,
            })
          }
        />
      </Text>
      <Text textType="label">
        HORA DE INICIO :{' '} <br/>
        <DateTimePicker css={styles.input}
          value={new Date(formData.hour_start)}
          onChange={(value: Date) => handleChange({ hour_start: value })}
        />
      </Text>
      <Text textType="label">
        HORA DE FINALIZACIÓN :{' '} <br/>
        <DateTimePicker css={styles.input}
          value={new Date(formData.hour_end)}
          onChange={(value: Date) => handleChange({ hour_end: value })}
        />
      </Text>
      <BaseInput
        label="Enlace del evento"
        value={formData.url}
        onChange={(value) => handleChange({ url: value })}
        placeholder="Escribe el enlace del evento"
        maxLength={1500}
        error={!isUrlValid && formData.url.length > 0}
        success={isUrlValid}
        hint={!isUrlValid && formData.url.length > 0 ? 'Introduce una url Ej.:https://www.google.com/' : ''}
      />
      <Text textType="label">
        CATEGORÍA :{' '}
        <Select css={styles.input}
                options={CATEGORIES}
                placeholder="Selecciona una categoría"
                defaultValue={categoryInit}
                onChange={(e) =>  handleCategory(e)}
        />
      </Text>
      <Text textType="label">
        GERENCIA :{' '}
        <Select css={styles.input}
          isMulti placeholder="Selecciona opciones de gerencia"
          options={managements_list}
          defaultValue={managementInit}
          getOptionValue={(option: any) => `${option['id']}`}
          getOptionLabel={(option: any) => `${option['management']}`}
          onChange={(e) =>  handleManagement(e)}
        />
      </Text>
      <BaseInput
        label="Ciclo"
        placeholder="Escribe un código de  ciclo"
        value={formData.cycle}
        onChange={(value) => handleChange({ cycle: value })}
        maxLength={30}
        error={!isCycleValid && formData.cycle.length > 0}
        success={isCycleValid}
        hint={!isCycleValid && formData.cycle.length > 0 ? 'Digite numeros' : ''}
      />
      <div style={{ width: 360 }}>
        <FileInput
          label='Imagen'
          value={formData.image}
          onChange={(value) => handleChange({ image: value })}
          accept='image/*'
        />
      </div>

      <SwitchInput checked={formData.active}
                   onCheckedChange={(value) => handleChange({ active: value })}
                   label={formData.active ? 'Activo': 'Inactivo'}
      />
    </ContentSection>
  )
}

export default NewForm
