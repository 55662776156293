import {GET_GROWTH_PATH_ERROR, GET_GROWTH_PATH_START, GET_GROWTH_PATH_SUCCESS} from "../../types";

export default function growth_path(state, action) {
  switch (action.type) {
    case GET_GROWTH_PATH_START:
      return {
        ...state,
        data: [],
        loading: true,
        error: null
      };
    case GET_GROWTH_PATH_SUCCESS:
      return {
        ...state,
        data: action.results,
        loading: false,
        error: null
      };
    case GET_GROWTH_PATH_ERROR:
      return {
        ...state,
        data: [],
        loading: false,
        error: action.detail
      };
    default:
      return { ...state };
  }
}
