import { DELETE_DRAWER_ERROR, DELETE_DRAWER_START, DELETE_DRAWER_SUCCESS } from "../../types";

export default function delete_drawer(state, action) {
    switch (action.type) {
        case DELETE_DRAWER_START:
            return {
                ...state,
                loading: true,
                error: null
            };
        case DELETE_DRAWER_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null
            };
        case DELETE_DRAWER_ERROR:
            return {
                ...state,
                loading: false,
                error: action.detail
            };
        default:
            return {...state};
    }
}
