import { GET_GROUP_POST_CYCLE_ERROR, GET_GROUP_POST_CYCLE_START, GET_GROUP_POST_CYCLE_SUCCESS } from "../../types";

export default function group_posts_cycle(state, action) {
    switch (action.type) {
        case GET_GROUP_POST_CYCLE_START:
            return {
                ...state,
                data: [],
                loading: true,
                error: null
            };
        case GET_GROUP_POST_CYCLE_SUCCESS:
            return {
                ...state,
                data: action.results,
                loading: false,
                error: null
            };
        case GET_GROUP_POST_CYCLE_ERROR:
            return {
                ...state,
                data: [],
                loading: false,
                error: action.detail
            };
        default:
            return { ...state };
    }
}