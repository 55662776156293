/** @jsxImportSource @emotion/react */
import React, { ChangeEvent } from 'react'
import { css } from '@emotion/react'
import { Button } from '..'
import { Props } from './types'
import * as styles from './index.styles'

const FileInput: React.FC<Props> = ({ label, hint, value, style, ...props }) => {
  const inputRef = React.createRef<HTMLInputElement>()
  const handleSelectedFiles = (event: ChangeEvent<HTMLInputElement>) => {
    (event.target.files && props.onChange) && props.onChange(event.target.files[0])
  }
  return (
    <div css={[styles.rootContainer, style]}>
      {
        label && (
          <label css={(theme: any) => styles.label({ theme, ...props })}>
            {label}
          </label>
        )
      }
      <div
        css={(theme: any) => styles.input({ theme, ...props })}
        onClick={() => !props.disabled && inputRef.current?.click()}
      >
        <input
          {...props}
          ref={inputRef}
          type="file"
          onChange={handleSelectedFiles}
          css={css({ display: "none" })}
        />
        <span>{value?.name || 'Ningun archivo seleccionado'}</span>
        <Button
          disabled={props.disabled}
          style={css({ width: '130px' })}
          size='sm'
          onClick={() => { }}
        >
          Buscar archivo
        </Button>
      </div>
      {
        hint && (
          <span css={(theme: any) => styles.hint({ theme, ...props })}>
            {hint}
          </span>
        )
      }
    </div>
  )
}

export default FileInput
