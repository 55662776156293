import {GET_QUESTIONS_ERROR, GET_QUESTIONS_START, GET_QUESTIONS_SUCCESS} from "../../types";

export default function questions(state, action) {
  switch (action.type) {
    case GET_QUESTIONS_START:
      return {
        ...state,
        data: [],
        loading: true,
        error: null
      };
    case GET_QUESTIONS_SUCCESS:
      return {
        ...state,
        data: action.results,
        loading: false,
        error: null
      };
    case GET_QUESTIONS_ERROR:
      return {
        ...state,
        data: [],
        loading: false,
        error: action.detail
      };
    default:
      return {...state};
  }
}
