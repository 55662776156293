import { FormData } from "../../../context/NotificationContext"

export const columns = [
  {
    Header: 'Titulo',
    accessor: 'title',
  },
  {
    Header: 'Cuerpo',
    accessor: 'body',
  },
  {
    Header: 'Ciclo',
    accessor: 'cycle',
  },
  {
    Header: 'Nivel',
    accessor: 'category',
  },
  {
    Header: 'Gerencia',
    accessor: 'management',
  },
  {
    Header: 'Imagen',
    accessor: 'image',
  },
  {
    Header: 'Estado',
    accessor: 'active',
  },
]

export type Data = Omit<FormData, 'imageFile'> & { image: string }

export const data: Data[] = []
