import {takeLatest, put, call} from 'redux-saga/effects';
import {GET_GROUP_DETAIL_POSTS_ERROR, GET_GROUP_DETAIL_POSTS_START, GET_GROUP_DETAIL_POSTS_SUCCESS} from "../../types";

import apiCall from '../../api/apiGet';

export function* get({payload}) {
  try {
    const url = `/group_posts/${payload.id}`;
    const method = 'GET';
    const headers = {Authorization: 'Bearer ' + payload.token};
    const results = yield call(apiCall, url, method, headers);
    yield put({type: GET_GROUP_DETAIL_POSTS_SUCCESS, results: results.data});
    // yield put(payload.navigate('/app/content-management/manage-post'))
  } catch (error) {
    yield put({type: GET_GROUP_DETAIL_POSTS_ERROR, error});
    // yield put(payload.navigate('/app/content-management/manage-post'))
  }
}

export default function* group_detail_posts() {
  yield takeLatest(GET_GROUP_DETAIL_POSTS_START, get);
}
