import { REMOVE_POSTS_ERROR, REMOVE_POSTS_START, REMOVE_POSTS_SUCCESS } from "../types";

export default function remove_posts(state, action) {
    switch (action.type) {
        case REMOVE_POSTS_START:
            return {
                ...state,
                loading: true,
                error: null
            };
        case REMOVE_POSTS_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null
            };
        case REMOVE_POSTS_ERROR:
            return {
                ...state,
                loading: false,
                error: action.detail
            };
        default:
            return {...state};
    }
}
