/** @jsxImportSource @emotion/react */
import React from 'react'
import { useDispatch } from 'react-redux'
import { Text } from '../../../components/atoms'
import { FormLogin } from '../../../components/templates'
import { background, sideSection } from './index.styles'
import { login } from '../../../redux/actions/auth/login';
import backgroundPng from "../../../assets/images/bg.png";

const Login: React.FC = () => {
  const dispatch = useDispatch()
  const handleLogin = (email: string, password: string) => {
    dispatch(login({ username: email, password }))
  }

  return (
    <div css={background} style={{ backgroundImage: `url(${backgroundPng})` }}>
      <div css={sideSection}>
        <FormLogin onLogin={handleLogin} />
        <div css={{ color: '#34189A', textAlign: 'center' }}>
          <Text textType="label">
            ISA (Intelligent Service Assistant v. 1.0.1) es un servicio
            desarrollado por SOY DIGITAL Todos los derechos reservados
          </Text>
        </div>
      </div>
    </div>
  )
}

export default Login
