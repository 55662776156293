import { css } from '@emotion/react'
import { ThemeUI } from '../../../theme'
import { Props as StyleProps } from './index'

type Props = { theme: ThemeUI } & StyleProps

export const root = ({ theme, style }: Props) => css`
  border-bottom: 1px solid ${theme.palette.ornamental};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 12px;
  & > div:first-child {
    & div:not(:last-child) {
      margin-bottom: 12px;
    }
  }
  ${style}
`