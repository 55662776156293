import {takeLatest, put, call} from 'redux-saga/effects';
import apiCall from '../../api';
import {DELETE_NEW_CYCLE_ERROR, DELETE_NEW_CYCLE_START, DELETE_NEW_CYCLE_SUCCESS} from "../../types";

export function* deleteNC({payload}) {
    try {
        const url = `/news_cycle/${payload.id}/`;
        const method = 'DELETE';
        const headers = {Authorization: 'Bearer ' + payload.token, 'Content-Type':'application/json;charset=utf-8'};
        const results = yield call(apiCall, url, method, payload.formData, headers);
        yield put({type: DELETE_NEW_CYCLE_SUCCESS, results: results.data});
    } catch (error) {
        yield put({type: DELETE_NEW_CYCLE_ERROR, error});
    }
}

export default function* delete_new_cycle() {
    yield takeLatest(DELETE_NEW_CYCLE_START, deleteNC);
}