/** @jsxImportSource @emotion/react */
import React, {useEffect, useState} from 'react'
import { Column } from 'react-table'
import { SelectInput, Text, Button } from '../../atoms'
import { TableGroupNewsBase } from '../../molecules'
import { ContentSection } from '../../organisms'
import * as styles from "../../molecules/TableBase/index.styles";
import {CATEGORIES} from "../../../category";
import {css} from "@emotion/react";

type Props = {
  title: string,
  subtitle: string,
  onClickApply?: () => void,
  onSearch?: (search: string) => void,
  columns: Column<any>[],
  data: any[],
  cycle: any[],
  management: any[],
  stateCategory: boolean,
}

const ManageTable: React.FC<Props> = ({
  title,
  subtitle,
  onClickApply = () => { },
  onSearch = () => { },
  columns,
  data,
  cycle,
  management,
  stateCategory,
}) => {

  const [page, setPage] = useState(1);
  //const [size, setSize] = useState(10);
  const size = 10;
  const [total, setTotal] = useState(data.length);
  const [rows, setRows] = useState(data.slice((page - 1) * size, (page * size)))


  const changeToPage = (page: number) => {
    setPage(page)
    setRows(data.slice((page - 1) * size, (page * size)))
  }

  const filterHandler = (key:string, value: any) => {
    setPage(1)
    const aux = data.filter(r => r[key].indexOf(value) > -1)
    setRows(aux.slice((page - 1) * size, (page * size)))
    setTotal(rows.length)
  }

  useEffect(() => {
    setRows(data.slice((page - 1)*size, (page * size)))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
      <ContentSection style={css`padding: ${title === '' ? '0 0 0 0' : ''}`}
          header={
            <React.Fragment >
              <Text textType='h4' weight='black'>{title}</Text>
              <Text textType='p' weight='bold'>{subtitle}</Text>
            </React.Fragment>
          }
      >
        <div css={styles.actions}>
          <SelectInput style={css`display: ${cycle.length === 0 ? 'none' : 'block'}`}
              label='Ciclo'
              options={cycle}
              placeholder='Selecciona un ciclo'
              onChange={(cycle) => cycle }
          />
          <SelectInput style={css`display: ${!stateCategory ? 'none' : 'block'}`}
              label='Categoría'
              options={CATEGORIES}
              placeholder='Selecciona un nivel'
              onChange={(event:any) => event && filterHandler('category', event.value)}
          />
          <SelectInput style={css`display: ${management.length === 0 ? 'none' : 'block'}`}
              label='Gerencia  '
              options={management}
              placeholder='Selecciona una gerencia'
              onChange={(value) => value }
          />
        </div>

        <TableGroupNewsBase columns={columns} data={rows}/>

        <div css={styles.actionsRigth}>
          <Button style={css({ width: '55px', margin: '0 0 0 5px' })} disabled={page === 1} onClick={() => changeToPage(1)}> {'<<'} </Button>
          <Button style={css({ width: '55px', margin: '0 0 0 5px' })} disabled={page === 1} onClick={() => changeToPage(page - 1)}>
            {'<'}
          </Button>
          <Button style={css({ width: '55px', margin: '0 0 0 5px' })} onClick={() => changeToPage(page + 1)} disabled={page === Math.ceil(data.length / size)}>
            {'>'}
          </Button>
          <Button style={css({ width: '55px', margin: '0 0 0 5px' })} disabled={page >= Math.ceil(data.length / size)} onClick={() => changeToPage(Math.ceil(data.length / size))}>
            {'>>'}
          </Button>
        </div>

        <div css={styles.actions}>
          <p>Pagina {page} de {Math.ceil(data.length / size)}</p>
          <p>Total item {total}</p>
        </div>


      </ContentSection>
  )
}

export default ManageTable

