import {DELETE_NOTIFICATION_ERROR, DELETE_NOTIFICATION_START, DELETE_NOTIFICATION_SUCCESS} from "../../types";

export default function delete_notification(state, action) {
    switch (action.type) {
        case DELETE_NOTIFICATION_START:
            return {
                ...state,
                loading: true,
                error: null
            };
        case DELETE_NOTIFICATION_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null
            };
        case DELETE_NOTIFICATION_ERROR:
            return {
                ...state,
                loading: false,
                error: action.detail
            };
        default:
            return {...state};
    }
}
