/** @jsxImportSource @emotion/react */
import React from 'react'
import { NewValue, Option, Props } from './types'
import * as styles from './index.styles'
import Select from 'react-select'
import { useTheme } from '@emotion/react'
import { ThemeUI } from '../../../theme'

const SelectInput: React.FC<Props> = ({
  children,
  label,
  hint,
  rightIcon,
  style = {},
  ...props
}) => {
  const theme = useTheme() as ThemeUI
  return (
    <div css={[styles.rootContainer, style]}>
      {label && (
        <label css={(theme: any) => styles.label({ theme, ...props })}>
          {label}
        </label>
      )}
      <Select<Option>
        {...props}
        isMulti={false}
        styles={styles.select({ theme, ...props })}
        isDisabled={props.disabled}
        options={props.options}
        value={props.value}
        onChange={(newValue: NewValue) => newValue && props.onChange(newValue)}
      />
      {hint && (
        <span css={(theme: any) => styles.hint({ theme, ...props })}>
          {hint}
        </span>
      )}
    </div>
  )
}

export default SelectInput
