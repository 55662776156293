export const breadcrumbItems = {
  index: { label: 'Inicio', path: '/' },
  dashboard: { label: 'Dashboard', path: '/app' },
  serviceManagement: { label: 'Administración de servicios' },
  contentManagement: { label: 'Administración de contenidos' },
  managePages: { label: 'Administrar páginas', path: '/app/content-management/manage-pages' },
  manageGroupNews: { label: 'Administrar grupo de noticias', path: '/app/content-management/manage-news' },
  manageNews: { label: 'Administrar noticias', path: '/app/content-management/manage-news' },
  manageBanners: { label: 'Administrar banners', path: '/app/content-management/manage-banners' },
  manageDrawer: { label: 'Administrar items de menú App', path: '/app/content-management/manage-drawer' },
  manageEvents: { label: 'Administrar eventos', path: '/app/content-management/manage-events' },
  managePost: { label: 'Administrar posteos', path: '/app/content-management/manage-post' },
  manageMagazine: { label: 'Administrar revistas', path: '/app/content-management/manage-magazine' },
  manageNatureMagazine: { label: 'Administrar revista natura', path: '/app/content-management/manage-nature-magazine' },
  manageCycle: { label: 'Noticias por ciclo', path: '/app/content-management/manage-cycle' },
  manageQuestions: { label: 'Administrar preguntas frecuentes', path: '/app/content-management/manage-questions' },
  manageNotifications: { label: 'Administrar notificaciones', path: '/app/content-management/manage-notifications' },
  manageNotificationsFCM: { label: 'Administrar notificaciones push - FCM', path: '/app/service-management/manage-fcm' },
  users: { label: 'Usuarios' },
  rolesManagement: { label: 'Administración de roles', path: '/app/roles-management' },
  managementData: { label: 'Datos de Gerencia', path: '/app/management-data' },
  manageManagements: { label: 'Administrar gerencia', path: '/app/content-management/manage-managements' },
  manageGrowthPath: { label: 'Camino del crecimiento', path: '/app/content-management/manage-growth-path' },
  manageOffers: { label: 'Administrar Ofertas', path: '/app/content-management/manage-offers-by-cycle' },
  manageCardCategory: { label: 'Administrar Tarjetas de categorías', path: '/app/content-management/manage-card-category' },
}
