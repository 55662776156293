/** @jsxImportSource @emotion/react */
import React from 'react'
import { ButtonProps } from './types'
import * as styles from './index.styles'

const Button: React.FC<ButtonProps> = (
    {
      children,
      onClick,
      disabled,
      ...props
    }) => {
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      css={(theme: any) => styles.button({ theme, ...props })}
    >
      {children}
    </button>
  )
}

export default Button
