import { css } from "@emotion/react";

export const root = css`
  display: flex;
  justify-content: start;
  flex-direction: column; 
  align-items: center;
  padding: 36px 12px;
  min-width: 230px;
  height: 100vh;
  background-color: #FFFFFF;
  position: sticky;
  top: 0;
  bottom: 0;
  left: 0;
  overflow-y: auto;
  z-index: 11;
  @media (max-width: 1264px){
    display: none;
  }
`

export const nav = css`
  width: 100%;
`