/** @jsxImportSource @emotion/react */
import React from 'react'
import { Outlet } from 'react-router'
import { Footer, Header, Sidebar } from '../../components/templates'
import * as styles from './index.styles'

const MainLayout: React.FC = () => (
  <div css={styles.root}>
    <Sidebar />
    <div css={styles.main}>
      <Header />
      <div css={styles.content}>
        <Outlet />
      </div>
      <Footer />
    </div>
  </div>
)

export default MainLayout
