import React from 'react'
import { useSelector } from 'react-redux'
import { useRoutes } from 'react-router-dom'
import { routesMap } from './router'

const App: React.FC = () => {
  const isLogged = useSelector((state: any) => state.login.isLogged)
  const routes = useRoutes([...routesMap(isLogged)])
  return <React.Fragment>{routes}</React.Fragment>
}

export default App
