import {takeLatest, put, call} from 'redux-saga/effects';
import apiCall from '../../api';
import { GET_FCM_HISTORY_START, POST_FCM_SEND_CSV_ERROR, POST_FCM_SEND_CSV_START, POST_FCM_SEND_CSV_SUCCESS } from "../../types";

export function* create({payload}) {
  try {
    const url = '/send_fcm_csv/';
    const method = 'POST';
    const headers = {Authorization: 'Bearer ' + payload.token, 'Content-Type':'multipart/form-data'};
    const formData = new FormData();
    formData.append('csv_file', payload.formData);
    formData.append('image', payload.image);
    const results = yield call(apiCall, url, method, formData, headers);
    yield put({type: POST_FCM_SEND_CSV_SUCCESS, results: results.data});
  } catch (error) {
    yield put({type: POST_FCM_SEND_CSV_ERROR, error});
  } finally {
    yield put(payload.handleNotification());
    yield put({ type: GET_FCM_HISTORY_START, payload: {token: payload.token} });
  }
}

export default function* fcm_send_csv() {
  yield takeLatest(POST_FCM_SEND_CSV_START, create);
}
