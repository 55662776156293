/** @jsxImportSource @emotion/react */
import React, { Fragment } from 'react'
import * as styles from '../index.styles'
import { CreateSide, ManageTable } from '../../../components/templates'
import { columns, data } from './mockData'
import { breadcrumbItems } from '../../utils'
import { Breadcrumb, StateSelectInput } from '../../../components/atoms'

const { index, users, rolesManagement } = breadcrumbItems

const ManageRoles: React.FC = () => {
  const formatData = data.map((item) => ({
    ...item,
    state: (
      <StateSelectInput
        defaultValue={{ label: 'Desactivado', value: 'disabled' }}
      />
    ),
  }))
  return (
    <Fragment>
      <Breadcrumb items={[index, users, rolesManagement]} />
      <div css={styles.root}>
        <div css={styles.main}>
          <ManageTable
            title="Administrar Roles"
            subtitle="Usuarios"
            columns={columns}
            data={formatData || []}
            cycle={[]}
            management={[]}
            stateCategory={false}
          />
        </div>
        <div css={styles.right}>
          <CreateSide
            title="Nuevo usuario"
            message="Massa vulputate id placerat vitae ornare a orci. Gravida augue viverra pretium adipiscing mauris."
            textButton="Crear nuevo usuario"
          />
        </div>
      </div>
    </Fragment>
  )
}

export default ManageRoles
