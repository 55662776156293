import {POST_FCM_SEND_ERROR, POST_FCM_SEND_START, POST_FCM_SEND_SUCCESS} from "../../types";

export default function fcm_send(state, action) {
  switch (action.type) {
    case POST_FCM_SEND_START:
      return {
        ...state,
        data: [],
        loading: true,
        error: null
      };
    case POST_FCM_SEND_SUCCESS:
      return {
        ...state,
        data: action.results,
        loading: false,
        error: null
      };
    case POST_FCM_SEND_ERROR:
      return {
        ...state,
        data: [],
        loading: false,
        error: action.detail
      };
    default:
      return {...state};
  }
}
