/** @jsxImportSource @emotion/react */
import React from 'react'
import { InputProps } from './types'
import * as styles from './index.styles'
import { CheckIcon, ErrorIcon } from '../../../assets'

const Input: React.FC<InputProps> = ({
  children,
  label,
  hint,
  maxLength,
  rightIcon,
  style = {},
  success,
  error,
  ...props
}) => {
  const showIcon = success || error || rightIcon
  const statusProps = { success, error }
  return (
    <div css={[styles.rootContainer, style]}>
      {label && (
        <label css={(theme: any) => styles.label({ theme, ...statusProps })}>
          {label}
        </label>
      )}
      <div css={styles.inputContainer}>
        <input
          {...props}
          css={(theme: any) => styles.input({ theme, ...statusProps })}
          maxLength={maxLength}
          onChange={(e) => props.onChange && props.onChange(e.target.value)}
        />
        {showIcon &&
          (rightIcon ? (
            rightIcon
          ) : error ? (
            <ErrorIcon color="#F26A1B" />
          ) : (
            success && <CheckIcon color="#04D99D" />
          ))}
      </div>
      {hint && (
        <span css={(theme: any) => styles.hint({ theme, ...props })} style={{ color: '#F26A1B'}}>
          {hint}
        </span>
      )}
    </div>
  )
}

export default Input
