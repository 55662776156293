import { CREATE_MAGAZINE_ERROR, CREATE_MAGAZINE_START, CREATE_MAGAZINE_SUCCESS } from "../../types";

export default function create_magazine(state, action) {
  switch (action.type) {
    case CREATE_MAGAZINE_START:
      return {
        ...state,
        loading: true,
        error: null
      };
    case CREATE_MAGAZINE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null
      };
    case CREATE_MAGAZINE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.detail
      };
    default:
      return { ...state };
  }
}
