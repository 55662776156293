import { takeLatest, put, call } from 'redux-saga/effects';

import apiCall from '../../api';
import {
  CREATE_QUESTIONS_ERROR,
  CREATE_QUESTIONS_START,
  CREATE_QUESTIONS_SUCCESS,
} from "../../types";

export function* create({ payload }) {
  try {
    const url = '/questions/';
    const method = 'POST';
    const headers = { Authorization: 'Bearer ' + payload.token, 'Content-Type': 'multipart/form-data' };
    const data = (object) => Object.keys(object).reduce((formData, key) => {
      formData.append(key, object[key]);
      return formData;
    }, new FormData());
    const results = yield call(apiCall, url, method, data(payload.formData), headers);
    yield put({ type: CREATE_QUESTIONS_SUCCESS, results: results.data });
    yield put(payload.navigate('/app/content-management/manage-questions'));
  } catch (error) {
    yield put({ type: CREATE_QUESTIONS_ERROR, error });
  }
}

export default function* create_notification() {
  yield takeLatest(CREATE_QUESTIONS_START, create);
}
