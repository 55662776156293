import {CREATE_GROWTH_PATH_ERROR, CREATE_GROWTH_PATH_START, CREATE_GROWTH_PATH_SUCCESS} from "../../types";

export default function create_growth_path(state, action) {
  switch (action.type) {
    case CREATE_GROWTH_PATH_START:
      return {
        ...state,
        loading: true,
        error: null
      };
    case CREATE_GROWTH_PATH_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null
      };
    case CREATE_GROWTH_PATH_ERROR:
      return {
        ...state,
        loading: false,
        error: action.detail
      };
    default:
      return {...state};
  }
}
