import {takeLatest, put, call} from 'redux-saga/effects';

import apiCall from '../../api/apiGet';
import {GET_ALL_NOTIFICATION_ERROR, GET_ALL_NOTIFICATION_START, GET_ALL_NOTIFICATION_SUCCESS} from "../../types";

export function* getNotifications({payload}) {
  try {
    const url = '/notifications/';
    const method = 'GET';
    const headers = {Authorization: 'Bearer ' + payload.token};
    const results = yield call(apiCall, url, method, headers);
    yield put({type: GET_ALL_NOTIFICATION_SUCCESS, results: results.data});
  } catch (error) {
    yield put({type: GET_ALL_NOTIFICATION_ERROR, error});
  }
}

export default function* notifications() {
  yield takeLatest(GET_ALL_NOTIFICATION_START, getNotifications);
}
