/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react'
import React, { useContext } from 'react'
import { ThemeUI } from '../../../theme'
import { Button, Card, Text } from '../../atoms'
import { ContentSection } from '../../organisms'
import * as styles from './index.styles'
import { Context } from '../../../context/NewCycleContext'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {useParams} from "react-router";
import {update_new_cycle} from "../../../redux/actions/group_cycle/update_new_cycle";
import {create_new_cycle} from "../../../redux/actions/group_cycle/create_new_cycle";

const NewPreview: React.FC = () => {
  const APP_URL = process.env.REACT_APP_URL
  const theme = useTheme() as ThemeUI
  const navigate = useNavigate()
  const { formData } = useContext(Context)
  const dispatch = useDispatch()
  const token = useSelector((state: any) => state.login.access)
  let { idCycle } = useParams();

  const handlePost = (token: any, formData: any) => {
    if (idCycle) {
      if (!formData.image?.name) {
        formData.image = formData.image.indexOf('/media/')>-1 ? undefined : formData.image
      }
      if (!formData.image_description?.name) {
        formData.image_description = formData.image_description.indexOf('/media/')>-1 ? undefined : formData.image_description
      }
      dispatch(update_new_cycle({ token, formData, navigate }))
    } else {
      dispatch(create_new_cycle({ token, formData, navigate }))
    }
  }

  return (
    <ContentSection
      style={styles.root}
      header={
        <Text textType="p" weight="bold">
          Vista Previa
        </Text>
      }
    >
      <div css={(theme: any) => styles.preview(theme)}>
        <Card style={css({ overflow: 'hidden' })}>
          <img
            src={
              formData.image
                ? (formData.image?.name ? URL.createObjectURL(formData.image): APP_URL+''+formData.image)
                : 'https://via.placeholder.com/300x200'
            }
            alt="placeholder"
            css={styles.previewImage}
          />
          <img
            src={
              formData.image_description
                ? (formData.image_description?.name ? URL.createObjectURL(formData.image_description): APP_URL+''+formData.image_description)
                : 'https://via.placeholder.com/300x200'
            }
            alt="placeholder"
            css={styles.previewImage}
          />
          <Text textType='sm' style={styles.previewTitle(theme)}>
            {formData.title}
          </Text>
          <div css={styles.previewDescription(theme)}>
            <Text textType="sm" weight="light">
              {formData.description}
            </Text>
          </div>
        </Card>
      </div>
      <Button onClick={() => handlePost(token, formData)}
              disabled={!formData.title || !formData.image || !formData.image_description}>
        {formData.active ? 'Publicar' : 'Guardar'} noticia por ciclo
      </Button>
    </ContentSection>
  )
}

export default NewPreview
