/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import React, {FC, useContext} from 'react'
import { Context } from '../../../context/UsersContext'
import {BaseInput, SwitchInput, Text} from '../../atoms'
import { ContentSection } from '../../organisms'
import {useParams} from "react-router-dom";
import Select from "react-select";
import * as styles from "../Header/index.styles";
import { permissions } from '../../../permissions'
import {useEffect} from 'react';
import { useSelector } from 'react-redux'

const UserForm: FC = () => {
  const { formData, handleChangeFormData: handleChange, handleUpdateFormData: handleData } = useContext(Context)
  let { id } = useParams();
  const permissions_list = useSelector((state: any) => state.permissions.data)
  const data_question = useSelector((state: any) => {
    return state.userdata.data.find((r: any) => parseInt(id+'') === r.id)
  })
  const managementInit = data_question?.permissions ? permissions_list.filter((r:any) => data_question?.permissions.indexOf(r.id) > -1) : null

  const handleManagement = (e: any) => {
    let temp: any[] = []
    e.forEach((item: { id: any }, index: any) => {
      temp.push(item.id)
    })
    handleChange({ user_permissions: temp })
  }

  useEffect(() => {
    if (!!id) {
      handleData(data_question)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, data_question])

  return (
    <ContentSection
      style={css`display: grid; gap: 18px;`}
      header={
        <React.Fragment>
          <Text textType='h4' weight='black'>{id ? 'Editar' : 'Nuevo'} usuario</Text>
        </React.Fragment>
      }
    >
      <BaseInput
        label='Nombres'
        value={formData.first_name}
        onChange={(value) => handleChange({ first_name: value })}
        placeholder='Nombres'
        maxLength={500}
      />
      <BaseInput
        label='Apellidos'
        value={formData.last_name}
        onChange={(value) => handleChange({ last_name: value })}
        placeholder='Apellidos'
        maxLength={500}
      />
      <BaseInput
        disabled={id ? true : false}
        label='Correo electronico'
        value={formData.username}
        onChange={(value) => handleChange({ username: value })}
        placeholder='Escribe el correo electronico'
        maxLength={500}
      />
      <Text textType="label">
        Permisos para este usuario :{' '}
        <Select css={styles.input}
          isMulti placeholder="Selecciona opciones de permisos"
          options={permissions}
          defaultValue={managementInit}
          getOptionValue={(option: any) => `${option['id']}`}
          getOptionLabel={(option: any) => `${option['name']}`}
          onChange={(e) =>  handleManagement(e)}
        />
      </Text>

      <SwitchInput
        checked={formData.is_staff}
        onCheckedChange={(value) => handleChange({ is_staff: value })}
        label={formData.is_staff ? 'Es staff': 'No es staff'}
      />
      <SwitchInput
        checked={formData.is_active}
        onCheckedChange={(value) => handleChange({ is_active: value })}
        label={formData.is_active ? 'Está activo': 'Inactivo'}
      />
    </ContentSection>
  )
}
export default UserForm