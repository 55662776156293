export const columns = [
  {
    Header: 'Titulo',
    accessor: 'title',
  },
  {
    Header: 'Contenido',
    accessor: 'description',
  },
  {
    Header: 'Ciclo',
    accessor: 'cycle',
  },
  {
    Header: 'Nivel',
    accessor: 'category',
  },
  {
    Header: 'Imagen',
    accessor: 'image',
  },
  {
    Header: 'Imagen descripcion',
    accessor: 'image_description',
  },
  {
    Header: 'Imagen movil',
    accessor: 'image_mobile',
  },
  {
    Header: 'Enlace',
    accessor: 'url',
  },
  {
    Header: 'Editar',
    accessor: 'editBanner',
  },
  {
    Header: 'Eliminar',
    accessor: 'deleteBanner',
  },
  {
    Header: 'Estado',
    accessor: 'active',
  },
]

export const data = [
  {
    title: 'Titulo 1',
    content: 'Contenido 1',
    image: 'https://via.placeholder.com/150',
    link: 'https://via.placeholder.com/150',
  },
  {
    title: 'Titulo 2',
    content: 'Contenido 2',
    image: 'https://via.placeholder.com/150',
    link: 'https://via.placeholder.com/150',
  },
  {
    title: 'Titulo 3',
    content: 'Contenido 3',
    image: 'https://via.placeholder.com/150',
    link: 'https://via.placeholder.com/150',
  },
]
