import { GET_USER_ERROR, GET_USER_START, GET_USER_SUCCESS } from '../../types';

export default function userdata(state, action) {
  switch (action.type) {
    case GET_USER_START:
      return { ...state };
    case GET_USER_SUCCESS:
      return {
        ...state,
        data: action.results,
        error: null,
      };
    case GET_USER_ERROR:
      return {
        ...state,
        data: [],
        error: action.detail
      };
    default:
      return { ...state };
  }
}
