/** @jsxImportSource @emotion/react */
import React from 'react'
import { NewValue, Option, Props } from './types'
import * as styles from './index.styles'
import Select, { GroupBase, OptionsOrGroups } from 'react-select'
import { useTheme } from '@emotion/react'
import { ThemeUI } from '../../../theme'

const options: OptionsOrGroups<Option, GroupBase<Option>> = [
  {
    value: 'published',
    label: 'Publicado',
  },
  {
    value: 'disabled',
    label: 'Desactivado',
  },
]

const StateSelect: React.FC<Props> = ({
  children,
  rightIcon,
  style = {},
  onChange,
  ...props
}) => {
  const theme = useTheme() as ThemeUI
  const handleChange = (newValue: NewValue) =>
    newValue && onChange && onChange(newValue)
  return (
    <div css={[styles.rootContainer, style]}>
      <Select<Option>
        {...props}
        isSearchable={false}
        isMulti={false}
        styles={styles.select({ theme, ...props })}
        isDisabled={props.disabled}
        options={options}
        value={props.value}
        onChange={handleChange}
      />
    </div>
  )
}

export default StateSelect
