import * as React from "react"

export default React.memo((props: React.SVGProps<SVGSVGElement>) => (

  <svg
    width={12}
    height={14}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#prefix__clip0_380:3764)">
      <path d="M11.9 5.8c-.8-1.2-.8-1.6-.8-1.6C10.7 1.1 8.2.9 7.5.9h-.2C7 .9 5.2 1 4.2 2.3h-.6c-.3 0-3.6 0-3.6 3.3 0 1.9.6 2.6 1 3.2l.1.1C.4 9.3 0 10 0 10.8c0 1.3 1 2.3 2.3 2.3 1.3 0 2.3-1 2.3-2.3 0-1.3-1-2.3-2.3-2.3-.3 0-.6.1-.9.2l-.1-.1C.9 8 .4 7.4.4 5.6c0-2.8 2.7-3 3.3-3H4c.7 0 2.9.2 3.2 3 0 .1.1.4.9 1.7v.1s0 .1-.1.1l-.8.4c-.1 0-.1.1-.1.2 0 0 .1 1 0 1.5 0 .1-.5.3-1.1.3h-.1-.1c-.3 0-.4.1-.4.3v1.1c0 .1.1.2.2.2s.2-.1.2-.2v-1.1H6c.5 0 1.4-.1 1.5-.6.1-.4 0-1.1 0-1.4.1-.1.4-.2.6-.3.2-.1.3-.2.3-.3 0-.2-.1-.4-.1-.4-.8-1.3-.8-1.6-.8-1.7-.3-2.4-1.8-3-2.8-3.2.8-1 2.3-1 2.6-1H7.6c.7 0 2.9.2 3.2 2.9 0 .1.1.5.9 1.8v.1s0 .1-.1.1c-.3.2-.7.3-.7.3-.1 0-.1.1-.1.2 0 0 .1 1 0 1.5 0 .2-.5.3-1.1.3h-.1-.1c-.4 0-.6.2-.6.4V10c0 .1.1.2.2.2s.2-.1.2-.2V8.9h.2c.5 0 1.4-.1 1.5-.6.1-.4 0-1.1 0-1.4l.6-.3c.3-.2.4-.3.4-.4 0-.2-.1-.4-.1-.4zm-7.7 5c0 1.1-.9 1.9-1.9 1.9-1.1 0-1.9-.9-1.9-1.9 0-1.1.9-1.9 1.9-1.9 1-.1 1.9.8 1.9 1.9z" />
      <path d="M1.3 10.9h.8v.8c0 .2.1.2.2.2s.2-.1.2-.2v-.8h.8c.1 0 .2-.1.2-.2s-.1-.2-.2-.2h-.8v-.7c0-.1-.1-.2-.2-.2s-.2.1-.2.2v.8h-.8c-.1 0-.2.1-.2.2s.1.1.2.1z" />
    </g>
    <defs>
      <clipPath id="prefix__clip0_380:3764">
        <path fill="#fff" transform="translate(0 .9)" d="M0 0h12v12.2H0z" />
      </clipPath>
    </defs>
  </svg>
))
