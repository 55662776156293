import { createContext, FC, useState } from 'react';
import { Option } from "../components/atoms/SelectInput/types"

export type Data = {
  id?: string,
  cycle: string,
  category?: Option,
  news_cycle: Object
  active: boolean,
}
export interface DataContext {
  formData: Data,
  handleChangeFormData: (data: Partial<Data>) => void,
  handleUpdateFormData: (data: Partial<Data>) => void,
}

const initialData: Data = {
  id: '',
  cycle: '',
  category: undefined,
  news_cycle: [],
  active: true,
}

export const Context = createContext<DataContext>({
  formData: initialData,
  handleChangeFormData: (data: Partial<Data>) => { },
  handleUpdateFormData: (data: Partial<Data>) => { },
})

const GroupPostFormDataProvider: FC = (props) => {
  const [formData, setFormData] = useState<Data>({
    id: '',
    cycle: '',
    category: undefined,
    news_cycle: [],
    active: true,
  })

  const handleChangeFormData = (data: Partial<Data>) => setFormData({ ...formData, ...data })

  const handleUpdateFormData = (data: Partial<Data>) => {
    if (data && data?.id) {
      setFormData({
        ...formData,
        id: data.id,
        cycle: data.cycle || initialData.cycle,
        category: data.category || initialData.category,
        news_cycle: data.news_cycle || initialData.news_cycle,
        active: data.active || false
      })
    }
  }

  return (
    <Context.Provider value={{ formData, handleChangeFormData, handleUpdateFormData }}>
      {props.children}
    </Context.Provider>
  )
}

export default GroupPostFormDataProvider
