import { takeLatest, put, call } from 'redux-saga/effects'

import apiCall from '../../api/apiGet'
import { GET_NATURE_MAGAZINE_ERROR, GET_NATURE_MAGAZINE_START, GET_NATURE_MAGAZINE_SUCCESS } from '../../types'

export function* getNatureMagazine({ payload }) {
    try {
        const url = '/natura_magazines/'
        const method = 'GET'
        const headers = { Authorization: 'Bearer ' + payload.token }
        const results = yield call(apiCall, url, method, headers)
        yield put({ type: GET_NATURE_MAGAZINE_SUCCESS, results: results.data })
    } catch (error) {
        yield put({ type: GET_NATURE_MAGAZINE_ERROR, error })
    }
}

export default function* nature_magazine() {
    yield takeLatest(GET_NATURE_MAGAZINE_START, getNatureMagazine)
}