import { takeLatest, put, call } from 'redux-saga/effects'

import apiCall from '../../api/apiGet'
import { GET_CDC_CARD_START, GET_CDC_CARD_SUCCESS, GET_CDC_CARD_ERROR } from '../../types'

export function* getOffers({ payload }) {
  try {
    const url = '/cdc_card_caregories/'
    const method = 'GET'
    const headers = { Authorization: 'Bearer ' + payload.token }
    const results = yield call(apiCall, url, method, headers)
    yield put({ type: GET_CDC_CARD_SUCCESS, results: results.data })
  } catch (error) {
    yield put({ type: GET_CDC_CARD_ERROR, error })
  }
}

export default function* card_category() {
  yield takeLatest(GET_CDC_CARD_START, getOffers)
}
