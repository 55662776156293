import * as React from "react"

export default React.memo((props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={4}
    height={9}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M.333 8.5a.314.314 0 01-.185-.061.359.359 0 01-.123-.163.394.394 0 01.073-.397L3.195 4.5.098 1.12A.381.381 0 010 .865C0 .767.035.674.098.607A.32.32 0 01.333.5.32.32 0 01.57.607l3.333 3.636a.392.392 0 010 .514L.57 8.393a.332.332 0 01-.108.08.308.308 0 01-.128.027z"
      fill="#34189A"
    />
  </svg>
))