import { takeLatest, put, call } from 'redux-saga/effects'
import apiCall from '../../api'
import { CREATE_NATURE_MAGAZINE_ERROR, CREATE_NATURE_MAGAZINE_START, CREATE_NATURE_MAGAZINE_SUCCESS } from "../../types"

export function* create_nature({ payload }) {
    try {
        const url = '/natura_magazines/'
        const method = 'POST'
        const headers = { Authorization: 'Bearer ' + payload.token, 'Content-Type': 'multipart/form-data' }
        const data = (object) => Object.keys(object).reduce((formData, key) => {
            formData.append(key, object[key])
            return formData
        }, new FormData())
        const results = yield call(apiCall, url, method, data(payload.formData), headers)
        yield put({ type: CREATE_NATURE_MAGAZINE_SUCCESS, results: results.data })
        yield put(payload.navigate('/app/content-management/manage-nature-magazine'))
    } catch (error) {
        yield put({ type: CREATE_NATURE_MAGAZINE_ERROR, error })
    }
}

export default function* create_nature_magazine() {
    yield takeLatest(CREATE_NATURE_MAGAZINE_START, create_nature)
}