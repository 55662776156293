import { takeLatest, put, call } from 'redux-saga/effects'
import apiCall from '../../api'
import { CREATE_MAGAZINE_ERROR, CREATE_MAGAZINE_START, CREATE_MAGAZINE_SUCCESS } from "../../types"

export function* create({ payload }) {
  try {
    const url = '/magazines/'
    const method = 'POST'
    const headers = { Authorization: 'Bearer ' + payload.token, 'Content-Type': 'multipart/form-data' }
    const data = (object) => Object.keys(object).reduce((formData, key) => {
      formData.append(key, object[key])
      return formData
    }, new FormData())
    const results = yield call(apiCall, url, method, data(payload.formData), headers)
    yield put({ type: CREATE_MAGAZINE_SUCCESS, results: results.data })
    yield put(payload.navigate('/app/content-management/manage-magazine'))
  } catch (error) {
    yield put({ type: CREATE_MAGAZINE_ERROR, error })
  }
}

export default function* create_magazine() {
  yield takeLatest(CREATE_MAGAZINE_START, create)
}
