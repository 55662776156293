import { css } from '@emotion/react'
import { ThemeUI } from '../../../theme'
import { StyleProps } from './types'

type Props = { theme: ThemeUI } & StyleProps

export const rootContainer = css`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const label = ({ theme }: Props) => css`
  font-size: 9px;
  line-height: 11px;
  margin-bottom: 6px;
  text-transform: uppercase;
`

export const hint = ({ theme, success, error }: Props) => css`
  font-size: 9px;
  line-height: 11px;
  ${success && (`color: ${theme.palette.success};`)}
  ${error && (`color: ${theme.palette.danger};`)}
  margin-top: 6px;
  text-align: right;
`

export const input = ({ theme, success, error, disabled }: Props) => css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 12px 18px;
  width: 100%;  
  background-color: ${theme.palette.white};
  font-family: "Lato", sans-serif;
  font-size: 12px;
  line-height: 14.4px;
  border-radius: 3px;
  border-width: 1px;
  border-style: solid;
  border-color: ${theme.palette.secondary70};
  text-align: left;
  ${success && `background-color: ${theme.palette.successLight};`}
  ${error && `background-color: ${theme.palette.dangerLight};`}
  &:hover {
    cursor: pointer;
  }
  &:focus {
    outline: 1px solid #4784FF;
  }
  & span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 12px;
  }
  ${disabled && `
    background-color: ${theme.palette.secondary10};
    &::placeholder {
      color: ${theme.palette.secondary70};
    }
    & ~ span {
      color: ${theme.palette.secondary70};
    }
  `}
`
