import { takeLatest, put, call } from 'redux-saga/effects'

import apiCall from '../../api/apiGet'
import { GET_MAGAZINES_ERROR, GET_MAGAZINES_START, GET_MAGAZINES_SUCCESS } from '../../types'

export function* getMagazines({ payload }) {
  try {
    const url = '/magazines/'
    const method = 'GET'
    const headers = { Authorization: 'Bearer ' + payload.token }
    const results = yield call(apiCall, url, method, headers)
    yield put({ type: GET_MAGAZINES_SUCCESS, results: results.data })
  } catch (error) {
    yield put({ type: GET_MAGAZINES_ERROR, error })
  }
}

export default function* magazines() {
  yield takeLatest(GET_MAGAZINES_START, getMagazines)
}
