import { createContext, FC, useState } from 'react'

export type Data = {
  id?: string
  title: string
  description: string
  image?: File
  is_image: boolean
  image_mobile?: File
  is_image_mobile: boolean
  image_description?: File
  is_image_description: boolean
  url: string
  active: boolean
  category: string
  cycle: string
}
export interface DataContext {
  formData: Data,
  handleChangeFormData: (data: Partial<Data>) => void,
  handleUpdateFormData: (data: Partial<Data>) => void,
}

const initialData: Data = {
  id: '',
  title: '',
  description: '',
  image: undefined,
  is_image: true,
  url: '',
  active: true,
  image_description: undefined,
  is_image_description: true,
  image_mobile: undefined,
  is_image_mobile: true,
  category: '',
  cycle: '',
}

export const Context = createContext<DataContext>({
  formData: initialData,
  handleChangeFormData: (data: Partial<Data>) => {},
  handleUpdateFormData: (data: Partial<Data>) => {},
})

const NewFormDataProvider: FC = (props) => {
  const [formData, setFormData] = useState<Data>(initialData)

  const handleChangeFormData = (data: Partial<Data>) => {
    setFormData({ ...formData, ...data })
  }

  const handleUpdateFormData = (data: Partial<Data>) => {
    if (data && data?.id) {
      setFormData({
        ...formData,
        id: data.id,
        title: data.title || initialData.title,
        description: data.description || initialData.description,
        url: data.url || initialData.url,
        category: data.category || initialData.category,
        cycle: data.cycle || initialData.cycle,
        image: data.image || initialData.image,
        image_description: data.image_description || initialData.image_description,
        image_mobile: data.image_mobile || initialData.image_mobile,
        is_image: data.is_image || initialData.is_image,
        is_image_description: data.is_image_description || initialData.is_image_description,
        is_image_mobile: data.is_image_mobile || initialData.is_image_mobile,
        active: data.active || false
      })
    }
  }
  return (
    <Context.Provider value={{ formData, handleChangeFormData, handleUpdateFormData }}>
      {props.children}
    </Context.Provider>
  )
}

export default NewFormDataProvider
