import { takeLatest, call, put } from 'redux-saga/effects';
import { GET_USER_ERROR, GET_USER_START, GET_USER_SUCCESS } from '../../types';

import apiCall from '../../api/apiGet';

export function* get({ payload }) {
  try {
    const url = '/users/';
    const method = 'GET';
    const headers = {Authorization: 'Bearer ' + payload.token};;
    const results = yield call(apiCall, url, method, headers);
    yield put({ type: GET_USER_SUCCESS, results: results.data });
  } catch (error) {
    yield put({ type: GET_USER_ERROR, error });
  }
}

export default function* userdata() {
  yield takeLatest(GET_USER_START, get);
}
