import { css } from '@emotion/react'
import { ThemeUI } from '../../../theme'
export const root = (theme: ThemeUI) => css`
  display: flex;
  align-items: center;
  justify-content: start;
  & > div {
    margin-left: 12px;
  }
`

export const container = (theme: ThemeUI) => css`
  all: unset;
  width: 21px;
  height: 12px;
  background-color: white;
  border-radius: 9999px;
  position: relative;
  box-shadow: 0 2px 10px white;
  border: 1px solid ${theme.palette.secondary};
  box-sizing: border-box;
  &[data-state="checked"] {
    border-color: ${theme.palette.primary};
  background-color: ${theme.palette.primary10};
  }
`

export const thumb = (theme: ThemeUI) => css`
  display: block;
  width: 8px;
  height: 8px;
  background-color: ${theme.palette.secondary};
  border-radius: 9999px;
  box-shadow: 0 2px 10px white;
  transition: transform 0.1;
  transform: translateX(1px);
  will-change: transform;
  &[data-state='checked'] {
    transform: translateX(10px);
    background-color: ${theme.palette.primary};
  }
`