export const columns = [
  {
    Header: 'Nombre',
    accessor: 'name',
  },
  {
    Header: 'Tipo',
    accessor: 'type',
  },
  {
    Header: 'Ciclo',
    accessor: 'cycle',
  },
  {
    Header: 'Nivel',
    accessor: 'category',
  },
  {
    Header: 'Gerencia',
    accessor: 'management',
  },
  {
    Header: 'Estado',
    accessor: 'active',
  },
]
