import { GET_NATURE_MAGAZINE_START, GET_NATURE_MAGAZINE_SUCCESS, GET_NATURE_MAGAZINE_ERROR } from "../../types";

export default function nature_magazine(state, action) {
    switch (action.type) {
        case GET_NATURE_MAGAZINE_START:
            return {
                ...state,
                data: [],
                loading: true,
                error: null
            };
        case GET_NATURE_MAGAZINE_SUCCESS:
            return {
                ...state,
                data: action.results,
                loading: false,
                error: null
            };
        case GET_NATURE_MAGAZINE_ERROR:
            return {
                ...state,
                data: [],
                loading: false,
                error: action.detail
            };
        default:
            return { ...state };
    }
}
