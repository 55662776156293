/** @jsxImportSource @emotion/react */
import React from 'react'
import { Text } from '../../atoms'
import { Notifications } from '../../organisms'
import * as styles from './index.styles'

const Header: React.FC = () => {
  return (
    <div css={styles.root}>
      <Text textType='h3' weight='black'>Administrador para la aplicación móvil de Gerencias y Consultoras. V01</Text>
      <Notifications />
    </div>
  )
}

export default Header
