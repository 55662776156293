/** @jsxImportSource @emotion/react */
import React from 'react'
import { NatureMagazineForm, NatureMagazinePreview } from '../../../components/templates'
import * as styles from '../index.styles'
import Provider from '../../../context/NatureMagazineContext'
import { breadcrumbItems } from '../../utils'
import { Breadcrumb } from '../../../components/atoms'

const { index, contentManagement, manageNatureMagazine } = breadcrumbItems

const NatureManageNews: React.FC = () => {
    return (
        <Provider>
            <Breadcrumb items={[index, contentManagement, manageNatureMagazine]} />
            <div css={styles.root}>
                <div css={styles.main}>
                    <NatureMagazineForm />
                </div>
                <div css={styles.right}>
                    <NatureMagazinePreview />
                </div>
            </div>
        </Provider>
    )
}

export default NatureManageNews
