import { takeLatest, put, call } from 'redux-saga/effects'
import apiCall from '../../api'
import { CREATE_GROUP_POST_ERROR, CREATE_GROUP_POST_START, CREATE_GROUP_POST_SUCCESS } from "../../types"

export function* create({ payload }) {
    try {
        const url = '/group_posts/'
        const method = 'POST'
        delete payload.formData.id
        const headers = { Authorization: 'Bearer ' + payload.token, 'Content-Type': 'multipart/form-data' }
        const data = (object) => Object.keys(object).reduce((formData, key) => {
            formData.append(key, object[key])
            return formData
        }, new FormData())
        const results = yield call(apiCall, url, method, data(payload.formData), headers)
        yield put({ type: CREATE_GROUP_POST_SUCCESS, results: results.data })
        yield put(payload.navigate('/app/content-management/manage-post'))
    } catch (error) {
        yield put({ type: CREATE_GROUP_POST_ERROR, error })
    }
}

export default function* create_group_posts() {
    yield takeLatest(CREATE_GROUP_POST_START, create)
}