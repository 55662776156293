/** @jsxImportSource @emotion/react */
import React, {useEffect, useState} from 'react'
import {CreateSide, ManageTable, Modal} from '../../../components/templates'
import * as styles from '../index.styles'
import Provider from '../../../context/WatchGroupPostsContext'
import { breadcrumbItems } from '../../utils'
import { Breadcrumb } from '../../../components/atoms'
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {columns} from "./mockData";
import {useNavigate} from "react-router";
import {group_detail_posts} from "../../../redux/actions/post/group_detail_posts";
import {delete_group_posts} from "../../../redux/actions/post/delete_group_posts";
import { delete_posts } from '../../../redux/actions/post/delete_posts'
import apiCall from '../../../redux/api';

const { index, contentManagement, managePost } = breadcrumbItems

const ManagePosts: React.FC = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  let { id } = useParams();
  const token = useSelector((state: any) => state.login.access)
  const [show, setShow] = useState(false)
  const [post, setPost] = useState([])
  const [modalData, setModalData] = useState({title: '', text: '', success: '', warning: ''})

  const detail_posts = useSelector((state: any) => state.group_detail_posts.data)

  useEffect(() => {
    if (id) {
      dispatch(group_detail_posts({ token, id }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (detail_posts) {
      if (detail_posts?.posts) {
        setPost(detail_posts.posts || [])
      }
    }
  }, [detail_posts])

  useEffect(() => {
    if (modalData.success === 'Cancelar') {
      if (id) {
        const url = `/group_posts/${id}/`;
        const method = 'DELETE';
        const headers = { 
          'Authorization': `Bearer ${token}`,
          'Content-Type':'application/json'
        };
        apiCall(url, method, {id: id}, headers).then((data) => console.log('data group: ',data))
        dispatch(delete_posts({ token, id }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalData])

  const handleGroupDelete = (id: any) => {
    if (detail_posts?.posts?.length > 0) {
      setModalData({title: 'Tienes datos de posteos!', text: 'No puedes eliminar un grupo si cuenta con posteos, primero elimina tus posteos.', success: 'Aceptar', warning: ''})
    } else {
      setModalData({title: '¿Esta seguro que desea eliminar?', text: '', success: 'Cancelar', warning: 'Eliminar'})
    }
    setShow(true)
  }

  const actionDelete = (id: any) => {
    dispatch(delete_group_posts({ token, id }))
    navigate('/app/content-management/manage-post')
    setShow(false)
  }

  return (
    <Provider>
      <Breadcrumb items={[index, contentManagement, managePost]} />
      <Modal
          show={show}
          text={modalData.text}
          title={modalData.title}
          btnSuccess={modalData.success}
          btnWarning={modalData.warning}
          onClickWarning={() => {actionDelete(id)}}
          onClickSuccess={() => {setShow(false)}}
          onClickClose={() => {setShow(false)}}
      />
      <div css={styles.root}>
        <div css={styles.main}>
          <ManageTable
              title={ detail_posts?.title}
              subtitle={detail_posts?.description}
              columns={columns}
              data={ post ? post : []}
              cycle={[]}
              management={[]}
              stateCategory={false}
          />
        </div>
        <div css={styles.right}>
          <CreateSide
              title={'Editar grupo de posteos'}
              message="Massa vulputate id placerat vitae ornare a orci. Gravida augue viverra pretium adipiscing mauris."
              textButton={'Editar grupo de posteos'}
              onCreate={() => navigate(`/app/content-management/edit-group-post/${id}`)}
          />
          <CreateSide
              title={'Eliminar grupo de posteos'}
              message="Massa vulputate id placerat vitae ornare a orci. Gravida augue viverra pretium adipiscing mauris."
              textButton={'Eliminar grupo de posteos'}
              onCreate={() => handleGroupDelete(id)}
              />
        </div>
      </div>
    </Provider>
  )
}

export default ManagePosts