import { GET_DRAWER_ERROR, GET_DRAWER_START, GET_DRAWER_SUCCESS } from "../../types";

export default function drawer(state, action) {
  switch (action.type) {
    case GET_DRAWER_START:
      return {
        ...state,
        data: [],
        loading: true,
        error: null
      };
    case GET_DRAWER_SUCCESS:
      return {
        ...state,
        data: action.results,
        loading: false,
        error: null
      };
    case GET_DRAWER_ERROR:
      return {
        ...state,
        data: [],
        loading: false,
        error: action.detail
      };
    default:
      return { ...state };
  }
}
