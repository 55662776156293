import { css } from '@emotion/react'
import { StylesConfig } from 'react-select'
import { ThemeUI } from '../../../theme'
import { Option, StyleProps } from './types'

type Props = { theme: ThemeUI } & StyleProps

export const rootContainer = css`
  display: flex;
  flex-direction: column;
`
export const label = ({ theme }: Props) => css`
  font-size: 9px;
  line-height: 11px;
  margin-bottom: 6px;
  text-transform: uppercase;
`

export const hint = ({ theme, success, error }: Props) => css`
  font-size: 9px;
  line-height: 11px;
  ${success && (`color: ${theme.palette.success};`)}
  ${error && (`color: ${theme.palette.danger};`)}
  margin-top: 6px;
  text-align: right;
`

type StyleSelectProps = (props: Props) => StylesConfig<Option>
export const select: StyleSelectProps = ({ theme, success, error }) => ({
  valueContainer: (base, state) => ({
    ...base,
    borderRadius: 3,
    padding: '8px 18px',
    backgroundColor: state.isDisabled ? theme.palette.secondary10 :
      success ? theme.palette.successLight : error ? theme.palette.dangerLight : theme.palette.white,
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  control: (base) => ({
    ...base,
    borderColor: theme.palette.secondary70,
    fontFamily: "Lato, 'sans-serif'",
    fontSize: 12,
    lineHeight: '14.4px',
  }),
  placeholder: (base) => ({
    ...base,
    whiteSpace: 'nowrap',
  }),
  option: (base) => ({
    ...base,
    fontSize: 12,
  })
})