import { CREATE_NATURE_MAGAZINE_ERROR, CREATE_NATURE_MAGAZINE_START, CREATE_NATURE_MAGAZINE_SUCCESS } from "../../types";

export default function create_nature_magazine(state, action) {
    switch (action.type) {
        case CREATE_NATURE_MAGAZINE_START:
            return {
                ...state,
                loading: true,
                error: null
            };
        case CREATE_NATURE_MAGAZINE_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null
            };
        case CREATE_NATURE_MAGAZINE_ERROR:
            return {
                ...state,
                loading: false,
                error: action.detail
            };
        default:
            return { ...state };
    }
}
