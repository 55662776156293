/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import React, {FC, useContext, useEffect, useState} from 'react'

import { Context } from '../../../context/ManagementContext'
import {BaseInput, SwitchInput, Text} from '../../atoms'
import { ContentSection } from '../../organisms'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux";
import { get_one_management } from "../../../redux/actions/management/get_one_management";

const validateNumber = (data: string) =>
    /^[0-9]+$/.test(data)

const NewForm: FC = () => {
    const { formData, handleChangeFormData:  handleChange, handleUpdateFormData: handleData } = useContext(Context)

    const dispatch = useDispatch()
    let { id } = useParams();
    const token = useSelector((state: any) => state.login.access)
    const data = useSelector((state: any) => state.get_one_management.data)

    useEffect(() => {
        if (!!id) {
            dispatch(get_one_management({ token, id }))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])

    useEffect(() => {
        if (!!id) {
            handleData(data)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, data])

    const [isIdValid, setIdValid] = useState<boolean>(false)
    const [isManagementValid, setIsManagementValid] = useState<boolean>(false)
    const [isRegionValid, setIsRegionValid] = useState<boolean>(false)
    const [isSectorValid, setIsSectorValid] = useState<boolean>(false)

    useEffect(() => {
        setIdValid(validateNumber(formData.id))
    }, [formData.id])
    useEffect(() => {
        setIsManagementValid(formData.management.length > 2)
    }, [formData.management])
    useEffect(() => {
        setIsRegionValid(formData.region.length > 2)
    }, [formData.region])
    useEffect(() => {
        setIsSectorValid(validateNumber(formData.sector))
    }, [formData.sector])

    return (
        <ContentSection style={css`display: grid;gap: 18px;`}
            header={
                <React.Fragment>
                    <Text textType="h4" weight="black">
                        {id ? 'Editar gerencia':'Nueva gerencia'}
                    </Text>
                </React.Fragment>
            }
        >
            <BaseInput label="Id"
                       value={formData.id}
                       disabled={!!id}
                       onChange={(value) => handleChange({ id: value })}
                       placeholder="Escribe el id de la gerencia"
                       maxLength={30}
                       error={!isIdValid && formData.id.length > 0}
                       success={isIdValid}
                       hint={!isIdValid && formData.id.length > 0 ? 'Digite numeros' : ''}
            />
            <BaseInput label="Nombre de gerencia"
                       value={formData.management}
                       onChange={(value) => handleChange({ management: value })}
                       placeholder="Escribe el nombre de la gerencia"
                       maxLength={100}
                       error={!isManagementValid && formData.management.length > 0}
                       success={isManagementValid}
                       hint={!isManagementValid && formData.management.length > 0 ? 'Digite letras' : ''}
            />
            <BaseInput label="Region"
                       value={formData.region}
                       onChange={(value) => handleChange({ region: value })}
                       placeholder="Escribe el nombre de la region"
                       maxLength={100}
                       error={!isRegionValid && formData.region.length > 0}
                       success={isRegionValid}
                       hint={!isRegionValid && formData.region.length > 0 ? 'Digite letras' : ''}
            />
            <BaseInput label="Sector"
                       value={formData.sector}
                       onChange={(value) => handleChange({ sector: value })}
                       placeholder="Escribe el nombre del sector"
                       maxLength={30}
                       error={!isSectorValid && formData.sector.length > 0}
                       success={isSectorValid}
                       hint={!isSectorValid && formData.sector.length > 0 ? 'Digite numeros' : ''}
            />
            <SwitchInput checked={formData.active}
                         onCheckedChange={(value) => handleChange({ active: value })}
                         label={formData.active ? 'Activo': 'Inactivo'}
            />
        </ContentSection>
    )
}

export default NewForm