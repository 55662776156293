/** @jsxImportSource @emotion/react */
import React, {useEffect, useState} from 'react'
import {Button, Text} from '../../atoms'
import { ContentSection } from '../../organisms'
import {TableBaseFCM} from "../../molecules";
import {Column} from "react-table";
import * as styles from "../../molecules/TableBase/index.styles";
import {css} from "@emotion/react";

type Props = {
  title: string,
  subtitle: string,
  onClickApply?: () => void,
  onSearch?: (search: string) => void,
  columns: Column<any>[],
  data: any[],
}

const ManagementDataForm: React.FC<Props> = ({
  title,
  subtitle,
  onClickApply = () => { },
  onSearch = () => { },
  columns,
  data,
}) => {
    const [page, setPage] = useState(1);
    const size = 10;
    const total = data.length;
    const [rows, setRows] = useState(data.slice((page - 1) * size, (page * size)))

    const changeToPage = (page: number) => {
        setPage(page)
        setRows(data.slice((page - 1) * size, (page * size)))
    }

    useEffect(() => {
        setRows(data.slice((page - 1)*size, (page * size)))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

  return (
    <ContentSection
      header={
        <React.Fragment>
            <Text textType='h4' weight='black'>{title}</Text>
            <Text textType='p' weight='bold'>{subtitle}</Text>
        </React.Fragment>
      }
    >
      <TableBaseFCM columns={columns} data={rows} />

      <div css={styles.actionsRigth}>
        <Button style={css({ width: '55px', margin: '0 0 0 5px' })} disabled={page === 1} onClick={() => changeToPage(1)}> {'<<'} </Button>
        <Button style={css({ width: '55px', margin: '0 0 0 5px' })} disabled={page === 1} onClick={() => changeToPage(page - 1)}>
          {'<'}
        </Button>
        <Button style={css({ width: '55px', margin: '0 0 0 5px' })} onClick={() => changeToPage(page + 1)} disabled={page === Math.ceil(data.length / size)}>
          {'>'}
        </Button>
        <Button style={css({ width: '55px', margin: '0 0 0 5px' })} disabled={page >= Math.ceil(data.length / size)} onClick={() => changeToPage(Math.ceil(data.length / size))}>
          {'>>'}
        </Button>
      </div>

      <div css={styles.actions}>
        <p>Pagina {page} de {Math.ceil(data.length / size)}</p>
        <p>Total item {total}</p>
      </div>

    </ContentSection>
  )
}

export default ManagementDataForm
