/** @jsxImportSource @emotion/react */
import React from 'react'
import * as styles from './index.styles'
import { Interpolation } from '@emotion/css'

export type Props = {
  style?: Interpolation
  action?: React.ReactNode
}

const HeaderSection: React.FC<Props> = ({ children, action, style }) => (
  <div css={(theme: any) => styles.root({ theme, style })}>
    <div>{children}</div>
    {action && <div>{action}</div>}
  </div>
)

export default HeaderSection
