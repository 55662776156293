/** @jsxImportSource @emotion/react */
import React, { Fragment, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { CreateSide, ManageTable } from '../../../components/templates'
import * as styles from '../index.styles'
import { breadcrumbItems } from '../../utils'
import { Breadcrumb, Button, FileInput, Text } from '../../../components/atoms'
import { useDispatch, useSelector } from 'react-redux'
import { fcm_history } from '../../../redux/actions/fcm/fcm_history'
import {columns} from "./mockData";
import { css } from '@emotion/react';
import { fcm_send_csv } from '../../../redux/actions/fcm/fcm_send_csv'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CardSection } from '../../../components/molecules'

export const root = css`
  padding: 30px;
`
const { index, serviceManagement, manageNotificationsFCM } = breadcrumbItems

const ManageFCM: React.FC = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
	const [selectedFile, setSelectedFile] = useState(undefined);
	const [image, setImage] = useState(undefined);
  const token = useSelector((state: any) => state.login.access)
  const data_fcm_devices = useSelector((state: any) => state.fcm_devices.data)
  const data_fcm_history = useSelector((state: any) => state.fcm_history)
  const location = useLocation();

  const data = data_fcm_history?.data?.map((hist: any) => {
    if (data_fcm_devices !== []) {
      let item = data_fcm_devices.find((dev: any) => hist.user_id === dev.name)
      hist.category = item?.category
      hist.cycle = item?.cycle
      hist.management = item?.management
    }
    return hist
  }) || []

  const cycleData = useSelector((state: any) => {
    return state.cycle.data.map((v:any) => {
      return { value: v.cycle, label: v.cycle }
    })
  })
  cycleData.push({ value: 'Ninguno', label: 'Ninguno'})

  const managementData = useSelector((state: any) => {
    return state.management.data.map((v:any) => {
      return { value: v.id, label: v.management }
    })
  })
  managementData.push({ value: 'Ninguno', label: 'Ninguno'})

  useEffect(() => {
    dispatch(fcm_history({ token }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  const changeHandler = (event: any) => {
    console.log(event)
		setSelectedFile(event);
	};

  const changeImageHandler = (event: any) => {
    console.log(event)
		setImage(event);
	};

  const handleUploadCSV = (formData: any, image: any) => {
    if (formData) {
      dispatch(fcm_send_csv({ token, formData, image, handleNotification}))
    } else {
      toast.error('Debe cargar un archivo csv!', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        })
  
    }
  }

  const handleNotification = () => {
    toast.success('Archivo cargado correctamente!', {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      })
  }

  return (
    <Fragment>
      <Breadcrumb items={[index, serviceManagement, manageNotificationsFCM]} />
      <div css={styles.root}>
        <div css={styles.main}>
          <ManageTable
            title="Administrar notificaciones push - FCM"
            subtitle="Notificaciones publicadas"
            columns={columns}
            data={data || []}
            cycle={cycleData || []}
            management={managementData || []}
            stateCategory={true}
          />
        </div>
        <div css={styles.right} >
          <CreateSide
            title="Enviar nueva notificación"
            message="Massa vulputate id placerat vitae ornare a orci. Gravida augue viverra pretium adipiscing mauris."
            textButton="Enviar nueva notificación"
            onCreate={() =>
              navigate('/app/service-management/send-notification')
            }
          />  
          <br />
          <CardSection padding="24px" >
            <FileInput
                label='Enviar notificaciones desde un archivo csv. Formato: (usuario, titulo, mensaje)'
                value={selectedFile}
                onChange={changeHandler}
                accept='csv/*'
            />
            <FileInput
                label='Imagen de mensaje'
                value={image}
                onChange={changeImageHandler}
                accept='image/*'
            />
            <br />
            <Button onClick={() => handleUploadCSV(selectedFile, image)}>
              <Text>
                Subir archivo
              </Text>
            </Button>
          </CardSection>
        </div>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          />
      </div>
    </Fragment>
  )
}

export default ManageFCM
