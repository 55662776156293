import { takeLatest, put, call } from 'redux-saga/effects'
import apiCall from '../../api'
import { CREATE_OFFERS_ERROR, CREATE_OFFERS_START, CREATE_OFFERS_SUCCESS } from "../../types"

export function* create({ payload }) {
  try {
    const url = '/offers_by_cycle/'
    const method = 'POST'
    const headers = { Authorization: 'Bearer ' + payload.token, 'Content-Type': 'multipart/form-data' }
    const data = (object) => Object.keys(object).reduce((formData, key) => {
      formData.append(key, object[key])
      return formData
    }, new FormData())
    const results = yield call(apiCall, url, method, data(payload.formData), headers)
    yield put({ type: CREATE_OFFERS_SUCCESS, results: results.data })
    yield put(payload.navigate('/app/content-management/manage-offers-by-cycle'))
  } catch (error) {
    yield put({ type: CREATE_OFFERS_ERROR, error })
  }
}

export default function* create_offers() {
  yield takeLatest(CREATE_OFFERS_START, create)
}
