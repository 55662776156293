/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react'
import React, {useContext} from 'react'
import { ThemeUI } from '../../../theme'
import { Button, Card, Text } from '../../atoms'
import { ContentSection } from '../../organisms'
import * as styles from './index.styles'
import {Context} from "../../../context/BannerContext";
import {useDispatch, useSelector} from "react-redux";
import {create_banner} from "../../../redux/actions/banner/create_banner";
import {update_banner} from "../../../redux/actions/banner/update_banner";
import {useNavigate, useParams} from "react-router-dom";

const NewPreview: React.FC = () => {
  const theme = useTheme() as ThemeUI
  const navigate = useNavigate();
  const APP_URL = process.env.REACT_APP_URL
  const { formData } = useContext(Context)
  const dispatch = useDispatch()
  const token = useSelector((state: any) => state.login.access)
  let { id } = useParams();

  const handleBanners = (token: any, formData: any) => {
    if (id) {
      if (!formData.image?.name) {
        formData.image = formData.image.indexOf('/media/') > -1 ? undefined : formData.image
      }
      if (!formData.image_mobile?.name) {
        formData.image_mobile = formData.image_mobile.indexOf('/media/') > -1 ? undefined : formData.image_mobile
      }
      if (!formData.image_description?.name) {
        formData.image_description = formData.image_description.indexOf('/media/') > -1 ? undefined : formData.image_description
      }
      dispatch(update_banner({ token, formData, navigate }))
    } else {
      dispatch(create_banner({ token, formData, navigate }))
    }
  }

  return (
    <ContentSection
      style={styles.root}
      header={
        <Text textType='p' weight='bold'>Vista Previa</Text>
      }
    >
      <div css={(theme: any) => styles.preview(theme)}>
        <Card style={css({ overflow: 'hidden' })}>
          {formData.is_image ? (
            <img
              src={formData.image ? (formData.image?.name ? URL.createObjectURL(formData.image): APP_URL+''+formData.image) : 'https://via.placeholder.com/600x200'}
              alt='placeholder'
              css={styles.previewImage}
            />
          ) : (
            <video width="100%" controls>
              <source
                src={formData.image ? (formData.image?.name ? URL.createObjectURL(formData.image): APP_URL+''+formData.image) : 'https://via.placeholder.com/600x200'}
                type="video/mp4" />
            </video>
          )}
          {formData.is_image_mobile ? (
            <img
            src={formData.image_mobile ? (formData.image_mobile?.name ? URL.createObjectURL(formData.image_mobile): APP_URL+''+formData.image_mobile) : 'https://via.placeholder.com/300x300'}
            alt='placeholder'
            css={styles.previewImage}
            />
          ) : (
            <video width="100%" controls>
              <source
                src={formData.image ? (formData.image_mobile?.name ? URL.createObjectURL(formData.image_mobile): APP_URL+''+formData.image_mobile) : 'https://via.placeholder.com/600x200'}
                type="video/mp4" />
            </video>
          ) }
          {formData.is_image_mobile ? (
            <img
              src={formData.image_description ? (formData.image_description?.name ? URL.createObjectURL(formData.image_description): APP_URL+''+formData.image_description) : 'https://via.placeholder.com/300x600'}
              alt='placeholder'
              css={styles.previewImage}
            />
          ) : (
            <video width="100%" controls>
              <source
                src={formData.image_description ? (formData.image_description?.name ? URL.createObjectURL(formData.image_description): APP_URL+''+formData.image_description) : 'https://via.placeholder.com/600x200'}
                type="video/mp4" />
            </video>
          ) }
          <Text textType='sm' style={styles.previewTitle(theme)}>
            {formData.title}
          </Text>
          <div css={styles.previewDescription(theme)}>
            <Text textType='sm' weight='light'>
              {formData.description}
            </Text>
            <Text textType='sm' weight='light'>
              {formData.category}
            </Text>
            <Text textType='sm' weight='light'>
              {formData.cycle}
            </Text>
          </div>
        </Card>
      </div>
      <Button onClick={() => handleBanners(token, formData)} disabled={!formData.title || !formData.image || !formData.image_mobile || !formData.image_description || !formData.category || !formData.cycle}>
        {!id ? 'Publicar' : 'Guardar'} banner
      </Button>
    </ContentSection>
  )
}


export default NewPreview
