import {takeLatest, put, call} from 'redux-saga/effects';
import apiCall from '../../api';
import {DELETE_QUESTIONS_ERROR, DELETE_QUESTIONS_START, DELETE_QUESTIONS_SUCCESS} from "../../types";

export function* deleteQuestions({payload}) {
    try {
        const url = `/questions/${payload.id}/`;
        const method = 'DELETE';
        const headers = {Authorization: 'Bearer ' + payload.token, 'Content-Type':'application/json'};
        const results = yield call(apiCall, url, method, payload.formData, headers);
        yield put({type: DELETE_QUESTIONS_SUCCESS, results: results.data});
    } catch (error) {
        yield put({type: DELETE_QUESTIONS_ERROR, error});
    }
}

export default function* delete_questions() {
    yield takeLatest(DELETE_QUESTIONS_START, deleteQuestions);
}