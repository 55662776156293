import {takeLatest, put, call} from 'redux-saga/effects';
import apiCall from '../../api';
import {CREATE_POSTS_ERROR, CREATE_POSTS_START, CREATE_POSTS_SUCCESS} from "../../types";

export function* create({payload}) {
  try {
    const url = '/posts/';
    const method = 'POST';
    const headers = {Authorization: 'Bearer ' + payload.token, 'Content-Type':'multipart/form-data'};
    const data = (object) => Object.keys(object).reduce((formData, key) => {
      formData.append(key, object[key]);
      return formData;
    }, new FormData());
    const results = yield call(apiCall, url, method, data(payload.formData), headers);
    yield put({type: CREATE_POSTS_SUCCESS, results: results.data});
    yield put(payload.navigate('/app/content-management/manage-post'))
  } catch (error) {
    yield put({type: CREATE_POSTS_ERROR, error});
  }
}

export default function* create_post() {
  yield takeLatest(CREATE_POSTS_START, create);
}
