import {takeLatest, put, call} from 'redux-saga/effects';
import apiCall from '../../api';
import {CREATE_NEWS_ERROR, CREATE_NEWS_START, CREATE_NEWS_SUCCESS} from "../../types";

export function* create({payload}) {
  try {
    const url = '/news/';
    const method = 'POST';
    const headers = {Authorization: 'Bearer ' + payload.token, 'Content-Type':'application/json'};
    const data = (object) => Object.keys(object).reduce((formData, key) => {
      formData.append(key, object[key]);
      return formData;
    }, new FormData());
    const results = yield call(apiCall, url, method, data(payload.formData), headers);
    yield put({type: CREATE_NEWS_SUCCESS, results: results.data});
    yield put(payload.navigate('/app/content-management/manage-group-news'));
  } catch (error) {
    yield put({type: CREATE_NEWS_ERROR, error});
  }
}

export default function* create_news() {
  yield takeLatest(CREATE_NEWS_START, create);
}
