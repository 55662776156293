import {takeLatest, put, call} from 'redux-saga/effects';
import {GET_GROWTH_PATH_ERROR, GET_GROWTH_PATH_START, GET_GROWTH_PATH_SUCCESS} from "../../types";

import apiCall from '../../api/apiGet';

export function* getGP({payload}) {
  try {
    const url = '/growthpath/';
    const method = 'GET';
    const headers = {Authorization: 'Bearer ' + payload.token};
    const results = yield call(apiCall, url, method, headers);
    yield put({type: GET_GROWTH_PATH_SUCCESS, results: results.data});
  } catch (error) {
    yield put({type: GET_GROWTH_PATH_ERROR, error});
  }
}

export default function* growth_path() {
  yield takeLatest(GET_GROWTH_PATH_START, getGP);
}
