import {takeLatest, put, call} from 'redux-saga/effects';
import apiCall from '../../api';
import { DELETE_DRAWER_ERROR, DELETE_DRAWER_START, DELETE_DRAWER_SUCCESS } from "../../types";

export function* deleteDrawer({payload}) {
    try {
        const url = `/drawer/${payload.id}/`;
        const method = 'DELETE';
        const headers = {Authorization: 'Bearer ' + payload.token, 'Content-Type':'application/json'};
        const results = yield call(apiCall, url, method, payload.formData, headers);
        yield put({type: DELETE_DRAWER_SUCCESS, results: results.data});
    } catch (error) {
        yield put({type: DELETE_DRAWER_ERROR, error});
    }
}

export default function* delete_drawer() {
    yield takeLatest(DELETE_DRAWER_START, deleteDrawer);
}