/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import React, {FC, useContext, useEffect, useState} from 'react'
import { Context } from '../../../context/GroupPostContext'
import {BaseInput, FileInput, SwitchInput, Text, TextareaInput} from '../../atoms'
import { ContentSection } from '../../organisms'
import {useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import Select from "react-select";
import * as styles from "../Header/index.styles";
import {CATEGORIES} from "../../../category";

const validateNumber = (data: string) =>
    /^[0-9]+$/.test(data)

const PostGroupForm: FC = () => {
  const { formData, handleChangeFormData: handleChange, handleUpdateFormData: handleData } = useContext(Context)
  let { id } = useParams()
  const group_data = useSelector((state: any) => state.group_detail_posts.data)

  const managements_list = useSelector((state: any) => state.management.data)
  const managementInit = group_data?.management && id ? managements_list.filter((r:any) => group_data?.management.indexOf(r.id) > -1) : null
  const categoryInit = group_data?.category && id ? { value: group_data.category, label: group_data.category } : { value: '', label: '' }
  const [state, setState] = useState<Array<number>>([]);
  useEffect(() => {
    if (!!id) {
      handleData(group_data)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, group_data])

  const handleCategory = (e: any) => {
    handleChange({ category: e.value })
  }

  /*const handleManagement = (e: any) => {
    let temp: any[] = []
    e.forEach((item: { id: any }, index: any) => {
      temp.push(item.id)
    })
    handleChange({ management: temp })
  }*/
  const handleManagement = (e: any) => {
    let temp: any[] = []
    e.forEach((item: { id: any }, index: any) => {
      temp.push(item.id)
    })
    console.log(temp)
    handleChange({ management: temp })

    // handleChange({management: e.value})
  }

  // const postManagementData = (managementA: any[]) => {
  //   return managementA.map((v:any) => {
  //     return { value: v.id, label: v.management }
  //   })
  // }

  const [isTitleValid, setTitleValid] = useState<boolean>(false)
  const [isDescriptionValid, setIsDescriptionValid] = useState<boolean>(false)
  const [isCycleValid, setIsCycleValid] = useState<boolean>(false)

  useEffect(() => {
    if (group_data !== undefined && group_data.posts) {
      group_data.posts.map((e: any) => setState(state => [...state, e.id]))
      // eslint-disable-next-line
      if (id) {
        formData.posts = state
      } else {
        formData.posts = []
      }
    }
    setTitleValid(formData.title.length > 2)
    // eslint-disable-next-line
  }, [formData.title])
  useEffect(() => {
    setIsDescriptionValid(formData.description.length > 2)
  }, [formData.description])
  useEffect(() => {
    setIsCycleValid(validateNumber(formData.cycle))
  }, [formData.cycle])

  return (
    <ContentSection
      style={css`display: grid; gap: 18px;`}
      header={
        <React.Fragment>
          <Text textType='h4' weight='black'>{id ? 'Editar' : 'Nuevo'}  grupo de posteos</Text>
        </React.Fragment>
      }
    >
      <BaseInput
        label='Título'
        value={formData.title}
        onChange={(value) => handleChange({ title: value })}
        placeholder='Escribe el titulo de tu entrada'
        maxLength={500}
        error={!isTitleValid && formData.title.length > 0}
        success={isTitleValid}
        hint={!isTitleValid && formData.title.length > 0 ? 'Digite mas de dos letras' : ''}
      />
      <TextareaInput
        label='Descripción'
        value={formData.description}
        onChange={(value) => handleChange({ description: value })}
        placeholder='Escribe el contenido de tu entrada'
        maxLength={1500}
        error={!isDescriptionValid && formData.description.length > 0}
        success={isDescriptionValid}
        hint={!isDescriptionValid && formData.description.length > 0 ? 'Digite mas de dos letras' : ''}
      />
      <BaseInput
        label="Ciclo"
        placeholder="Escribe un código de  ciclo"
        value={formData.cycle}
        onChange={(value) => handleChange({ cycle: value })}
        error={!isCycleValid && formData.cycle.length > 0}
        success={isCycleValid}
        hint={!isCycleValid && formData.cycle.length > 0 ? 'Digite numeros' : ''}
      />
      <Text textType="label">
        CATEGORÍA :{' '}
        <Select css={styles.input}
                options={CATEGORIES}
                placeholder="Selecciona una categoría"
                defaultValue={categoryInit}
                onChange={(e) =>  handleCategory(e)}
        />
      </Text>
      {/* <div style={{ width: 360 }}>
        <SelectInput
            isMulti
            label='Gerencia  '
            options={postManagementData(managements_list)}
            placeholder='Selecciona una gerencia'
            onChange={(e) =>  handleManagement(e)}
        />
      </div> */}
      <Text textType="label">
        GERENCIA :{' '}
        <Select css={styles.input}
          isMulti
          placeholder="Selecciona opciones de gerencia"
          options={managements_list}
          defaultValue={managementInit}
          getOptionValue={(option: any) => `${option['id']}`}
          getOptionLabel={(option: any) => `${option['management']}`}
          onChange={(e) =>  handleManagement(e)}
        />
      </Text>
      <div style={{ width: 360 }}>
        <FileInput
          label={formData.is_cover ? 'Imagen' : 'Video'}
          value={formData.cover}
          onChange={(value) => handleChange({ cover: value })}
          accept={formData.is_cover ? 'image/*' : 'video/*'}
        />
      </div>
      <SwitchInput checked={formData.is_cover}
        onCheckedChange={(value) => handleChange({ is_cover: value })}
        label={formData.is_cover ? 'Subir Imagen': 'Subir Video'}
      />

      <SwitchInput checked={formData.active}
        onCheckedChange={(value) => handleChange({ active: value })}
        label={formData.active ? 'Activo': 'Inactivo'}
      />
    </ContentSection>
  )
}

export default PostGroupForm
