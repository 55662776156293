/** @jsxImportSource @emotion/react */
import React from 'react'
import { CheckboxProps } from './types'
import * as PrimitiveCheckbox from '@radix-ui/react-checkbox'
import * as styles from './index.styles'
import CheckIcon from './CheckIcon'

const Checkbox: React.FC<CheckboxProps> = ({
  children,
  disabled,
  checked,
  onChange,
  defaultChecked,
  style = {},
}) => {
  return (
    <div css={[styles.container, style]}>
      <PrimitiveCheckbox.Root
        css={styles.root}
        checked={checked}
        defaultChecked={defaultChecked}
        onCheckedChange={onChange}
        disabled={disabled}
      >
        <PrimitiveCheckbox.CheckboxIndicator>
          <CheckIcon />
        </PrimitiveCheckbox.CheckboxIndicator>
      </PrimitiveCheckbox.Root>
      {children && <label>{children}</label>}
    </div>
  )
}

export default Checkbox
