export const columns = [
    {
        Header: 'Titulo',
        accessor: 'title',
    },
    {
        Header: 'Descripción',
        accessor: 'description',
    },
    {
        Header: 'Fecha de publicación',
        accessor: 'created',
    },
    {
        Header: 'Imagen',
        accessor: 'image',
    },
    {
        Header: 'Editar',
        accessor: 'editNews',
    },
    {
        Header: 'Eliminar',
        accessor: 'deleteNews',
    },
    {
        Header: 'Estado',
        accessor: 'active',
    },
]
