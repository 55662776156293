import {takeLatest, put, call} from 'redux-saga/effects';
import apiCall from '../../api';
import {POST_FCM_SEND_ERROR, POST_FCM_SEND_START, POST_FCM_SEND_SUCCESS} from "../../types";

export function* create({payload}) {
  try {
    const url = '/send_fcm/';
    const method = 'POST';
    const headers = {Authorization: 'Bearer ' + payload.token, 'Content-Type':'application/json'};
    const data = (object) => Object.keys(object).reduce((formData, key) => {
      if (key === 'registration_id') {
        formData.append(key, JSON.stringify(object[key]));
      } else {
        formData.append(key, object[key]);
      }
      return formData;
    }, new FormData());
    const results = yield call(apiCall, url, method, data(payload.formData), headers);
    yield put({type: POST_FCM_SEND_SUCCESS, results: results.data});
    yield put(payload.navigate('/app/service-management/manage-fcm'));
  } catch (error) {
    yield put({type: POST_FCM_SEND_ERROR, error});
  }
}

export default function* fcm_send() {
  yield takeLatest(POST_FCM_SEND_START, create);
}
