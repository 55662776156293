import * as React from "react"

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  const color = props.color || '#34189A'
  return (
    <svg
      width={12}
      height={12}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.243 1.757a6 6 0 10-8.486 8.485 6 6 0 008.486-8.485zM6 11.196A5.202 5.202 0 01.804 6 5.202 5.202 0 016 .804 5.202 5.202 0 0111.196 6 5.202 5.202 0 016 11.196z"
        fill={color}
      />
      <path
        d="M5.096 7.258L3.25 5.413l-.569.569 2.414 2.413 4.21-4.21-.57-.568-3.64 3.641z"
        fill={color}
      />
    </svg>
  )
}

export default React.memo(SvgComponent)
