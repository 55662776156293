import { createContext, FC, useState } from 'react'

export type Data = {
  username: string
  first_name: string
  last_name: string
  is_staff: boolean
  is_active: boolean
  user_permissions: object
}
export interface DataContext {
  formData: Data
  handleChangeFormData: (data: Partial<Data>) => void,
  handleUpdateFormData: (data: Partial<Data>) => void,
}

const initialData: Data = {
  username: '',
  last_name: '',
  first_name: '',
  is_staff: true,
  is_active: true,
  user_permissions: []
}

export const Context = createContext<DataContext>({
  formData: initialData,
  handleChangeFormData: (data: Partial<Data>) => {},
  handleUpdateFormData: (data: Partial<Data>) => {},
})

const NewFormDataProvider: FC = (props) => {
  const [formData, setFormData] = useState<Data>(initialData)

  const handleChangeFormData = (data: Partial<Data>) => {
    setFormData({ ...formData, ...data })
  }

  const handleUpdateFormData = (data: Partial<Data>) => {
    if (data && data?.username) {
      setFormData({
        username: data.username,
        last_name: data.last_name || initialData.last_name,
        first_name: data.first_name || initialData.first_name,
        is_staff: data.is_staff || initialData.is_staff,
        is_active: data.is_active || initialData.is_active,
        user_permissions: data.user_permissions || initialData.user_permissions,
      })
    }
  }
  return (
    <Context.Provider value={{ formData, handleChangeFormData, handleUpdateFormData }}>
      {props.children}
    </Context.Provider>
  )
}

export default NewFormDataProvider
