import {DELETE_MANAGEMENT_ERROR, DELETE_MANAGEMENT_START, DELETE_MANAGEMENT_SUCCESS} from "../../types";

export default function delete_management(state, action) {
    switch (action.type) {
        case DELETE_MANAGEMENT_START:
            return {
                ...state,
                loading: true,
                error: null
            };
        case DELETE_MANAGEMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null
            };
        case DELETE_MANAGEMENT_ERROR:
            return {
                ...state,
                loading: false,
                error: action.detail
            };
        default:
            return {...state};
    }
}
