import {CREATE_GROUP_NEWS_ERROR, CREATE_GROUP_NEWS_START, CREATE_GROUP_NEWS_SUCCESS} from "../../types";

export default function create_group_news(state, action) {
  switch (action.type) {
    case CREATE_GROUP_NEWS_START:
      return {
        ...state,
        loading: true,
        error: null
      };
    case CREATE_GROUP_NEWS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null
      };
    case CREATE_GROUP_NEWS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.detail
      };
    default:
      return {...state};
  }
};
