import { CREATE_QUESTIONS_ERROR, CREATE_QUESTIONS_START, CREATE_QUESTIONS_SUCCESS } from "../../types";

export default function create_question(state, action) {
  switch (action.type) {
    case CREATE_QUESTIONS_START:
      return {
        ...state,
        data: [],
        loading: true,
        error: null
      };
    case CREATE_QUESTIONS_SUCCESS:
      return {
        ...state,
        data: action.results,
        loading: false,
        error: null
      };
    case CREATE_QUESTIONS_ERROR:
      return {
        ...state,
        data: [],
        loading: false,
        error: action.detail
      };
    default:
      return { ...state };
  }
}
