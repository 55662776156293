const typography = {
  types: {
    h1: {
      fontSize: 50,
      lineHeight: '60px',
    },
    h2: {
      fontSize: 38,
      lineHeight: '46px',
    },
    h3: {
      fontSize: 28,
      lineHeight: '34px',
    },
    h4: {
      fontSize: 21,
      lineHeight: '25px',
    },
    p: {
      fontSize: 16,
      lineHeight: '19px',
    },
    sm: {
      fontSize: 12,
      lineHeight: '14px',
    },
    label: {
      fontSize: 9,
      lineHeight: '11px',
    },
    labelSm: {
      fontSize: 7,
      lineHeight: '8px',
    },
  },
  weights: {
    light: 300,
    normal: 400,
    bold: 700,
    black: 900,
  },
}

export default typography