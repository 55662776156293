import * as React from "react"

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={284}
      height={223}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#prefix__clip0_148:1256)" strokeMiterlimit={10}>
        <path
          d="M76.9 165.224v11.9c0 5.6 4.5 10.1 10.1 10.1h85.8c5.6 0 10.1-4.5 10.1-10.1v-85.8c0-5.6-4.5-10.1-10.1-10.1H87c-5.6 0-10.1 4.5-10.1 10.1v59.7l26.5-23.6 18.2 18.2 30.3-25.7-9-7.6 30.2-4.5-4.6 26.5-7.5-6.8-38 33.8-19.6-19.5-26.5 23.4z"
          stroke="url(#prefix__paint0_linear_148:1256)"
          strokeWidth={1.514}
        />
        <path
          d="M182.9 100.224h-106"
          stroke="url(#prefix__paint1_linear_148:1256)"
          strokeWidth={1.514}
        />
        <path
          d="M170.8 93.324a3 3 0 100-6 3 3 0 000 6z"
          stroke="url(#prefix__paint2_linear_148:1256)"
          strokeWidth={1.514}
        />
        <path
          d="M161.7 93.324a3 3 0 100-6 3 3 0 000 6z"
          stroke="url(#prefix__paint3_linear_148:1256)"
          strokeWidth={1.514}
        />
        <path
          d="M152.6 93.324a3 3 0 100-6 3 3 0 000 6z"
          stroke="url(#prefix__paint4_linear_148:1256)"
          strokeWidth={1.514}
        />
        <path
          d="M212.9 75.224h6.5M245.9 75.224h6.5M233.1 94.824v-6.1M223.6 57.523c-6.3 3.4-10.7 10-10.7 17.7 0 11 9 20 20 20s20-9 20-20c0-8-4.8-15-11.6-18.2"
          stroke="#3BD3EA"
          strokeWidth={2}
          strokeLinecap="round"
        />
        <path
          d="M232.8 59.623s-4.1 11.9-4.1 15.1c0 3.2 1.8 4.1 4.1 4.1s4.1-.9 4.1-4.1c0-3.2-4.1-15.1-4.1-15.1z"
          stroke="#3BD3EA"
          strokeWidth={2}
          strokeLinecap="round"
        />
        <path
          d="M20.9 40.224h6.5M53.9 40.224h6.5M41.1 59.824v-6.1M31.6 22.523c-6.3 3.4-10.7 10-10.7 17.7 0 11 9 20 20 20s20-9 20-20c0-8-4.8-15-11.6-18.2"
          stroke="#04D99D"
          strokeWidth={2}
          strokeLinecap="round"
        />
        <path
          d="M40.8 24.623s-4.1 11.9-4.1 15.1c0 3.2 1.8 4.1 4.1 4.1s4.1-.9 4.1-4.1c0-3.2-4.1-15.1-4.1-15.1z"
          stroke="#04D99D"
          strokeWidth={2}
          strokeLinecap="round"
        />
        <path d="M79.2 84.924l-25.3-29.7" stroke="#04D99D" strokeWidth={2} />
        <path d="M182.9 109.824l33.3-22.5" stroke="#3BD3EA" strokeWidth={2} />
        <path
          d="M182.9 163.023l42 14.2"
          stroke="url(#prefix__paint5_linear_148:1256)"
          strokeWidth={2}
        />
        <path
          d="M175.8 191.224H84c-6.1 0-11.1-5-11.1-11.1v-91.8c0-6.1 5-11.1 11.1-11.1h91.8c6.1 0 11.1 5 11.1 11.1v91.8c0 6.1-5 11.1-11.1 11.1z"
          stroke="#04D99D"
          strokeWidth={0.237}
          strokeLinecap="round"
        />
        <path
          d="M178.9 195.124h-98c-6.6 0-11.9-5.3-11.9-11.9v-98c0-6.6 5.3-11.9 11.9-11.9h98c6.6 0 11.9 5.3 11.9 11.9v98c0 6.6-5.3 11.9-11.9 11.9z"
          stroke="#1CDDA6"
          strokeWidth={0.239}
          strokeLinecap="round"
        />
        <path
          d="M182.1 199.023H77.8c-7 0-12.6-5.7-12.6-12.6v-104.3c0-7 5.7-12.6 12.6-12.6h104.3c7 0 12.6 5.7 12.6 12.6v104.3c0 6.9-5.7 12.6-12.6 12.6z"
          stroke="#34E0B0"
          strokeWidth={0.241}
          strokeLinecap="round"
        />
        <path
          d="M185.2 202.924H74.7c-7.4 0-13.4-6-13.4-13.4v-110.5c0-7.4 6-13.4 13.4-13.4h110.5c7.4 0 13.4 6 13.4 13.4v110.5c0 7.4-6 13.4-13.4 13.4z"
          stroke="#4CE4B9"
          strokeWidth={0.242}
          strokeLinecap="round"
        />
        <path
          d="M188.3 206.724H71.6c-7.8 0-14.1-6.3-14.1-14.1v-116.7c0-7.8 6.3-14.1 14.1-14.1h116.7c7.8 0 14.1 6.3 14.1 14.1v116.7c0 7.8-6.3 14.1-14.1 14.1z"
          stroke="#63E7C2"
          strokeWidth={0.244}
          strokeLinecap="round"
        />
        <path
          d="M191.4 210.624h-123c-8.2 0-14.9-6.7-14.9-14.9v-123c0-8.2 6.7-14.9 14.9-14.9h123c8.2 0 14.9 6.7 14.9 14.9v123c0 8.2-6.7 14.9-14.9 14.9z"
          stroke="#7BEBCC"
          strokeWidth={0.245}
          strokeLinecap="round"
        />
        <path
          d="M194.5 214.523H65.3c-8.6 0-15.6-7-15.6-15.6v-129.3c0-8.6 7-15.6 15.6-15.6h129.2c8.6 0 15.6 7 15.6 15.6v129.2c.1 8.7-6.9 15.7-15.6 15.7z"
          stroke="#93EFD5"
          strokeWidth={0.247}
          strokeLinecap="round"
        />
        <path
          d="M197.6 218.324H62.2c-9.1 0-16.4-7.3-16.4-16.4v-135.4c0-9.1 7.3-16.4 16.4-16.4h135.4c9.1 0 16.4 7.3 16.4 16.4v135.4c0 9.1-7.3 16.4-16.4 16.4z"
          stroke="#ABF2DE"
          strokeWidth={0.248}
          strokeLinecap="round"
        />
        <path
          d="M200.8 222.224H59.1c-9.5 0-17.2-7.7-17.2-17.2v-141.6c0-9.5 7.7-17.2 17.2-17.2h141.7c9.5 0 17.2 7.7 17.2 17.2v141.7c-.1 9.4-7.8 17.1-17.2 17.1z"
          stroke="#C3F6E7"
          strokeWidth={0.25}
          strokeLinecap="round"
        />
        <path
          d="M232.9 100.724c14.083 0 25.5-11.417 25.5-25.5 0-14.084-11.417-25.5-25.5-25.5s-25.5 11.416-25.5 25.5c0 14.083 11.417 25.5 25.5 25.5z"
          stroke="#3BD3EA"
          strokeWidth={0.25}
        />
        <path
          d="M232.9 104.324c16.071 0 29.1-13.029 29.1-29.1 0-16.072-13.029-29.1-29.1-29.1s-29.1 13.028-29.1 29.1c0 16.071 13.029 29.1 29.1 29.1z"
          stroke="#63DCEE"
          strokeWidth={0.25}
        />
        <path
          d="M232.9 108.024c18.115 0 32.8-14.685 32.8-32.8 0-18.115-14.685-32.8-32.8-32.8-18.115 0-32.8 14.685-32.8 32.8 0 18.115 14.685 32.8 32.8 32.8z"
          stroke="#8AE5F3"
          strokeWidth={0.25}
        />
        <path
          d="M232.9 111.624c20.103 0 36.4-16.297 36.4-36.4 0-20.103-16.297-36.4-36.4-36.4-20.103 0-36.4 16.297-36.4 36.4 0 20.103 16.297 36.4 36.4 36.4z"
          stroke="#B2EEF7"
          strokeWidth={0.25}
        />
        <path
          d="M232.9 115.224c22.091 0 40-17.909 40-40 0-22.092-17.909-40-40-40s-40 17.908-40 40c0 22.091 17.909 40 40 40z"
          stroke="#DAF7FB"
          strokeWidth={0.25}
        />
        <path
          d="M40.9 65.724c14.083 0 25.5-11.417 25.5-25.5 0-14.084-11.417-25.5-25.5-25.5s-25.5 11.416-25.5 25.5c0 14.083 11.417 25.5 25.5 25.5z"
          stroke="#04D99D"
          strokeWidth={0.25}
        />
        <path
          d="M40.9 69.323c16.072 0 29.1-13.028 29.1-29.1 0-16.071-13.029-29.1-29.1-29.1-16.072 0-29.1 13.029-29.1 29.1 0 16.072 13.028 29.1 29.1 29.1z"
          stroke="#3AE1B2"
          strokeWidth={0.25}
        />
        <path
          d="M40.9 73.024c18.115 0 32.8-14.685 32.8-32.8 0-18.115-14.685-32.8-32.8-32.8-18.115 0-32.8 14.685-32.8 32.8 0 18.115 14.685 32.8 32.8 32.8z"
          stroke="#70E9C7"
          strokeWidth={0.25}
        />
        <path
          d="M40.9 76.624c20.103 0 36.4-16.297 36.4-36.4 0-20.103-16.297-36.4-36.4-36.4-20.103 0-36.4 16.297-36.4 36.4 0 20.103 16.297 36.4 36.4 36.4z"
          stroke="#A6F2DC"
          strokeWidth={0.25}
        />
        <path
          d="M40.9 80.224c22.091 0 40-17.909 40-40 0-22.092-17.909-40-40-40s-40 17.908-40 40c0 22.091 17.909 40 40 40z"
          stroke="#DCFAF1"
          strokeWidth={0.25}
        />
        <path
          d="M243.9 207.724c14.083 0 25.5-11.417 25.5-25.5 0-14.084-11.417-25.5-25.5-25.5s-25.5 11.416-25.5 25.5c0 14.083 11.417 25.5 25.5 25.5z"
          stroke="#3BD3EA"
          strokeWidth={0.25}
        />
        <path
          d="M243.9 211.324c16.071 0 29.1-13.029 29.1-29.1 0-16.072-13.029-29.1-29.1-29.1s-29.1 13.028-29.1 29.1c0 16.071 13.029 29.1 29.1 29.1z"
          stroke="#63DCEE"
          strokeWidth={0.25}
        />
        <path
          d="M243.9 215.024c18.115 0 32.8-14.685 32.8-32.8 0-18.115-14.685-32.8-32.8-32.8-18.115 0-32.8 14.685-32.8 32.8 0 18.115 14.685 32.8 32.8 32.8z"
          stroke="#8BE5F3"
          strokeWidth={0.25}
        />
        <path
          d="M243.9 218.624c20.103 0 36.4-16.297 36.4-36.4 0-20.103-16.297-36.4-36.4-36.4-20.103 0-36.4 16.297-36.4 36.4 0 20.103 16.297 36.4 36.4 36.4z"
          stroke="#B4EEF7"
          strokeWidth={0.25}
        />
        <path
          d="M243.9 222.224c22.091 0 40-17.909 40-40 0-22.092-17.909-40-40-40s-40 17.908-40 40c0 22.091 17.909 40 40 40z"
          stroke="#DCF7FB"
          strokeWidth={0.25}
        />
        <path
          d="M223.9 182.224h6.5"
          stroke="url(#prefix__paint6_linear_148:1256)"
          strokeWidth={2}
          strokeLinecap="round"
        />
        <path
          d="M256.9 182.224h6.5"
          stroke="url(#prefix__paint7_linear_148:1256)"
          strokeWidth={2}
          strokeLinecap="round"
        />
        <path
          d="M244.1 201.824v-6.1"
          stroke="url(#prefix__paint8_linear_148:1256)"
          strokeWidth={2}
          strokeLinecap="round"
        />
        <path
          d="M234.6 164.523c-6.3 3.4-10.7 10-10.7 17.7 0 11 9 20 20 20s20-9 20-20c0-8-4.8-15-11.6-18.2"
          stroke="url(#prefix__paint9_linear_148:1256)"
          strokeWidth={2}
          strokeLinecap="round"
        />
        <path
          d="M243.8 166.624s-4.1 11.9-4.1 15.1c0 3.2 1.8 4.1 4.1 4.1s4.1-.9 4.1-4.1c0-3.2-4.1-15.1-4.1-15.1z"
          stroke="url(#prefix__paint10_linear_148:1256)"
          strokeWidth={2}
          strokeLinecap="round"
        />
      </g>
      <defs>
        <linearGradient
          id="prefix__paint0_linear_148:1256"
          x1={76.168}
          y1={134.249}
          x2={183.682}
          y2={134.249}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#04D99D" />
          <stop offset={0.514} stopColor="#F2C4B8" />
          <stop offset={1} stopColor="#3BD3EA" />
        </linearGradient>
        <linearGradient
          id="prefix__paint1_linear_148:1256"
          x1={0}
          y1={0}
          x2={0}
          y2={0}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#04D99D" />
          <stop offset={0.514} stopColor="#F2C4B8" />
          <stop offset={1} stopColor="#3BD3EA" />
        </linearGradient>
        <linearGradient
          id="prefix__paint2_linear_148:1256"
          x1={167.025}
          y1={90.334}
          x2={174.596}
          y2={90.334}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#04D99D" />
          <stop offset={0.514} stopColor="#F2C4B8" />
          <stop offset={1} stopColor="#3BD3EA" />
        </linearGradient>
        <linearGradient
          id="prefix__paint3_linear_148:1256"
          x1={157.939}
          y1={90.334}
          x2={165.511}
          y2={90.334}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#04D99D" />
          <stop offset={0.514} stopColor="#F2C4B8" />
          <stop offset={1} stopColor="#3BD3EA" />
        </linearGradient>
        <linearGradient
          id="prefix__paint4_linear_148:1256"
          x1={148.854}
          y1={90.334}
          x2={156.425}
          y2={90.334}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#04D99D" />
          <stop offset={0.514} stopColor="#F2C4B8" />
          <stop offset={1} stopColor="#3BD3EA" />
        </linearGradient>
        <linearGradient
          id="prefix__paint5_linear_148:1256"
          x1={182.605}
          y1={170.144}
          x2={225.245}
          y2={170.144}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3BD3EA" />
          <stop offset={1} stopColor="#00489C" />
        </linearGradient>
        <linearGradient
          id="prefix__paint6_linear_148:1256"
          x1={0}
          y1={0}
          x2={0}
          y2={0}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3BD3EA" />
          <stop offset={1} stopColor="#00489C" />
        </linearGradient>
        <linearGradient
          id="prefix__paint7_linear_148:1256"
          x1={0}
          y1={0}
          x2={0}
          y2={0}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3BD3EA" />
          <stop offset={1} stopColor="#00489C" />
        </linearGradient>
        <linearGradient
          id="prefix__paint8_linear_148:1256"
          x1={0}
          y1={0}
          x2={0}
          y2={0}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3BD3EA" />
          <stop offset={1} stopColor="#00489C" />
        </linearGradient>
        <linearGradient
          id="prefix__paint9_linear_148:1256"
          x1={222.925}
          y1={183.172}
          x2={264.925}
          y2={183.172}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3BD3EA" />
          <stop offset={1} stopColor="#00489C" />
        </linearGradient>
        <linearGradient
          id="prefix__paint10_linear_148:1256"
          x1={238.675}
          y1={175.22}
          x2={248.925}
          y2={175.22}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3BD3EA" />
          <stop offset={1} stopColor="#00489C" />
        </linearGradient>
        <clipPath id="prefix__clip0_148:1256">
          <path
            fill="#fff"
            transform="translate(.8 .124)"
            d="M0 0h283.2v222.2H0z"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

const MemoSvgComponent = React.memo(SvgComponent)
export default MemoSvgComponent
