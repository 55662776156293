import {UPDATE_MANAGEMENT_START, UPDATE_MANAGEMENT_SUCCESS, UPDATE_MANAGEMENT_ERROR} from "../../types";

export default function update_management(state, action) {
    switch (action.type) {
        case UPDATE_MANAGEMENT_START:
            return {
                ...state,
                data: [],
                loading: true,
                error: null
            };
        case UPDATE_MANAGEMENT_SUCCESS:
            return {
                ...state,
                data: action.results,
                loading: false,
                error: null
            };
        case UPDATE_MANAGEMENT_ERROR:
            return {
                ...state,
                data: [],
                loading: false,
                error: action.detail
            };
        default:
            return { ...state };
    }
}