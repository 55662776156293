/** @jsxImportSource @emotion/react */
import React, {Fragment, useEffect} from 'react'
import { useLocation, useNavigate } from 'react-router'
import { CreateSide, ManageGroupNewsTable } from '../../../components/templates'
import * as styles from '../index.styles'
import { columns } from './mockData'
import { breadcrumbItems } from '../../utils'
import { Breadcrumb } from '../../../components/atoms'
import {useDispatch, useSelector} from "react-redux";
import {group_news} from "../../../redux/actions/news/group_news";

const { index, contentManagement, manageGroupNews } = breadcrumbItems

const ManageGroupNews: React.FC = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const token = useSelector((state: any) => state.login.access)
  const groups = useSelector((state: any) => state.group_news.data)
  const location = useLocation();

  useEffect(() => {
    dispatch(group_news({token}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  return (
    <Fragment>
      <Breadcrumb items={[index, contentManagement, manageGroupNews]} />
      <div css={styles.root}>
        <div css={styles.main}>
          <ManageGroupNewsTable
            title="Administrar Noticias"
            subtitle="Grupos de noticias publicados"
            columns={columns}
            data={groups || []}
            cycle={[]}
            management={[]}
            stateCategory={false}
          />
        </div>
        <div css={styles.right}>
          <CreateSide
            title="Crear grupo de noticias"
            message="Massa vulputate id placerat vitae ornare a orci. Gravida augue viverra pretium adipiscing mauris."
            textButton="Crear grupo de noticias"
            onCreate={() => navigate('/app/content-management/create-group-new')}
          />
        </div>
      </div>
    </Fragment>
  )
}

export default ManageGroupNews
