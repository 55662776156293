/** @jsxImportSource @emotion/react */
import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import { Text } from '..'
import * as styles from './index.styles'
import SeparatorIcon from './SeparatorIcon'
import { Props } from './types'

//breadcrumb root component
const Breadcrumb: React.FC<Props> = ({ items, style }) => {
  return (
    <nav css={(theme: any) => styles.root({ theme })}>
      {items.map((item, index) => (
        <Fragment key={`breadcrumb- ${index}`}>
          <Link
            key={index}
            to={item.path || '#'}
            css={(theme: any) => styles.link({ theme, hasPath: !!item.path })}
          >
            <Text>{item.label}</Text>
          </Link>
          {index !== items.length - 1 && (
            <SeparatorIcon css={(theme: any) => styles.separator({ theme })} />
          )}
        </Fragment>
      ))}
    </nav>
  )
}

export default Breadcrumb
