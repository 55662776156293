export const columns = [
  {
    Header: 'Nombre de usuario',
    accessor: 'username',
  },
  {
    Header: 'Roles',
    accessor: 'roles',
  },
  {
    Header: 'Estado',
    accessor: 'state',
  },
]

export const data = [
  {
    username: 'Hugo Soliz',
    roles: 'Administrador,  Gestor de contenidos, Editor de noticias, Editor de componentes, Editor de usuarios',
    state: 'Activo',
  },
  {
    username: 'Juan Perez',
    roles: 'Administrador,  Gestor de contenidos, Editor de noticias, Editor de componentes, Editor de usuarios',
    state: 'Activo',
  },
  {
    username: 'Pedro Perez',
    roles: 'Administrador,  Gestor de contenidos, Editor de noticias, Editor de componentes, Editor de usuarios',
    state: 'Activo',
  },
  {
    username: 'Juan Perez',
    roles: 'Administrador,  Gestor de contenidos, Editor de noticias, Editor de componentes, Editor de usuarios',
    state: 'Activo',
  },
  {
    username: 'Pedro Perez',
    roles: 'Administrador,  Gestor de contenidos, Editor de noticias, Editor de componentes, Editor de usuarios',
    state: 'Activo',
  },
  {
    username: 'Juan Perez',
    roles: 'Administrador,  Gestor de contenidos, Editor de noticias, Editor de componentes, Editor de usuarios',
    state: 'Activo',
  },
  {
    username: 'Pedro Perez',
    roles: 'Administrador,  Gestor de contenidos, Editor de noticias, Editor de componentes, Editor de usuarios',
    state: 'Activo',
  },
]