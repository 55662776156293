import {
    CREATE_MANAGEMENT_ERROR,
    CREATE_MANAGEMENT_SUCCESS,
    CREATE_MANAGEMENT_START,
} from "../../types";

export default function create_management(state, action) {
    switch (action.type) {
        case CREATE_MANAGEMENT_START:
            return {
                ...state,
                loading: true,
                error: null
            };
        case CREATE_MANAGEMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null
            };
        case CREATE_MANAGEMENT_ERROR:
            return {
                ...state,
                loading: false,
                error: action.detail
            };
        default:
            return { ...state };
    }
}
