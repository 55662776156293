import { css } from "@emotion/react"
import { ThemeUI } from "../../../theme"
import { StyleProps } from "./types"

type Props = { theme: ThemeUI } & StyleProps

const getSizeProps = (size?: string) => (
  size === 'sm' ? css`
    padding: 6px 15px;
    font-size: 9px;
    line-height: 11px;
  ` : size === 'lg' ? css`
    padding: 12px 24px;
    font-size: 21px;
    line-height: 25px;
  ` : css`
    padding: 10px 18px;
    font-size: 16px;
    line-height: 19px;
  `
)

const getVariantProps = ({ variant = 'primary', theme }: Props) => {
  const { default: bg, hover, disabled, } = theme.layout.button[variant]
  return css`
    font-family: Lato,'sans-serif';
    background-color: ${bg};
    color: ${theme.palette[variant === 'warning' ? 'secondary90' : 'white']};
    &:hover {
      background-color: ${hover};
    }
    &:disabled {
      background-color: ${disabled};
    }
  `
}


const button = ({ size, variant, theme, style }: Props) => css`
  border: none;
  border-radius: 3px;
  font-weight: bold;
  transition: all 0.2s ease-in-out;
  width: 100%;
  box-sizing: border-box;
  cursor: pointer;
  ${getVariantProps({ theme, variant })}
  ${getSizeProps(size)}
  ${style}
`

export { button }