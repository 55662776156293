import {DETAIL_NEW_CYCLE_ERROR, DETAIL_NEW_CYCLE_START, DETAIL_NEW_CYCLE_SUCCESS} from "../../types";

export default function detail_new_cycle(state, action) {
    switch (action.type) {
        case DETAIL_NEW_CYCLE_START:
            return {
                ...state,
                data: [],
                loading: true,
                error: null
            };
        case DETAIL_NEW_CYCLE_SUCCESS:
            return {
                ...state,
                data: action.results,
                loading: false,
                error: null
            };
        case DETAIL_NEW_CYCLE_ERROR:
            return {
                ...state,
                data: [],
                loading: false,
                error: action.detail
            };
        default:
            return { ...state };
    }
}
