import { createContext, FC, useState } from 'react';
import { Option } from "../components/atoms/SelectInput/types"

export type Data = {
  id?: string,
  title: string,
  description: string,
  cycle: string,
  category?: Option,
  management: object,
  posts: object,
  cover?: File
  is_cover: boolean
  active: boolean,
}
export interface DataContext {
  formData: Data,
  handleChangeFormData: (data: Partial<Data>) => void,
  handleUpdateFormData: (data: Partial<Data>) => void,
}

const initialData: Data = {
  id: '',
  title: '',
  description: '',
  cycle: '',
  category: undefined,
  management: [],
  posts: [],
  cover: undefined,
  is_cover: true,
  active: true,
}

export const Context = createContext<DataContext>({
  formData: initialData,
  handleChangeFormData: (data: Partial<Data>) => { },
  handleUpdateFormData: (data: Partial<Data>) => { },
})

const GroupPostFormDataProvider: FC = (props) => {
  const [formData, setFormData] = useState<Data>({
    id: '',
    title: '',
    description: '',
    cycle: '',
    category: undefined,
    management: [],
    posts: [],
    cover: undefined,
    is_cover: true,
    active: true,
  })

  const handleChangeFormData = (data: Partial<Data>) => setFormData({ ...formData, ...data })

  const handleUpdateFormData = (data: Partial<Data>) => {
    if (data && data?.id) {
      setFormData({
        ...formData,
        id: data.id,
        title: data.title || initialData.title,
        description: data.description || initialData.description,
        cycle: data.cycle || initialData.cycle,
        category: data.category || initialData.category,
        management: data.management || initialData.management,
        posts: data.posts || initialData.posts,
        cover: data.cover || initialData.cover,
        is_cover: data.is_cover || initialData.is_cover,
        active: data.active || false
      })
    }
  }
  return (
    <Context.Provider value={{ formData, handleChangeFormData, handleUpdateFormData }}>
      {props.children}
    </Context.Provider>
  )
}

export default GroupPostFormDataProvider
