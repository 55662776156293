/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react'
import React, {useContext} from 'react'
import { ThemeUI } from '../../../theme'
import { Button, Card, Text } from '../../atoms'
import { ContentSection } from '../../organisms'
import * as styles from './index.styles'
import {Context} from "../../../context/DrawerContext";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import { update_drawer } from '../../../redux/actions/drawer/update_drawer'
import { create_drawer } from '../../../redux/actions/drawer/create_drawer'

const NewPreview: React.FC = () => {
  const theme = useTheme() as ThemeUI
  const navigate = useNavigate();
  const APP_URL = process.env.REACT_APP_URL
  const { formData } = useContext(Context)
  const dispatch = useDispatch()
  const token = useSelector((state: any) => state.login.access)
  let { id } = useParams();

  const handleBanners = (token: any, formData: any) => {
    if (id) {
      if (!formData.image?.name) {
        formData.image = formData.image.indexOf('/media/') > -1 ? undefined : formData.image
      }
      dispatch(update_drawer({ token, formData, navigate }))
    } else {
      dispatch(create_drawer({ token, formData, navigate }))
    }
  }

  return (
    <ContentSection
      style={styles.root}
      header={
        <Text textType='p' weight='bold'>Vista Previa</Text>
      }
    >
      <div css={(theme: any) => styles.preview(theme)}>
        <Card style={css({ overflow: 'hidden' })}>
            <img
              src={formData.image ? (formData.image?.name ? URL.createObjectURL(formData.image): APP_URL+''+formData.image) : 'https://via.placeholder.com/600x200'}
              alt='placeholder'
              css={styles.previewImage}
            />
          <Text textType='sm' style={styles.previewTitle(theme)}>
            {formData.title}
          </Text>
          <div css={styles.previewDescription(theme)}>
            <Text textType='sm' weight='light'>
              {formData.nav}
            </Text>
            <Text textType='sm' weight='light'>
              {formData.url}
            </Text>
          </div>
        </Card>
      </div>
      <Button onClick={() => handleBanners(token, formData)} disabled={!formData.title || !formData.image}>
        {!id ? 'Publicar' : 'Guardar'} item
      </Button>
    </ContentSection>
  )
}


export default NewPreview
