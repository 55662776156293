import {GET_FCM_DEVICES_ERROR, GET_FCM_DEVICES_START, GET_FCM_DEVICES_SUCCESS} from "../../types";

export default function fcm_devices(state, action) {
  switch (action.type) {
    case GET_FCM_DEVICES_START:
      return {
        ...state,
        data: [],
        loading: true,
        error: null
      };
    case GET_FCM_DEVICES_SUCCESS:
      return {
        ...state,
        data: action.results,
        loading: false,
        error: null
      };
    case GET_FCM_DEVICES_ERROR:
      return {
        ...state,
        data: [],
        loading: false,
        error: action.detail
      };
    default:
      return {...state};
  }
}
