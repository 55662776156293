/** @jsxImportSource @emotion/react */
import React, {Fragment, useMemo, useState} from 'react'
import {
  Column,
  useGlobalFilter,
  useSortBy,
  useTable,
} from 'react-table'
import {Text} from '../../atoms'
import * as styles from './index.styles'
import {Link, useNavigate} from "react-router-dom";
import { DeleteIcon, EditIcon, EyeIcon } from '../../../assets'
import { Modal } from '../../../components/templates'
import { toast } from 'react-toastify';
import {useDispatch, useSelector} from "react-redux";
import {delete_banner} from "../../../redux/actions/banner/delete_banner";
import {delete_news} from "../../../redux/actions/news/delete_news";
import {delete_events} from "../../../redux/actions/events/delete_events";
import {delete_questions} from "../../../redux/actions/questions/delete_questions";
import {delete_magazine} from "../../../redux/actions/magazine/delete_magazine";
import {delete_nature_magazine} from "../../../redux/actions/nature-magazine/delete_nature_magazine";
import {delete_new_cycle} from "../../../redux/actions/group_cycle/delete_new_cycle";
import {delete_notification} from "../../../redux/actions/notification/delete_notification";
import {delete_growth_path} from "../../../redux/actions/growth-path/delete_growth_path";
import {delete_card_category} from "../../../redux/actions/card_category/delete_card_category";
import {delete_offers} from "../../../redux/actions/offers_by_cycle/delete_offers";
import {group_news} from "../../../redux/actions/news/group_news";
import {banners} from "../../../redux/actions/banner/banners";
import {magazines} from "../../../redux/actions/magazine/magazines";
import {nature_magazine} from "../../../redux/actions/nature-magazine/nature_magazine";
import {notifications} from "../../../redux/actions/notification/notifications";
import {questions} from "../../../redux/actions/questions/questions";
import {events} from "../../../redux/actions/events/events";
import {growth_path} from "../../../redux/actions/growth-path/growth_path";
import {card_category} from "../../../redux/actions/card_category/card_category";
import {offers} from "../../../redux/actions/offers_by_cycle/offers";
import {group_cycle} from "../../../redux/actions/group_cycle/group_cycle";
import {group_detail_posts} from "../../../redux/actions/post/group_detail_posts";
import {delete_drawer} from "../../../redux/actions/drawer/delete_drawer";
import {drawer} from "../../../redux/actions/drawer/drawer";
import apiCall from '../../../redux/api';

import moment from "moment";
import { userdata } from '../../../redux/actions/auth/userdata';

export type Props = {
  columns: Column<any>[]
  data: any[]
}

const TableBase: React.FC<Props> = ({
  columns: columnsTable,
  data: dataTable,
}) => {
  const APP_URL = process.env.REACT_APP_URL
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const token = useSelector((state: any) => state.login.access)

  const columns = useMemo((): Column<any>[] => columnsTable, [columnsTable])
  const data = useMemo((): any[] => dataTable, [dataTable])
  const tabla = useTable({ columns, data }, useGlobalFilter, useSortBy)
  const [show, setShow] = useState(false)
  const [idData, setIdData] = useState({id: 0, type: ''})

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = tabla

  const handleUserEdit = (row: any) => {
    navigate(`/app/users/edit/${row.original.id}`)
  }
  const handleDirectNewsE = (row: any) => {
    navigate(`/app/content-management/edit-new/${row.original.id}`)
  }
  const handleDirectBannerE = (row: any) => {
    navigate(`/app/content-management/edit-banner/${row.original.id}`)
  }
  const handleDirectDrawerE = (row: any) => {
    navigate(`/app/content-management/edit-drawer/${row.original.id}`)
  }
  const handleDirectNotificationE = (row: any) => {
    navigate(`/app/content-management/edit-notification/${row.original.id}`)
  }
  const handleDirectQuestionsE = (row: any) => {
    navigate(`/app/content-management/edit-questions/${row.original.id}`)
  }
  const handleDirectEventsE = (row: any) => {
    navigate(`/app/content-management/edit-events/${row.original.id}`)
  }
  const handleDirectPostsE = (row: any) => {
    navigate(`/app/content-management/edit-post/${row.original.id}`)
  }
  const handleDirectMagazineE = (row: any) => {
    navigate(`/app/content-management/edit-magazine/${row.original.id}`)
  }
  const handleDirectNatureMagazineE = (row: any) => {
    navigate(`/app/content-management/edit-nature-magazine/${row.original.id}`)
  }
  const handleDirectCycleE = (row: any) => {
    navigate(`/app/content-management/edit-news_cycle/${row.original.id}`)
  }
  const handleDirectGrowthPathE = (row: any) => {
    navigate(`/app/content-management/edit-growth-path/${row.original.id}`)
  }
  const handleDirectCardCategoryE = (row: any) => {
    navigate(`/app/content-management/edit-card-category/${row.original.id}`)
  }
  const handleDirectOffersE = (row: any) => {
    navigate(`/app/content-management/edit-offers-by-cycle/${row.original.id}`)
  }
  const handleDirectGPost = (row: any) => {
    navigate(`/app/content-management/watch-group-posts/${row.original.id}`)
  }
  const handleDirectGCycle = (row: any) => {
    navigate(`/app/content-management/watch-group-cycle/${row.original.id}`)
  }
  const handleDirectAddCycle = (row: any) => {
    navigate(`/app/content-management/create-news_cycle/${row.original.id}`)
  }
  const handleDirectDelete = (row: any, action: string) => {
    setShow(true)
    setIdData({id:row.original.id, type: action})
  }
  const actionDelete = (data: any) => {
    let id = data.id
    switch(data.type) {
      case 'News':
        dispatch(delete_news({ token, id }))
        setTimeout(() => {
          dispatch(group_news({ token, id }))
          navigate('/app/content-management/manage-group-news')
        }, 1500)
        break;
      case 'Banner':
        dispatch(delete_banner({ token, id }))
        setTimeout(() => {
          dispatch(banners({ token }));
        }, 1500)
        break;
      case 'Notification':
        dispatch(delete_notification({ token, id }))
        setTimeout(() => {
          dispatch(notifications({ token }))
        }, 1500)
        break;
      case 'Questions':
        dispatch(delete_questions({ token, id }))
        setTimeout(() => {
          dispatch(questions({ token }))
        }, 1500)
        break;
      case 'Event':
        dispatch(delete_events({ token, id }))
        setTimeout(() => {
          dispatch(events({ token }))
        }, 1500)
        break;
      case 'Magazine':
        dispatch(delete_magazine({ token, id }))
        setTimeout(() => {
          dispatch(magazines({ token }))
        }, 1500)
        break;
      case 'NatureMagazine':
        dispatch(delete_nature_magazine({ token, id }))
        setTimeout(() => {
          dispatch(nature_magazine({ token }))
        }, 1500)
        break;
      case 'Cycle':
        dispatch(delete_new_cycle({ token, id }))
        setTimeout(() => {
          dispatch(group_cycle({ token }))
          navigate('/app/content-management/manage-cycle');
        }, 1500)
        break;
      case 'Posts':
        const url = `/posts/${id}/`;
        const method = 'DELETE';
        const headers = { 
          'Authorization': `Bearer ${token}`,
          'Content-Type':'application/json'
        };
        apiCall(url, method, {id: id}, headers).then((data) => console.log('data: ',data))
        // dispatch(delete_posts({type: 'DELETE_POSTS_START', payload:{ token, id }}))
        // dispatch(remove_posts({type: 'REMOVE_POSTS_START', payload:{ token, id }}))
        setTimeout(() => {
          dispatch(group_detail_posts({ token, id }))
          navigate('/app/content-management/manage-post');
        }, 1500)
        break;
      case 'GrowthPath':
        dispatch(delete_growth_path({ token, id }))
        setTimeout(() => {
          dispatch(growth_path({ token }))
        }, 1500)
        break;
      case 'CardCategory':
        dispatch(delete_card_category({ token, id }))
        setTimeout(() => {
          dispatch(card_category({ token }))
        }, 1500)
        break;
      case 'Offers':
        dispatch(delete_offers({ token, id }))
        setTimeout(() => {
          dispatch(offers({ token }))
        }, 1500)
        break;
      case 'Drawer':
        dispatch(delete_drawer({ token, id }))
        setTimeout(() => {
          dispatch(drawer({ token }))
        }, 1500)
        break;
      case 'User':
        const url_user = `/users/${id}/`;
        const method_user = 'DELETE';
        const headers_user = { 
          'Authorization': `Bearer ${token}`,
          'Content-Type':'application/json'
        };
        apiCall(url_user, method_user, {id: id}, headers_user).then((data) => dispatch(userdata({ token })))
        break;
      default:
        // code block
      }
    toast.success('Eliminación exitosa!', {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })
    //navigate(`/app/dashboard`)
    setShow(false)
  }

  return (
    <Fragment>
      <Modal
          show={show}
          text=""
          title={`¿Esta seguro que desea eliminar`}
          btnSuccess="Cancelar"
          btnWarning="Eliminar"
          onClickWarning={() => {actionDelete(idData)}}
          onClickSuccess={() => {setShow(false)}}
          onClickClose={() => {setShow(false)}}
      />

      <table {...getTableProps()} css={styles.table}>
        <thead css={styles.tableHeader}>
          {headerGroups.map((headerGroup, index) => (
            <tr
              {...headerGroup.getHeaderGroupProps()}
              css={styles.tableHeaderRow}
              key={`tr-${index}`}
            >
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  css={styles.tableHeaderCell}
                  key={`th-${column.render('Header')}`}
                >
                  {column.render('Header')}
                  {column.isSorted ? (
                    column.isSortedDesc ? (
                      <span> &darr;</span>
                    ) : (
                      <span> &uarr;</span>
                    )
                  ) : (
                    ''
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()} css={styles.tableBody}>
          {rows.map((row) => {
            prepareRow(row)
            return (
                <tr {...row.getRowProps()} css={styles.tableBodyRow}>
                  {row.cells.map((cell) => {
                    if (cell.column.id === 'image' || cell.column.id === 'image_description' || cell.column.id === 'image_mobile' || cell.column.id === 'cover') {
                      return (
                          <td {...cell.getCellProps()} css={styles.tableBodyCell}>
                            <img alt={cell.column.id}
                                 css={styles.imageCellD}
                                 src={cell.value ? APP_URL + cell.value : 'https://via.placeholder.com/300x200'}
                            />
                          </td>
                      )
                    } else if (cell.column.id === 'url_video' || cell.column.id === 'url') {
                      return (
                          <td {...cell.getCellProps()} css={styles.tableBodyCell}>
                            <a href={cell.value} target={'_blank'} rel="noopener noreferrer">{cell.value}</a>
                          </td>
                      )
                    } else if (cell.column.id === 'created') {
                      return (
                          <td {...cell.getCellProps()} css={styles.tableBodyCell}>
                            <p>{moment(cell.value).utc().format('YYYY-MM-DD HH:mm')}</p>

                          </td>
                      )
                    } else if (cell.column.id === 'id') {
                      return (
                          <td {...cell.getCellProps()} css={styles.tableBodyCell}>
                            <Link to={`/app/content-management/create-post/${cell.value}`}> Agregar Post</Link>
                          </td>
                      )
                    } else if (cell.column.id === 'idNCycle') {
                      return (
                          <td {...cell.getCellProps()} css={styles.tableBodyCell}>
                            <p style={{color: 'blue', textDecoration: 'underline'}} onClick={() => handleDirectAddCycle(row)}> Agregar Noticia Ciclo</p>
                          </td>
                      )
                    } else {
                      return (
                          <td {...cell.getCellProps()} css={styles.tableBodyCell}>
                            {cell.render('Cell')}
                            {cell.column.id === 'active' ? cell.value === true ? 'Activo' : 'Inactivo' : null}
                            {cell.column.id === 'editUser' ? <EditIcon width={24} height={24} css={styles.icon} onClick={() => handleUserEdit(row)} /> : null}
                            {cell.column.id === 'deleteUser' ? <DeleteIcon width={24} height={24} css={styles.icon} onClick={() => handleDirectDelete(row, 'User')} /> : null}
                            {cell.column.id === 'editNews' ? <EditIcon width={24} height={24} css={styles.icon} onClick={() => handleDirectNewsE(row)} /> : null}
                            {cell.column.id === 'deleteNews' ? <DeleteIcon width={24} height={24} css={styles.icon} onClick={() => handleDirectDelete(row, 'News')} /> : null}
                            {cell.column.id === 'editBanner' ? <EditIcon width={24} height={24} css={styles.icon} onClick={() => handleDirectBannerE(row)} /> : null}
                            {cell.column.id === 'deleteBanner' ? <DeleteIcon width={24} height={24} css={styles.icon} onClick={() => handleDirectDelete(row, 'Banner')} /> : null}
                            {cell.column.id === 'editDrawer' ? <EditIcon width={24} height={24} css={styles.icon} onClick={() => handleDirectDrawerE(row)} /> : null}
                            {cell.column.id === 'deleteDrawer' ? <DeleteIcon width={24} height={24} css={styles.icon} onClick={() => handleDirectDelete(row, 'Drawer')} /> : null}
                            {cell.column.id === 'editNotification' ? <EditIcon width={24} height={24} css={styles.icon} onClick={() => handleDirectNotificationE(row)} /> : null}
                            {cell.column.id === 'deleteNotification' ? <DeleteIcon width={24} height={24} css={styles.icon} onClick={() => handleDirectDelete(row, 'Notification')} /> : null}
                            {cell.column.id === 'editQuestions' ? <EditIcon width={24} height={24} css={styles.icon} onClick={() => handleDirectQuestionsE(row)} /> : null}
                            {cell.column.id === 'deleteQuestions' ? <DeleteIcon width={24} height={24} css={styles.icon} onClick={() => handleDirectDelete(row, 'Questions')} /> : null}
                            {cell.column.id === 'editEvent' ? <EditIcon width={24} height={24} css={styles.icon} onClick={() => handleDirectEventsE(row)} /> : null}
                            {cell.column.id === 'deleteEvent' ? <DeleteIcon width={24} height={24} css={styles.icon} onClick={() => handleDirectDelete(row, 'Event')} /> : null}
                            {cell.column.id === 'editPosts' ? <EditIcon width={24} height={24} css={styles.icon} onClick={() => handleDirectPostsE(row)} /> : null}
                            {cell.column.id === 'deletePosts' ? <DeleteIcon width={24} height={24} css={styles.icon} onClick={() => handleDirectDelete(row, 'Posts')} /> : null}
                            {cell.column.id === 'editMagazine' ? <EditIcon width={24} height={24} css={styles.icon} onClick={() => handleDirectMagazineE(row)} /> : null}
                            {cell.column.id === 'deleteMagazine' ? <DeleteIcon width={24} height={24} css={styles.icon} onClick={() => handleDirectDelete(row, 'Magazine')} /> : null}
                            {cell.column.id === 'editNatureMagazine' ? <EditIcon width={24} height={24} css={styles.icon} onClick={() => handleDirectNatureMagazineE(row)} /> : null}
                            {cell.column.id === 'deleteNatureMagazine' ? <DeleteIcon width={24} height={24} css={styles.icon} onClick={() => handleDirectDelete(row, 'NatureMagazine')} /> : null}
                            {cell.column.id === 'editCycle' ? <EditIcon width={24} height={24} css={styles.icon} onClick={() => handleDirectCycleE(row)} /> : null}
                            {cell.column.id === 'deleteCycle' ? <DeleteIcon width={24} height={24} css={styles.icon} onClick={() => handleDirectDelete(row, 'Cycle')} /> : null}
                            {cell.column.id === 'editGrowthPath' ? <EditIcon width={24} height={24} css={styles.icon} onClick={() => handleDirectGrowthPathE(row)} /> : null}
                            {cell.column.id === 'deleteGrowthPath' ? <DeleteIcon width={24} height={24} css={styles.icon} onClick={() => handleDirectDelete(row, 'GrowthPath')} /> : null}
                            {cell.column.id === 'editCardCategory' ? <EditIcon width={24} height={24} css={styles.icon} onClick={() => handleDirectCardCategoryE(row)} /> : null}
                            {cell.column.id === 'deleteCardCategory' ? <DeleteIcon width={24} height={24} css={styles.icon} onClick={() => handleDirectDelete(row, 'CardCategory')} /> : null}
                            {cell.column.id === 'editOffers' ? <EditIcon width={24} height={24} css={styles.icon} onClick={() => handleDirectOffersE(row)} /> : null}
                            {cell.column.id === 'deleteOffers' ? <DeleteIcon width={24} height={24} css={styles.icon} onClick={() => handleDirectDelete(row, 'Offers')} /> : null}
                            {cell.column.id === 'watchPosts' ? <EyeIcon width={24} height={24} css={styles.icon} onClick={() => handleDirectGPost(row)} /> : null}
                            {cell.column.id === 'watchCycle' ? <EyeIcon width={24} height={24} css={styles.icon} onClick={() => handleDirectGCycle(row)} /> : null}
                          </td>
                      )
                    }
                  })}
                </tr>
            )
          })}
        </tbody>
        <Text textType="sm">{rows.length===0?'No hay datos en la tabla.':''}</Text>
      </table>
    </Fragment>
  )
}

export default TableBase
