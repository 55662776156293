/** @jsxImportSource @emotion/react */
import React from 'react'
import { Button, Text } from '../../atoms'
import { ContentSection } from '../../organisms'
import * as styles from './index.styles'

type Props = {
  title: string,
  message: string,
  textButton: string,
  onCreate?: () => void,
}

const CreateSide: React.FC<Props> = ({ title, message, textButton, onCreate = () => { } }) => (
  <ContentSection
    header={
      <Text textType='p' weight='bold'>{title}</Text>
    }
  >
    <div css={styles.root}>
      <Text textType='label'>
        {message}
      </Text>
      <Button onClick={onCreate}>
        <Text >
          {textButton}
        </Text>
      </Button>
    </div>
  </ContentSection>
)


export default CreateSide
