import { FormData } from "../../../context/NotificationContext"

export const columns = [
  {
    Header: 'Titulo',
    accessor: 'title',
  },
  {
    Header: 'Contenido',
    accessor: 'description',
  },
  {
    Header: 'Ciclo',
    accessor: 'cycle',
  },
  {
    Header: 'Nivel',
    accessor: 'category',
  },
  {
    Header: 'Gerencia',
    accessor: 'management',
  },
  {
    Header: 'Imagen',
    accessor: 'image',
  },
  {
    Header: 'Enlace',
    accessor: 'url_video',
  },
  {
    Header: 'Editar',
    accessor: 'editNotification',
  },
  {
    Header: 'Eliminar',
    accessor: 'deleteNotification',
  },
  {
    Header: 'Estado',
    accessor: 'active',
  },
]

export type Data = Omit<FormData, 'imageFile'> & { image: string }

export const data: Data[] = []
