/** @jsxImportSource @emotion/react */
import React, { Fragment, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { CreateSide, ManageTable } from '../../../components/templates'
import * as styles from '../index.styles'
import { columns } from './mockData'
import { breadcrumbItems } from '../../utils'
import { Breadcrumb } from '../../../components/atoms'
import {card_category} from "../../../redux/actions/card_category/card_category";

const { index, contentManagement, manageOffers } = breadcrumbItems

const ManageOffers: React.FC = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const token = useSelector((state: any) => state.login.access)
  const card_data = useSelector((state: any) => state.card_category.data)
  const location = useLocation();

  useEffect(() => {
    dispatch(card_category({ token }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  return (
    <Fragment>
      <Breadcrumb items={[index, contentManagement, manageOffers]} />
      <div css={styles.root}>
        <div css={styles.main}>
          <ManageTable
            title="Administrar tarjetas de categorías"
            subtitle="Tarjetas publicadas"
            data={card_data || []}
            stateCategory={true}
            columns={columns}
            cycle={[]} management={[]}
          />
        </div>
        <div css={styles.right}>
          <CreateSide
            title="Crear nueva tarjeta"
            message="Massa vulputate id placerat vitae ornare a orci. Gravida augue viverra pretium adipiscing mauris."
            textButton="Crear nueva tarjeta"
            onCreate={() => navigate('/app/content-management/create-card-category')}
          />
        </div>
      </div>
    </Fragment>
  )
}

export default ManageOffers
