/** @jsxImportSource @emotion/react */
import React, {Fragment, useEffect, useState} from 'react'
import { useLocation, useNavigate } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { CreateSide, ManageTable } from '../../../components/templates'
import * as styles from '../index.styles'
import { columns } from './mockData'
import { breadcrumbItems } from '../../utils'
import {Breadcrumb, Button, FileInput, Text} from '../../../components/atoms'
import { events } from '../../../redux/actions/events/events'
import moment from 'moment';
import {CardSection} from "../../../components/molecules";
import {events_send_csv} from "../../../redux/actions/events/events_send_csv";
import {toast} from "react-toastify";

const { index, contentManagement, manageEvents } = breadcrumbItems

const ManageEvents: React.FC = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [selectedFile, setSelectedFile] = useState(undefined);
  const [image, setImage] = useState(undefined);
  const location = useLocation();

  const token = useSelector((state: any) => state.login.access)
  const data_events = useSelector((state: any) => {
    state.events.data.map((item:any) => {
    item.hour_init = moment(item.hour_start).utc().format('h:mm')
    item.hour_fin = moment(item.hour_end).utc().format('h:mm')
    return  item
  })
  return state.events.data
  })
  const cycleData = useSelector((state: any) => {
    return state.events_cycle.data.map((v:any) => {
      return { value: v.cycle, label: v.cycle }
    })
  })
  cycleData.push({ value: 'Ninguno', label: 'Ninguno'})

  const managementData = useSelector((state: any) => {
    return state.management.data.map((v:any) => {
      return { value: v.id, label: v.management }
    })
  })
  managementData.push({ value: 'Ninguno', label: 'Ninguno'})

  useEffect(() => {
    dispatch(events({ token }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  const changeImageHandler = (event: any) => {
    console.log(event)
    setImage(event);
  };

  const handleUploadCSV = (formData: any, image: any) => {
    if (formData) {
      dispatch(events_send_csv({ token, formData, image, handleNotification}))
    } else {
      toast.error('Debe cargar un archivo csv!', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
  }

  const changeHandler = (event: any) => {
    console.log(event)
    setSelectedFile(event);
  };

  const handleNotification = () => {
    toast.success('Archivo cargado correctamente!', {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })
  }

  return (
    <Fragment>
      <Breadcrumb items={[index, contentManagement, manageEvents]} />
      <div css={styles.root}>
        <div css={styles.main}>
          <ManageTable
            title="Administrar Eventos"
            subtitle="Eventos publicados"
            columns={columns}
            data={data_events || []}
            cycle={cycleData || []}
            management={managementData || []}
            stateCategory={true}
          />
        </div>
        <div css={styles.right}>
          <CreateSide
            title="Crear nuevo evento"
            message="Massa vulputate id placerat vitae ornare a orci. Gravida augue viverra pretium adipiscing mauris."
            textButton="Crear nuevo evento"
            onCreate={() => navigate('/app/content-management/create-events')}
          />
          <br />
          <CardSection padding="24px" >
            <FileInput
              label='Enviar notificaciones desde un archivo csv. Formato: (usuario, titulo, mensaje, fecha, fecha y hora de inicio, fecha y hora de fin, url)'
              value={selectedFile}
              onChange={changeHandler}
              accept='csv/*'
            />
            <FileInput
              label='Imagen de mensaje'
              value={image}
              onChange={changeImageHandler}
              accept='image/*'
            />
            <br />
            <Button onClick={() => handleUploadCSV(selectedFile, image)}>
              <Text>
                Subir archivo
              </Text>
            </Button>
          </CardSection>
        </div>
      </div>
    </Fragment>
  )
}

export default ManageEvents
