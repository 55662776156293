/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import React, {FC, useContext, useEffect, useState} from 'react'
import { Context } from '../../../context/GroupNewContext'
import {BaseInput, SwitchInput, Text, TextareaInput} from '../../atoms'
import { ContentSection } from '../../organisms'
import {CATEGORIES_ALL} from "../../../category_all";
import {useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import Select from "react-select";
import * as styles from "../Header/index.styles";

const NewGroupForm: FC = () => {
  const { formData, handleChangeFormData: handleChange, handleUpdateFormData: handleData } = useContext(Context)
  let { id } = useParams()
  const detail_data = useSelector((state: any) => state.group_detail.data)
  const categoryInit = detail_data?.category && id ? { value: detail_data.category, label: detail_data.category } : { value: '', label: '' }

  const handleCategory = (e: any) => {
    handleChange({ category: e.value })
  }

  useEffect(() => {
    if (!!id) {
      handleData(detail_data)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, detail_data])

  const [isTitleValid, setTitleValid] = useState<boolean>(false)
  const [isDescriptionValid, setIsDescriptionValid] = useState<boolean>(false)

  useEffect(() => {
    setTitleValid(formData.title.length > 2)
  }, [formData.title])
  useEffect(() => {
    setIsDescriptionValid(formData.description.length > 2)
  }, [formData.description])

  return (
    <ContentSection
      style={css`display: grid; gap: 18px;`}
      header={
        <React.Fragment>
          <Text textType='h4' weight='black'>{id ? 'Editar' : 'Nuevo'} grupo de noticias</Text>
        </React.Fragment>
      }
    >
      <BaseInput
        label='Título'
        value={formData.title}
        onChange={(value) => handleChange({ title: value })}
        placeholder='Escribe el titulo de tu entrada'
        maxLength={150}
        error={!isTitleValid && formData.title.length > 0}
        success={isTitleValid}
        hint={!isTitleValid && formData.title.length > 0 ? 'Digite mas de dos letras' : ''}
      />
      <TextareaInput
        label='Descripción'
        value={formData.description}
        onChange={(value) => handleChange({ description: value })}
        placeholder='Escribe el contenido de tu entrada'
        maxLength={1500}
        error={!isDescriptionValid && formData.description.length > 0}
        success={isDescriptionValid}
        hint={!isDescriptionValid && formData.description.length > 0 ? 'Digite mas de dos letras' : ''}
      />
      <Text textType="label">
        CATEGORÍA :{' '}
        <Select css={styles.input}
                options={CATEGORIES_ALL}
                placeholder={"Selecciona una categoría"}
                defaultValue={categoryInit}
                onChange={(e) =>  handleCategory(e)}
        />
      </Text>
      <BaseInput
        label="Ciclo"
        placeholder="Escribe un código de  ciclo"
        value={formData.cycle}
        onChange={(value) => handleChange({ cycle: value })}
        maxLength={30}
      />
      <SwitchInput checked={formData.active}
                   onCheckedChange={(value) => handleChange({ active: value })}
                   label={formData.active ? 'Activo': 'Inactivo'}
      />
    </ContentSection>
  )
}

export default NewGroupForm
