import {UPDATE_NEW_CYCLE_ERROR, UPDATE_NEW_CYCLE_START, UPDATE_NEW_CYCLE_SUCCESS} from "../../types";

export default function update_new_cycle(state, action) {
    switch (action.type) {
        case UPDATE_NEW_CYCLE_START:
            return {
                ...state,
                loading: true,
                error: null
            };
        case UPDATE_NEW_CYCLE_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null
            };
        case UPDATE_NEW_CYCLE_ERROR:
            return {
                ...state,
                loading: false,
                error: action.detail
            };
        default:
            return {...state};
    }
}
