/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import React, {FC, useContext, useEffect, useState} from 'react'
import { Context } from '../../../context/BannerContext'
import {BaseInput, FileInput, SwitchInput, Text, TextareaInput} from '../../atoms'
import { ContentSection } from '../../organisms'
import {CATEGORIES_ALL} from "../../../category_all";
import {useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import Select from "react-select";
import * as styles from "../Header/index.styles";

const validateNumber = (data: string) =>
    /^[0-9]+$/.test(data)

const NewForm: FC = () => {
  const { formData, handleChangeFormData: handleChange, handleUpdateFormData: handleData } = useContext(Context)
  let { id } = useParams();
  const detail_data = useSelector((state: any) => state.banners.data)
  const news_data = id ? detail_data?.find((r:any) => r.id === parseInt(id+'')) : null

  useEffect(() => {
    if (!!id) {
      handleData(news_data)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, news_data])

  const categoryInit = news_data?.category ? { value: news_data.category, label: news_data.category } : { value: '', label: '' }

  const handleCategory = (e: any) => {
    handleChange({ category: e.value })
  }

  const [isTitleValid, setTitleValid] = useState<boolean>(false)
  const [isDescriptionValid, setIsDescriptionValid] = useState<boolean>(false)
  const [isUrlValid, setIsUrlValid] = useState<boolean>(false)
  const [isCycleValid, setIsCycleValid] = useState<boolean>(false)

  useEffect(() => {
    setTitleValid(formData.title.length > 2)
  }, [formData.title])
  useEffect(() => {
    setIsDescriptionValid(formData.description.length > 2)
  }, [formData.description])
  useEffect(() => {
    setIsUrlValid(formData.url.length > 10)
  }, [formData.url])
  useEffect(() => {
    setIsCycleValid(validateNumber(formData.cycle))
  }, [formData.cycle])

  return (
    <ContentSection
      style={css`display: grid; gap: 18px;`}
      header={
        <React.Fragment>
          <Text textType='h4' weight='black'>{id ? 'Editar' : 'Nuevo'} banner</Text>
        </React.Fragment>
      }
    >
      <BaseInput
        label='Título'
        value={formData.title}
        onChange={(value) => handleChange({ title: value })}
        placeholder='Escribe el titulo de tu entrada'
        maxLength={500}
        error={!isTitleValid && formData.title.length > 0}
        success={isTitleValid}
        hint={!isTitleValid && formData.title.length > 0 ? 'Digite mas de dos letras' : ''}
      />
      <TextareaInput
        label='Contenido'
        value={formData.description}
        onChange={(value) => handleChange({ description: value })}
        placeholder='Escribe el contenido de tu entrada'
        maxLength={1500}
        error={!isDescriptionValid && formData.description.length > 0}
        success={isDescriptionValid}
        hint={!isDescriptionValid && formData.description.length > 0 ? 'Digite mas de dos letras' : ''}
      />
      <div style={{ width: 360 }}>
        <FileInput
          label={formData.is_image ? 'Imagen' : 'Video'}
          value={formData.image}
          onChange={(value) => handleChange({ image: value })}
          accept={formData.is_image ? 'image/*' : 'video/*'}
        />
      </div>
      <SwitchInput checked={formData.is_image}
        onCheckedChange={(value) => handleChange({ is_image: value })}
        label={formData.is_image ? 'Subir Imagen': 'Subir Video'}
      />
      <div style={{ width: 360 }}>
        <FileInput
          label={formData.is_image_mobile ? 'Imagen Movil' : 'Video Movil'}
          value={formData.image_mobile}
          onChange={(value) => handleChange({ image_mobile: value })}
          accept={formData.is_image_mobile ? 'image/*' : 'video/*'}
        />
      </div>
      <SwitchInput checked={formData.is_image_mobile}
        onCheckedChange={(value) => handleChange({ is_image_mobile: value })}
        label={formData.is_image_mobile ? 'Subir Imagen': 'Subir Video'}
      />

      <div style={{ width: 360 }}>
        <FileInput
          label={formData.is_image_description ? 'Imagen Descripción' : 'Video Descripción'}
          value={formData.image_description}
          onChange={(value) => handleChange({ image_description: value })}
          accept={formData.is_image_description ? 'image/*' : 'video/*'}
        />
      </div>
      <SwitchInput checked={formData.is_image_description}
        onCheckedChange={(value) => handleChange({ is_image_description: value })}
        label={formData.is_image_description ? 'Subir Imagen': 'Subir Video'}
      />
      <BaseInput
        label='Link'
        value={formData.url}
        onChange={(value) => handleChange({ url: value })}
        placeholder='URL'
        maxLength={1500}
        error={!isUrlValid && formData.url.length > 0}
        success={isUrlValid}
        hint={!isUrlValid && formData.url.length > 0 ? 'Introduce una url Ej.:https://www.google.com/' : ''}
      />
      <Text textType="label">
        CATEGORÍA :{' '}
        <Select css={styles.input}
            options={CATEGORIES_ALL}
            defaultValue={categoryInit}
            onChange={(e) =>  handleCategory(e)}
        />
      </Text>
      <BaseInput
        label="Ciclo"
        placeholder="Escribe un código de  ciclo"
        value={formData.cycle}
        onChange={(value) => handleChange({ cycle: value })}
        maxLength={30}
        error={!isCycleValid && formData.cycle.length > 0}
        success={isCycleValid}
        hint={!isCycleValid && formData.cycle.length > 0 ? 'Digite numeros' : ''}
      />
      <SwitchInput checked={formData.active}
                   onCheckedChange={(value) => handleChange({ active: value })}
                   label={formData.active ? 'Activo': 'Inactivo'}
      />
    </ContentSection>
  )
}

export default NewForm
