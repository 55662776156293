import { createContext, FC, useState } from 'react'

export type Data = {
  id?: string
  title: string
  nav: string
  image?: File
  web: boolean
  url: string
  active: boolean
}
export interface DataContext {
  formData: Data,
  handleChangeFormData: (data: Partial<Data>) => void,
  handleUpdateFormData: (data: Partial<Data>) => void,
}

const initialData: Data = {
  id: '',
  title: '',
  nav: '',
  image: undefined,
  web: true,
  url: '',
  active: true,
}

export const Context = createContext<DataContext>({
  formData: initialData,
  handleChangeFormData: (data: Partial<Data>) => {},
  handleUpdateFormData: (data: Partial<Data>) => {},
})

const NewFormDataProvider: FC = (props) => {
  const [formData, setFormData] = useState<Data>(initialData)

  const handleChangeFormData = (data: Partial<Data>) => {
    setFormData({ ...formData, ...data })
  }

  const handleUpdateFormData = (data: Partial<Data>) => {
    if (data && data?.id) {
      setFormData({
        ...formData,
        id: data.id,
        title: data.title || initialData.title,
        nav: data.nav || initialData.nav,
        url: data.url || initialData.url,
        web: data.web || initialData.web,
        image: data.image || initialData.image,
        active: data.active || false
      })
    }
  }
  return (
    <Context.Provider value={{ formData, handleChangeFormData, handleUpdateFormData }}>
      {props.children}
    </Context.Provider>
  )
}

export default NewFormDataProvider
