import {takeLatest, put, call} from 'redux-saga/effects';
import apiCall from '../../api';
import {UPDATE_GROUP_CYCLE_ERROR, UPDATE_GROUP_CYCLE_START, UPDATE_GROUP_CYCLE_SUCCESS} from "../../types";

export function* updateGroup({payload}) {
    try {
        const url = `/group_cycle/${payload.formData.id}/`;
        const method = 'PUT';
        const headers = {Authorization: 'Bearer ' + payload.token, 'Content-Type':'application/json'};

        const results = yield call(apiCall, url, method, payload.formData, headers);
        yield put({type: UPDATE_GROUP_CYCLE_SUCCESS, results: results.data});
        yield put(payload.navigate('/app/content-management/manage-cycle'));
    } catch (error) {
        yield put({type: UPDATE_GROUP_CYCLE_ERROR, error});
    }
}

export default function* update_group_cycle() {
    yield takeLatest(UPDATE_GROUP_CYCLE_START, updateGroup);
}