import {takeLatest, put, call} from 'redux-saga/effects';
import apiCall from '../../api';
import { POST_EVENTS_SEND_CSV_START, POST_EVENTS_SEND_CSV_SUCCESS, POST_EVENTS_SEND_CSV_ERROR } from "../../types";

export function* create({payload}) {
  try {
    const url = '/events_csv/';
    const method = 'POST';
    const headers = {Authorization: 'Bearer ' + payload.token, 'Content-Type':'multipart/form-data'};
    const formData = new FormData();
    formData.append('csv_file', payload.formData);
    formData.append('image', payload.image);
    const results = yield call(apiCall, url, method, formData, headers);
    yield put({type: POST_EVENTS_SEND_CSV_SUCCESS, results: results.data});
  } catch (error) {
    yield put({type: POST_EVENTS_SEND_CSV_ERROR, error});
  } finally {
    yield put(payload.handleNotification());
  }
}

export default function* events_send_csv() {
  yield takeLatest(POST_EVENTS_SEND_CSV_START, create);
}
