export const permissions = [
    {
        id: 45,
        name: 'Administrar grupo de noticias',
        codename: 'add_groupnews',
    },
    {
        id: 37,
        name: 'Administrar banners',
        codename: 'add_banners',
    },
    {
        id: 49,
        name: 'Administrar notificaciones',
        codename: 'add_notifications',
    },
    {
        id: 65,
        name: 'Administrar preguntas frecuentes',
        codename: 'add_questions',
    },
    {
        id: 61,
        name: 'Administrar eventos',
        codename: 'add_events',
    },
    {
        id: 93,
        name: 'Administrar grupo de posteos',
        codename: 'add_grouppost',
    },
    {
        id: 85,
        name: 'Administrar revistas',
        codename: 'add_magazine',
    },
    {
        id: 81,
        name: 'Administrar revista natura',
        codename: 'add_naturamagazine',
    },
    {
        id: 117,
        name: 'Administrar Noticias por ciclo',
        codename: 'add_groupcycle',
    },
    {
        id: 101,
        name: 'Administrar Camino de Crecimiento',
        codename: 'add_growthpath',
    },
    {
        id: 109,
        name: 'Administrar Tarjetas de categoría',
        codename: 'add_cdccardcategory',
    },
    {
        id: 97,
        name: 'Administrar Ofertas por ciclo',
        codename: 'add_offersbycycle',
    },
    {
        id: 77,
        name: 'Administrar Items de menu APP',
        codename: 'add_drawer',
    },
    {
        id: 25,
        name: 'Enviar FCM (push notifications)',
        codename: 'add_fcmdevice',
    },
    {
        id: 153,
        name: 'Administrar gerencias',
        codename: 'add_management',
    },
    {
        id: 13,
        name: 'Administrar usuarios',
        codename: 'add_user',
    }
]