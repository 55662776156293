import { takeLatest, call, put } from 'redux-saga/effects';
import { GET_PERMISSIONS_ERROR, GET_PERMISSIONS_START, GET_PERMISSIONS_SUCCESS } from '../../types';

import apiCall from '../../api/apiGet';

export function* get({ payload }) {
  try {
    const url = '/permissions/';
    const method = 'GET';
    const headers = {Authorization: 'Bearer ' + payload.token};;
    const results = yield call(apiCall, url, method, headers);
    yield put({ type: GET_PERMISSIONS_SUCCESS, results: results.data });
  } catch (error) {
    yield put({ type: GET_PERMISSIONS_ERROR, error });
  }
}

export default function* permissions() {
  yield takeLatest(GET_PERMISSIONS_START, get);
}
