import { css } from "@emotion/react"
import { ThemeUI } from "../../../theme"

type Props = { theme: ThemeUI, isActive?: boolean }

export const root = css`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`

export const title = ({ theme }: Props) => css`
  padding: 6px 10px;
  & > div {
    text-transform: uppercase;
    padding-top: 6px;
    padding-bottom: 6px;
    color: ${theme.palette.secondary30};
    border-bottom: 1px solid ${theme.palette.ornamental};
  }  
`

export const list = css`
  margin:0;
  padding: 0;
  text-indent: 0;
  list-style: none;
`

export const subList = css`
  margin:0;
  padding: 0;
  text-indent: 0;
  list-style: none;
  background: #EDEAF6;
`

const hoverLinkStyle = ({ theme }: Props) => css`
  background: ${theme.palette.primary};
  color: ${theme.palette.white};
  & svg {
    fill: ${theme.palette.white};
  }
  `

export const linkStyle = ({ theme, isActive }: Props) => css`
  color: ${theme.palette.secondary90};
  display: flex;
  text-decoration: none;
  padding: 12px 6px;
  width: 100%;
  border-radius: 3px;
  cursor: pointer;
  &:hover {
    ${hoverLinkStyle({ theme })}
  }
  & svg {
    fill: ${theme.palette.primary};
    margin-right: 12px;
  }
  ${isActive && hoverLinkStyle({ theme })}
`

export const item = ({ theme, isActive }: Props) => css`
  & a {
    ${linkStyle({ theme, isActive })}
  }
  &::marker {
    content: "";
  }
`

export const subItem = ({ theme, isActive }: Props) => css`
  & a {
    padding: 12px 6px;
    padding-left: 20px;
    color: ${theme.palette.secondary90};
    display: block;
    text-decoration: none;
    border-radius: 3px;
    cursor: pointer;
    &:hover {
      ${hoverLinkStyle({ theme })}
    }
    ${isActive && hoverLinkStyle({ theme })}
  }
`
