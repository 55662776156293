import axios from 'axios';

const baseURL = process.env.REACT_APP_API_URL_V1;
const config = {
  Accept: 'application/json',
};

// eslint-disable-next-line
export default (url, method, data, headers) => axios({
  baseURL,
  method,
  url,
  headers,
  data,
  config,
});
