/** @jsxImportSource @emotion/react */
import React, {useState} from 'react'
import {CreateSide, ManageTable, Modal} from '../../../components/templates'
import * as styles from '../index.styles'
import Provider from '../../../context/WatchGroupCycleContext'
import { breadcrumbItems } from '../../utils'
import { Breadcrumb } from '../../../components/atoms'
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {columns} from "./mockData";
import {useNavigate} from "react-router";
import {delete_group_cycle} from "../../../redux/actions/group_cycle/delete_group_cycle";

const { index, contentManagement, manageCycle } = breadcrumbItems

const ManageCycle: React.FC = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  let { id } = useParams();
  const token = useSelector((state: any) => state.login.access)
  const [show, setShow] = useState(false)
  const [modalData, setModalData] = useState({title: '', text: '', success: '', warning: ''})

  const cycle_data = useSelector((state: any) => state.group_cycle.data)
  const detail_cycle = cycle_data?.find((r:any) => parseInt(id+'') === r.id)

  const handleGroupDelete = (id: any) => {
    if (detail_cycle?.news_cycle.length > 0) {
      setModalData({title: 'Tienes datos de noticias por ciclo!', text: 'No puedes eliminar un grupo si cuenta con noticias por ciclo, primero elimina tus noticias por ciclo.', success: 'Aceptar', warning: ''})
    } else {
      setModalData({title: '¿Esta seguro que desea eliminar?', text: '', success: 'Cancelar', warning: 'Eliminar'})
    }
    setShow(true)
  }
  const actionDelete = (id: any) => {
    dispatch(delete_group_cycle({ token, id }))
    navigate('/app/content-management/manage-cycle')
    setShow(false)
  }

  return (
    <Provider>
      <Breadcrumb items={[index, contentManagement, manageCycle]} />
      <Modal
          show={show}
          text={modalData.text}
          title={modalData.title}
          btnSuccess={modalData.success}
          btnWarning={modalData.warning}
          onClickWarning={() => {actionDelete(id)}}
          onClickSuccess={() => {setShow(false)}}
          onClickClose={() => {setShow(false)}}
      />
      <div css={styles.root}>
        <div css={styles.main}>
          <ManageTable
              title="Datos de la noticia por ciclo"
              subtitle={'Codigo: ' + detail_cycle?.id}
              columns={columns}
              data={ detail_cycle?.news_cycle || []}
              cycle={[]}
              management={[]}
              stateCategory={false}
          />
        </div>
        <div css={styles.right}>
          <CreateSide
              title={'Editar grupo noticias por ciclo'}
              message="Massa vulputate id placerat vitae ornare a orci. Gravida augue viverra pretium adipiscing mauris."
              textButton={'Editar noticias por ciclo'}
              onCreate={() => navigate(`/app/content-management/edit-group-n-cycle/${id}`)}
          />
          <CreateSide
              title={'Eliminar grupo noticias por ciclo'}
              message="Massa vulputate id placerat vitae ornare a orci. Gravida augue viverra pretium adipiscing mauris."
              textButton={'Eliminar noticias por ciclo'}
              onCreate={() => handleGroupDelete(id)}
              />
        </div>
      </div>
    </Provider>
  )
}

export default ManageCycle