import {takeLatest, put, call} from 'redux-saga/effects';
import apiCall from '../../api';
import {DELETE_GROUP_CYCLE_ERROR, DELETE_GROUP_CYCLE_START, DELETE_GROUP_CYCLE_SUCCESS} from "../../types";

export function* deleteGC({payload}) {
    try {
        const url = `/group_cycle/${payload.id}/`;
        const method = 'DELETE';
        const headers = {Authorization: 'Bearer ' + payload.token, 'Content-Type':'application/json'};
        const results = yield call(apiCall, url, method, payload.formData, headers);
        yield put({type: DELETE_GROUP_CYCLE_SUCCESS, results: results.data});
    } catch (error) {
        yield put({type: DELETE_GROUP_CYCLE_ERROR, error});
    }
}

export default function* delete_group_cycle() {
    yield takeLatest(DELETE_GROUP_CYCLE_START, deleteGC);
}