import { GET_MAGAZINES_ERROR, GET_MAGAZINES_START, GET_MAGAZINES_SUCCESS } from "../../types";

export default function magazines(state, action) {
  switch (action.type) {
    case GET_MAGAZINES_START:
      return {
        ...state,
        data: [],
        loading: true,
        error: null
      };
    case GET_MAGAZINES_SUCCESS:
      return {
        ...state,
        data: action.results,
        loading: false,
        error: null
      };
    case GET_MAGAZINES_ERROR:
      return {
        ...state,
        data: [],
        loading: false,
        error: action.detail
      };
    default:
      return { ...state };
  }
}
