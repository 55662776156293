import {CREATE_NEW_CYCLE_ERROR, CREATE_NEW_CYCLE_START, CREATE_NEW_CYCLE_SUCCESS} from "../../types";

export default function create_new_cycle(state, action) {
  switch (action.type) {
    case CREATE_NEW_CYCLE_START:
      return {
        ...state,
        loading: true,
        error: null
      };
    case CREATE_NEW_CYCLE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null
      };
    case CREATE_NEW_CYCLE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.detail
      };
    default:
      return {...state};
  }
}
