import { CREATE_DRAWER_ERROR, CREATE_DRAWER_START, CREATE_DRAWER_SUCCESS } from "../../types";

export default function create_drawer(state, action) {
  switch (action.type) {
    case CREATE_DRAWER_START:
      return {
        ...state,
        loading: true,
        error: null
      };
    case CREATE_DRAWER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null
      };
    case CREATE_DRAWER_ERROR:
      return {
        ...state,
        loading: false,
        error: action.detail
      };
    default:
      return {...state};
  }
}
