import {UPDATE_NOTIFICATION_ERROR, UPDATE_NOTIFICATION_START, UPDATE_NOTIFICATION_SUCCESS} from "../../types";

export default function update_notification(state, action) {
    switch (action.type) {
        case UPDATE_NOTIFICATION_START:
            return {
                ...state,
                loading: true,
                error: null
            };
        case UPDATE_NOTIFICATION_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null
            };
        case UPDATE_NOTIFICATION_ERROR:
            return {
                ...state,
                loading: false,
                error: action.detail
            };
        default:
            return {...state};
    }
}
